import React, { useEffect, useState } from "react";

import MetricLeft from "../components/MetricLeft"
import MetricPanel from "../components/MetricPanel";
import ConceptPanel from "../components/ConceptPanel";
import Sidebar from "../components/Sidebar";
import { MetricNode } from "../lexical/MetricNode";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import { useMetrics } from '../hooks/useMetrics';
import { useLibrary } from "../hooks/useLibrary";
import TopBar from "../components/TopBar/TopBar";
import { BraceNode } from "../lexical/BraceNode";
import { Item, isConcept, isMetric } from "../@types/common";
import { CustomTreeProvider } from "../hooks/useCustomTree";

const MetricCentral: React.FC = () => {
    const {currentItem, setCurrentItem} = useMetrics();
    const { library, libraries, setLibrary } = useLibrary();

    const onError = (error: any) => {
        console.error(error);
    };

    const prepopulatedRichText = () => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        let logicText = "";
        if (isMetric(currentItem)) {
            logicText = currentItem.metric_logic || "";
        } else if (isConcept(currentItem)) {
            logicText = currentItem.logic || "";
        }
        const text = $createTextNode(logicText || "");
        paragraph.append(text);
        root.append(paragraph);
    }

    const editorConfig = {
        namespace: "metrics-editor",
        onError: onError,
        nodes: [MetricNode, BraceNode],
        editorState: prepopulatedRichText,
    };

    const renderPanel = () => {
        if (isMetric(currentItem)) {
            return <MetricPanel page="MetricCentral"/>;
        } else if (isConcept(currentItem)) {
            return <ConceptPanel readOnly={true} page="MetricCentral" currentConcept={currentItem} setCurrentConcept={setCurrentItem}/>;
        }
        return <MetricPanel page="MetricCentral"/>;
    };

    useEffect(() => {
        if (!library && libraries && libraries.length > 0) {
            setLibrary(libraries[0]);
        }
    }, []);

    return (
        <CustomTreeProvider>
            <div className="App bg-gray-100 pl-[56px] w-full min-h-screen relative flex min-w-[1000px]">
                <LexicalComposer initialConfig={editorConfig}>
                    <TopBar title="Metric Central" />
                    <Sidebar />
                    <MetricLeft/>
                    {renderPanel()}
                </LexicalComposer>
            </div>
        </CustomTreeProvider>
    );
};

export default MetricCentral;
