import ReactDOM from "react-dom";
import { Cohort } from "../../hooks/useCohorts";

export const closeTooltip = (tooltipRef : HTMLDivElement) => {
    ReactDOM.unmountComponentAtNode(tooltipRef);
    document.body.childNodes.forEach(child => {
        if (child.isSameNode(tooltipRef)) {
            document.body.removeChild(tooltipRef);
        }
    })
};

export const parseMessageContent = (content: string, cohorts: Cohort[] = []) => {
    const regex = /(\[([^\]]+)\])|(\{([^}]+)\})|(@([^}]+)@)/g;
    // this regex matches 5 parts:
    // 0: the whole thing
    // 1: entire left of or [*]
    // 2: inside of left side without brackets
    // 3: entire right of or {*}
    // 4: inside of right side without brackets
    // 3: entire right of or @*@
    // 4: inside of right side without @
    const chunks: any[] = [];
    let match;
    let lastEnd = 0;

    const findAllLocations = (text: string, searchText: string) => {
        let index = text.indexOf(searchText);
        const result: number[] = [];
        while (index >= 0) {
            result.push(index);
            index = text.indexOf(searchText, index + 1);
        }
        return result;
    }

    const parseCohort = (textContent: string) => {
        type Chunk = {start: number, length: number};
        let cohortLocations : Chunk[] = [];
        cohorts.forEach(cohort => {
            findAllLocations(textContent, `@${cohort.name}`).forEach(index => {
                cohortLocations.push({start: index, length: cohort.name.length + 1});
            })
        });
        let processedEnd = 0;
        cohortLocations.sort((a, b) => a.start < b.start ? -1 : a.start === b.start ? b.length - a.length : 1);
        cohortLocations.reduce(
            (prev: Chunk[], curr: Chunk) => {
                if (prev.length === 0)
                    return [curr];
                if (prev[prev.length - 1].start === curr.start)
                    return prev;
                return [...prev, curr];
            }, []
        ).forEach(({start, length}) => {
            if (start > processedEnd) {
                chunks.push({ type: "text", content: textContent.substring(processedEnd, start) });
            }
            chunks.push({ type: "link_cohort", content: textContent.substring(start + 1, start + length) });
            processedEnd = start + length;
        })
        if (processedEnd < textContent.length) {
            chunks.push({ type: "text", content: textContent.substring(processedEnd) });
        }
    }

    while ((match = regex.exec(content)) !== null) {
        if (match.index > lastEnd) {
            const textContent = content.substring(lastEnd, match.index);
            // parseCohort(textContent);
            chunks.push({ type: "text", content: content.substring(lastEnd, match.index) });
        }
        if (match[2] !== undefined) {
            chunks.push({ type: "link", content: match[2] });
        }
        if (match[4] !== undefined) {
            chunks.push({ type: "link_concept", content: match[4] });
        }
        if (match[6] !== undefined) {
            chunks.push({ type: "link_cohort", content: match[6] });
        }
        lastEnd = match.index + match[0].length;
    }

    if (lastEnd < content.length) {
        const textContent = content.substring(lastEnd);
        // parseCohort(textContent);
        chunks.push({ type: "text", content: content.substring(lastEnd) });
    }
    return chunks;
};