import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "./useAuth";
import { useLibrary } from "./useLibrary";
import * as api from "../api";
import { useToast } from "./useToast";

interface SampleContextData {
  sample: {[s: string]: string[]};
  setSample: (sample: {[s: string]: string[]}) => void;
}

const SampleContext = createContext<SampleContextData | undefined>(undefined);

const useSample = () => {
  const context = useContext(SampleContext);
  if (!context) {
    throw new Error("useFavorite must be used within an FavoriteProvider");
  }
  return context;
};

const SampleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sample, setSample] = useState<{[s: string]: string[]}>({});
  const {userProfile, isAuthenticated} = useAuth();
  const {library} = useLibrary();
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching sample");
      const responseSample = await api.fetchUserSample(userProfile!.email, library?.id || 0);
      console.log(responseSample);
      setSample(responseSample);
    }
    if (isAuthenticated) {
      fetchData()
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setSample({});
    }
  }, [library]);

  return (
    <SampleContext.Provider value={{ sample, setSample }}>
      {children}
    </SampleContext.Provider>
  );
};

export { SampleProvider, useSample };
