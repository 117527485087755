import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, XMarkIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditIcon } from "../svg/EditIcon.svg"
import { ReactComponent as LoadIcon } from "../svg/LoadIcon.svg"

interface ButtonsProps {
    type: string;
    handleButtonClick: (buttonType: string) => void;
    buttonState: (buttonType: string) => boolean;
}

const Buttons: React.FC<ButtonsProps> = ({ type, handleButtonClick, buttonState }) => {
    const getButtonClass = (buttonType: string, buttonColor?: string) => {
        const defaultColor = buttonColor || 'white';

        if (buttonState(buttonType)) {
            //If button is enabled
            switch (buttonColor) {
                case 'red':
                    return "px-4 mx-1 py-2 bg-white text-red-600 border-1 border-slate-300 text-sm rounded-md shadow-button hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-teal-900 focus:ring-opacity-50";

                //By default - white color
                default:
                    return "px-4 mx-1 py-2 bg-white text-slate-900 border-1 border-slate-300 text-sm rounded-md shadow-button hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-teal-900 focus:ring-opacity-50";
            }
        } else {
            //If button is disabled
            switch (buttonColor) {
                case 'red':
                    return "px-4 mx-1 py-2 bg-gray-200 text-red text-sm rounded-md ";

                default:
                    return "px-4 mx-1 py-2 bg-gray-200 text-white text-sm rounded-md ";
            }
        }
    };

    return (
        <div className="flex mt-2">
            <div className="flex gap-4">
                {type === "Concept" &&
                    <button onClick={() => handleButtonClick('Draft')}
                        className={getButtonClass('Draft')}
                        disabled={!buttonState('Draft')}>
                        Save
                    </button>
                }
            </div>
            <div className="ml-auto flex gap-4">
                <button onClick={() => handleButtonClick('Delete')}
                    className={getButtonClass('Delete', "red")}
                    disabled={!buttonState('Delete')}>
                    <XMarkIcon className={buttonState('Delete') ? "h-4 w-4 mr-1 inline-block" : "h-5 w-5 ml-1 inline-block text-gray-300"} />
                    {type === "table" ? "Do Not Use Table" : `Delete`}
                </button>
                {type !== "table" &&
                    <button onClick={() => handleButtonClick('Duplicate')}
                        className={getButtonClass('Duplicate')}
                        disabled={!buttonState('Duplicate')}>
                        <DocumentDuplicateIcon className={buttonState('Duplicate') ? "h-4 w-4 mr-1 inline-block" : "h-5 w-5 mr-1 inline-block text-gray-300"} />
                        Duplicate {type}
                    </button>
                }
                {type === "table" &&
                    <button onClick={() => handleButtonClick('Draft')}
                        className={getButtonClass('Draft')}
                        disabled={!buttonState('Draft')}>
                        <LoadIcon className={buttonState('Draft') ? "h-5 w-5 mr-1 inline-block" : "h-5 w-5 mr-1 inline-block text-gray-300"} />
                        Save as Draft
                    </button>
                }
                {/* <button onClick={() => handleButtonClick('Delete')}
                    className={getButtonClass('Delete')}
                    disabled={!buttonState('Delete')}>
                    <TrashIcon className={buttonState('Delete') ? "h-4 w-4 mr-1 inline-block" : "h-5 w-5 mr-1 inline-block text-gray-300"} />
                    <span className='text-red-700'>{type === "table" ? "Do Not Use Table" : `Delete ${type}`}</span>
                </button> */}
            </div>
        </div>
    );
};

export default Buttons;
