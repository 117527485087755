import { Tooltip } from 'react-tooltip'

// we are using not react style to match with tooltip library
export const SimpleToolTipId = "simpleToolTip"

const SimpleToolTip: React.FC = () => {
    return (<Tooltip 
        id="simpleToolTip"
        style={{
            zIndex: "20",
            background: "#412B88",
            borderRadius: "7px",
            font: "Inter",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            opacity: "1.0",
        }}
    />)
}

export default SimpleToolTip;