import React, { useEffect, useState } from 'react';
import { Concept, Item } from '../@types/common';
import { CheckCircleIcon, XMarkIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Library } from '../@types/common';
import { useLibrary } from '../hooks/useLibrary';
import Loader from './Loader';
import { useAuth } from '../hooks/useAuth';
import { useToast } from '../hooks/useToast';
import { useConcept } from '../hooks/useConcepts';

interface ConceptLeftProps {
    currentConcept: Concept | null;
    setCurrentConcept: (concept: Concept | null) => void;
}

const ConceptLeft: React.FC<ConceptLeftProps> = ({currentConcept, setCurrentConcept}) => {
    const {concepts, addConcept} = useConcept();
    const { library, setLibrary, libraries } = useLibrary();
    const [editor] = useLexicalComposerContext();
    const [expandedLibrary, setExpandedLibrary] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const {checkEditPrivilege} = useAuth();
    const toast = useToast();

    const handleLibraryClick = async (libraryId: number) => {
        if (expandedLibrary === libraryId) {
            setExpandedLibrary(null);
            return;
        }

        setLoading(true);
        setExpandedLibrary(libraryId);

        const selectedLibrary = libraries?.find(lib => lib.id === libraryId);
        setLibrary(selectedLibrary || null);

        setLoading(false);
    };

    const handleConceptClick = async (concept: Concept) => {
        setCurrentConcept(concept);
    };

    const getStatusIcon = (status: string) => {
        if (status === "Deprecated") {
            return <XMarkIcon className="h-5 w-5 ml-1 inline-block" />
        } else if (status === "Verified") {
            return <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />
        }
        return null;
    }

    return (
        <div className="pt-[56px] h-screen min-w-[384px] w-[384px] px-4 pt-10 text-left">
            <div className="rounded-lg border-2 h-full w-full py-2 px-2 flex flex-col overflow-auto">
                {libraries?.map((library) => (
                    <div key={library.id}>
                        <button onClick={() => handleLibraryClick(library.id)}>
                            {library.name} {expandedLibrary === library.id ? <ChevronDownIcon className='h-5 w-5 inline' /> : <ChevronRightIcon className='h-5 w-5 inline' />}
                        </button>
                        {expandedLibrary === library.id && (
                            <>
                                {loading ? (
                                    <div className='m-2'><Loader /></div>
                                ) : (
                                    <>
                                        <div className="border-b mb-2 flex-grow ml-4">
                                            <div className="flex justify-between items-center mb-2">
                                                <div className='font-semibold underline'>Concepts</div>
                                                <button onClick={() => {
                                                    if (!checkEditPrivilege('ConceptBuilder', library?.id || 0)) {
                                                        toast.warning("You do not have edit access to this page");
                                                        return;
                                                    }
                                                    const newConcept : Concept = {
                                                        id: -1,
                                                        name: "Untitled Concept",
                                                        type: "Concept",
                                                        attributes: [],
                                                    }
                                                    setCurrentConcept(newConcept);
                                                    addConcept(newConcept).then((concept) => {
                                                        setCurrentConcept(concept);
                                                    }).catch((e) => {
                                                        setCurrentConcept(null);
                                                    })
                                                }}>+ New Concept</button>
                                            </div>
                                            <div className="overflow-auto h-full max-h-[calc(50vh-4rem)]">
                                                {concepts.map((it, index) => (
                                                    <div className={`hover:cursor-pointer ${(currentConcept && it.id === currentConcept.id && currentConcept.type === "Concept") ? 'font-semibold' : ''}`} key={index} onClick={() => {
                                                        handleConceptClick(it);
                                                    }}>
                                                        {it.name}
                                                        {getStatusIcon(it.status!)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>)}
                            </>)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConceptLeft;
