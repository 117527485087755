import React, { useEffect } from "react";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useToast } from "../hooks/useToast";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import * as api from "../api";
import useQuery from "../hooks/useQuery";

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const toast = useToast();
  const { setUserProfile, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    if (isAuthenticated) {
      const redirect = query.get("redirect");
      if (redirect === null){
        navigate("/");
      } else {
        navigate(redirect);
      }
    }
  }, [])

  const handleSuccess = async (response: CredentialResponse) => {
    try {
      const userProfile = await api.googleOAuth(response.credential!);
      const privilege = await api.fetchUserPrivilege(userProfile.email);
      userProfile.privilege = privilege;
      setUserProfile(userProfile);
      // setPrivilege(privilege);
      // console.log(privilege);
      toast.success("Successfully logged in!");
      const redirect = query.get("redirect");
      if (redirect === null){
        navigate("/");
      } else {
        navigate(redirect);
      }
    } catch (error: any) {
      error?.message && toast.error(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <GoogleLogin onSuccess={handleSuccess} />
    </div>
  );
};

export default Login;
