import React, { useState, useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import { Library } from "../@types/common"
import { SelectComponents } from 'react-select/dist/declarations/src/components';

interface LibraryDropdownProps {
    libraries: Library[];
    selectedLibrary: Library | null;
    onLibrarySelect: (selectedOption: { value: number, label: string } | null) => void;
    customStyles?: StylesConfig<{
        value: number;
        label: string;
    }, false, any>;
    customComponents?: Partial<SelectComponents<{
        value: number;
        label: string;
    }, false, any>>
}

const LibraryDropdown: React.FC<LibraryDropdownProps> = ({ libraries, selectedLibrary, onLibrarySelect, customStyles, customComponents }) => {
    const [selectOptions, setSelectOptions] = useState<any[]>([]);
    // const options = libraries.map(lib => ({ value: lib.id, label: lib.name }));
    const selectedValue = selectedLibrary ? { value: selectedLibrary.id, label: selectedLibrary.name } : null;

    const handleChange = (selectedOption: { value: number, label: string } | null) => {
        // const selectedLibrary = selectedOption ? libraries.find(lib => lib.id === selectedOption.value) : null;
        onLibrarySelect(selectedOption);
    };

    useEffect(() => {
        const transformed = libraries.map(lib => ({ value: lib.id, label: lib.name }));
        setSelectOptions(transformed);
    }, [libraries]);

    return (
        <Select
            onChange={handleChange}
            options={selectOptions}
            className="w-full"
            isSearchable={false}
            value={selectedValue}
            placeholder="Select an area of focus"
            styles={customStyles}
            components={customComponents}
        />
    );
};

export default LibraryDropdown;
