import React, { createContext, useState, useContext, useEffect } from "react";
import {
  ChatMessage,
} from "../@types/common";
import * as api from "../api";
import { useAuth } from "./useAuth";
import { useLibrary } from "./useLibrary";
import { useToast } from "./useToast";

export interface HistoryEntry {
  id?: string;
  message: string;
  timestamp: string;
  status: string;
  historyid?: string;
  userid?: string;
  favorite?: string;
  session_id: number,
}

interface UpdateHistoryStatusParams {
  id: string;
  newStatus: string;
}

interface UpdateHistoryFavoriteParams {
  historyid: string;
  newFavorite: string;
}

interface UpdateHistorySessionParams {
  historyid: string;
  newSessionId: number;
}

interface HistoryContextData {
  history: HistoryEntry[];
  addHistory: (history: HistoryEntry) => void;
  updateHistoryStatus: (params: UpdateHistoryStatusParams) => void;
  updateHistoryIds: (updatedMessages: ChatMessage[]) => void;
  updateHistorySession: (params: UpdateHistorySessionParams) => void;
  favorite: FavoriteEntry[];
  addFavorite: (favorite: FavoriteEntry) => void;
  removeFavoriteId: (id: string) => void;
}

export interface FavoriteEntry {
  id?: string;
  message: string;
  historyid?: string;
  favorite?: string;
}

const HistoryContext = createContext<HistoryContextData | undefined>(undefined);

const useHistory = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error("useHistory must be used within an HistoryProvider");
  }
  return context;
};

const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [favorite, setFavorite] = useState<FavoriteEntry[]>([]);
  const {userProfile, isAuthenticated} = useAuth();
  const {library} = useLibrary();
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching history");
      const response = await api.fetchUserHistory(userProfile!.email, library?.id || 0);
      console.log(response);
      setHistory(response.reverse());
    };

    if (isAuthenticated && userProfile !== null && library !== null) {
      fetchData()
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setHistory([]);
    }
  }, [library]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching favorite");
      const responseFav = await api.fetchUserFavorite(userProfile!.email, library?.id || 0);
      console.log(responseFav);
      setFavorite(responseFav.reverse());
    }
    if (isAuthenticated) {
      fetchData()
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setFavorite([]);
    }
  }, [library]);

  const addHistory = (newHistory: HistoryEntry) => {
    // history update is managed on the server side so no needs to do api action here
    setHistory(history => [...history, newHistory]);
  };

  const updateHistoryStatus = ({ id, newStatus }: UpdateHistoryStatusParams) => {
    // history update is managed on the server side so no needs to do api action here
    setHistory((prevHistory) => {
      return prevHistory.map((entry) =>
        entry.id === id ? { ...entry, status: newStatus } : entry
      );
    });
  };

  const _updateHistoryFavorite = ({ historyid, newFavorite }: UpdateHistoryFavoriteParams) => {
    // TODO: move favorite so it is grouped with history
    setHistory((prevHistory) => {
      return prevHistory.map((entry) =>
        entry.historyid === historyid ? { ...entry, favorite: newFavorite } : entry
      );
    });
  };

  const updateHistorySession = ({ historyid, newSessionId }: UpdateHistorySessionParams) => {
    const oldSessionId = history.find(history => history.historyid === historyid)?.session_id || -1;
    setHistory((prevHistory) => {
      return prevHistory.map((entry) => 
        entry.historyid === historyid ? { ...entry, session_id: newSessionId} : entry
      );
    });
    api.updateUserHistorySession(historyid, newSessionId).catch((e) => {
      console.error(e);
      toast.error(e);
      setHistory((prevHistory) => {
        return prevHistory.map((entry) => 
          entry.historyid === historyid ? { ...entry, session_id: oldSessionId} : entry
        );
      });
    });
  };

  const updateHistoryIds = (updatedMessages: ChatMessage[]) => {
    // history update is managed on the server side so no needs to do api action here
    setHistory((prevHistory) => {
      const updatedHistory = [...prevHistory];
      const recentHistorySize = updatedMessages.length;
      const recentHistory = updatedHistory.slice(-recentHistorySize);
      updatedMessages.forEach((message, index) => {
        recentHistory[index].id = message.id;
      });
      return [...updatedHistory.slice(0, -recentHistorySize), ...recentHistory];
    });
  };

  const addFavorite = (newFavorite: FavoriteEntry) => {
    const oldFavorite = favorite.map(f => f);
    setFavorite(favorite => [...favorite, newFavorite]);
    _updateHistoryFavorite({historyid: newFavorite.historyid || "", newFavorite: "Y"});
    api.addFavorite(newFavorite.historyid || "", "Y").catch(e => {
      console.error(e);
      toast.error(e.message);
      setFavorite(oldFavorite);
      _updateHistoryFavorite({historyid: newFavorite.historyid || "", newFavorite: "N"});
    })
  };

  const removeFavoriteId = (id: string) => {
    const oldFavorite = favorite.map(f => f);
    setFavorite(favList => {
        const index = favList.findIndex((entry) => {return entry.historyid === id});
        if (index !== -1) {
            return [...favList.slice(0, index), ...favList.slice(index + 1)];
        }
        return [...favList];
    });
    _updateHistoryFavorite({historyid: id, newFavorite: "N"});
    api.addFavorite(id, "N").catch(e => {
      console.error(e);
      toast.error(e.message);
      setFavorite(oldFavorite);
      _updateHistoryFavorite({historyid: id, newFavorite: "Y"});
    });
  }

  return (
    <HistoryContext.Provider value={{ 
      history, addHistory, updateHistoryStatus, updateHistoryIds, updateHistorySession,
      favorite, addFavorite, removeFavoriteId, 
    }}>
      {children}
    </HistoryContext.Provider>
  );
};

export { HistoryProvider, useHistory };
