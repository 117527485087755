import React, { useState, createContext, useContext } from "react";

export type DataPointsType = {
  date: string;
  value: number;
}[];

export type ChartDataType = {
  dataPoints: DataPointsType;
  title: string;
};

export type ChartContextType = {
  charts: ChartDataType[];

  addChartData: (data: ChartDataType) => void;
};

export const ChartContext = createContext<ChartContextType>({
  charts: [],

  addChartData: () => {},
});

export const ChartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [charts, setCharts] = useState<ChartDataType[]>([]);

  const addChartData = (data: ChartDataType) => {
    const newTitle =
      data.title.length === 0
        ? ""
        : data.title.charAt(0).toUpperCase() + data.title.slice(1);

    setCharts([...charts, { ...data, title: newTitle }]);
  };

  const value: ChartContextType = {
    charts,
    addChartData,
  };

  return (
    <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
  );
};

export const useChart = (): ChartContextType => {
  return useContext(ChartContext);
};
