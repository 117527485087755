import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  MenuOption,
  LexicalTypeaheadMenuPlugin,
  MenuTextMatch,
  SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { KEY_ARROW_DOWN_COMMAND, KEY_ARROW_UP_COMMAND, TextNode } from "lexical";
import { $createMetricNode } from "../lexical/MetricNode";
import ReactDOM from "react-dom";
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { Concept, Item, Metric } from "../@types/common";
import { $createConceptNode } from "../lexical/ConceptNode";
import { useConcept } from "../hooks/useConcepts";

const metricsCache = new Map();

const metricsSearch = (
  query: string,
  metricNames: string[],
  conceptNames: string[],
  callback: (results: Array<string>) => void,
) => {
  setTimeout(() => {
    const metricResults = metricNames.filter((metric) =>
      metric.toLowerCase().includes(query.toLowerCase())
    );
    const conceptResults = conceptNames
      .filter((name) => name.toLowerCase().includes(query.toLowerCase()))
    const results = [...metricResults, ...conceptResults].sort((a, b) =>
      a.localeCompare(b)
    );
    callback(results);
  }, 500);
};

const useMetricLookupService = (queryString: string | null, metricNames: string[], conceptNames: string[]) => {
  const [results, setResults] = useState<Array<string>>([]);

  useEffect(() => {
    if (queryString === null) {
      setResults([]);
      return;
    }

    const cachedResults = metricsCache.get(queryString);
    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    metricsCache.set(queryString, null);
    metricsSearch(queryString, metricNames, conceptNames, (results) => {
      metricsCache.set(queryString, results);
      setResults(results);
    });
  }, [queryString]);

  return results;
};

const MetricRegex = /(^|\s)(\[((?:[a-zA-z0-9\s]){0,20}))$/;

const getPossibleQueryMatch = (text: string): MenuTextMatch | null => {
  let match = MetricRegex.exec(text);
  if (match) {
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    return {
      leadOffset: match.index + maybeLeadingWhitespace.length,
      matchingString,
      replaceableString: match[2],
    }
  }

  return null;
}

class MetricTypeaheadOption extends MenuOption {
  name: string;
  status: string;
  type: string;
  description: string;
  bl: string;
  concept: Concept | null;

  constructor(name: string, status: string, type: string, description: string, bl: string, concept: Concept | null = null) {
    super(name);
    this.name = name;
    this.status = status;
    this.type = type;
    this.description = description;
    this.bl = bl;
    this.concept = concept;
  }
}

interface MetricsAutoCompletePluginProps {
  _metrics: Metric[],
  _concepts: Item[],
  concepts?: Concept[],
}

const MetricsAutoCompletePlugin: React.FC<MetricsAutoCompletePluginProps> = ({ _metrics, _concepts }) => {
  const [editor] = useLexicalComposerContext();
  const editorRef = useRef<any>(null);

  const [queryString, setQueryString] = React.useState<string | null>(null);

  const [activeTab, setActiveTab] = React.useState<'All' | 'Metrics' | 'Concepts'>('All');

  const metricNames = _metrics.map(metric => metric.metric_name);
  const metricStatuses = _metrics.map(metric => metric.metric_status);

  const conceptNames = _concepts.map(concept => concept.name);
  const conceptStatuses = _concepts.map(concept => concept.status);

  const results = useMetricLookupService(queryString, metricNames, conceptNames);

  const {concepts} = useConcept();

  const options = useMemo(() => {
    let filteredResults = results;

    if (activeTab === 'Metrics') {
      filteredResults = results.filter(name => metricNames.includes(name));
    } else if (activeTab === 'Concepts') {
      filteredResults = results.filter(name => conceptNames.includes(name));
    }

    return filteredResults.map((result) => {
      let metric = _metrics.find(item => item.metric_name === result);
      let status = "";
      let type = "";
      let description = "";
      let bl = ""
      let concept : Concept | null = null;

      if (metric) {
        status = metric.metric_status!;
        type = "Metric";
        description = metric.metric_desc!;
        bl = metric.metric_logic!;
      } else {
        let concept = _concepts.find(item => item.name === result);
        if (concept) {
          status = concept.status!;
          type = "Concept";
          description = concept.desc!;
        }
      }

      if (type === "Concept") {
        const temp = concepts.find(concept => concept.name === result);
        if (temp !== undefined) {
          concept = temp;
        }
      }

      return new MetricTypeaheadOption(result, status!, type, description, bl, concept);
    });
  }, [results, activeTab]);


  const onOptionSelected = useCallback(
    (selectedOption: MetricTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const isMetric = selectedOption.type === "Metric";
        const isConcept = selectedOption.type === "Concept";
        if (isMetric) {
          const metricNode = $createMetricNode(selectedOption.name, selectedOption.status, selectedOption.type, selectedOption.description, selectedOption.bl);
          if (nodeToReplace) {
            nodeToReplace.replace(metricNode);
          }
          metricNode.select();
        }
        if (isConcept && selectedOption.concept !== null) {
          const conceptNode = $createConceptNode(selectedOption.concept, null, [], 0);
          if (nodeToReplace) {
            nodeToReplace.replace(conceptNode);
          }
          conceptNode.select();
        }
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMatch = useCallback(
    (text: string) => {
      return getPossibleQueryMatch(text);
    }, [editor]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        editorRef.current?.focus();
      }
    }

    const targetElement = editorRef.current || document;
    targetElement.addEventListener('keydown', handleKeyDown);

    return () => {
      targetElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [options, editorRef]);

  const MetricDisplay: React.FC<{
    selectedIndex: number | null;
    selectOptionAndCleanUp: (option: MetricTypeaheadOption) => void;
    setHighlightedIndex: (index: number) => void;
  }> = ({selectedIndex, selectOptionAndCleanUp, setHighlightedIndex}) => {
    const elementsRef = useRef<(HTMLDivElement|null)[]>(new Array<HTMLDivElement|null>());
    const [myOwnNumber, _setSelectedIndex] = React.useState(0);

    useEffect(() => {
      setHighlightedIndex(0);
    }, []);

    const scrollIntoViewIfNeeded = (target:HTMLDivElement) => {
      console.log(target.getBoundingClientRect());
      // Target is outside the viewport from the bottom
      if (target.getBoundingClientRect().bottom > window.innerHeight) {
          //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
          target.scrollIntoView(false);
      }
  
      // Target is outside the view from the top
      if (target.getBoundingClientRect().top < 0) {
          // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
          target.scrollIntoView();
      }
  };

    useEffect(() => {
      return editor.registerCommand(KEY_ARROW_DOWN_COMMAND, (payload) => {
        _setSelectedIndex((selected) => { 
          let newNum = selected + 1; 
          if (newNum >= options.length) {
            newNum = options.length - 1;
          }
          // console.log(newNum); 
          setHighlightedIndex(newNum);
          // scrollIntoViewIfNeeded(elementsRef.current[newNum]!);
          elementsRef.current[newNum]!.scrollIntoView();
          return newNum; 
        });
        payload.preventDefault();
        payload.stopImmediatePropagation();
        return true;
      }, 4);
    }, []);

    useEffect(() => {
      return editor.registerCommand(KEY_ARROW_UP_COMMAND, (payload) => {
        _setSelectedIndex((selected) => { 
          let newNum = selected - 1; 
          if (newNum < 0) {
            newNum = 0;
          }
          // console.log(newNum); 
          setHighlightedIndex(newNum);
          // scrollIntoViewIfNeeded(elementsRef.current[newNum]!);
          elementsRef.current[newNum]!.scrollIntoView();
          return newNum; 
        });
        payload.preventDefault();
        payload.stopImmediatePropagation();
        return true;
      }, 4);
    }, []);

    return (
      <div
        className="absolute bottom-full bg-white shadow-metricTooltip rounded-md flex flex-col w-[300px] max-h-[200px] overflow-y-auto"
        onLoad={() => console.log("loaded")}
        onKeyDown={() => console.log("keydown")}
      >
        <div className="flex">
          <button
            className={`flex-1 ${activeTab === 'All' ? 'bg-gray-200' : ''}`}
            onMouseDown={event => {
              event.preventDefault();
              setActiveTab('All');
            }}
          >
            All
          </button>
          <button
            className={`flex-1 ${activeTab === 'Metrics' ? 'bg-gray-200' : ''}`}
            onMouseDown={event => {
              event.preventDefault();
              setActiveTab('Metrics');
            }}
          >
            Metrics
          </button>
          <button
            className={`flex-1 ${activeTab === 'Concepts' ? 'bg-gray-200' : ''}`}
            onMouseDown={event => {
              event.preventDefault();
              setActiveTab('Concepts');
            }}
          >
            Concepts
          </button>
        </div>
        {options.map((option, index) => (
          <div
            ref={(element) => elementsRef.current[index] = element}
            key={option.key}
            className={`px-[4px] py-[2px] w-full ${index === selectedIndex ? "bg-gray-100" : ""}`}
            onClick={() => {
              _setSelectedIndex(index);
              setHighlightedIndex(index);
              selectOptionAndCleanUp(option)
            }}
            onMouseMove={() => {
              _setSelectedIndex(index);
              setHighlightedIndex(index)}}
          >
            <div className="h-fit w-fit rounded-[10px] border-[1px] border-[#E5DDFD] px-[8px] py-[3px] bg-metric-entry cursor-pointer" 
                // key={index}
                // id={`_metric_${index}`}
            >
                <div className="font-Inter text-[13px] font-[500] tracking-normal text-left leading-[16px] text-[#412B88]">
                    {option.name}
                </div>
            </div>
            {/* {option.name} */}
            {/* {option.status === "Verified" && <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />} */}
          </div>
        ))}
      </div>
    )
  }

  return (
    <LexicalTypeaheadMenuPlugin<MetricTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onOptionSelected}
      triggerFn={checkForMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        // <MetricDisplay selectedIndex={selectedIndex} selectOptionAndCleanUp={selectOptionAndCleanUp} setHighlightedIndex={setHighlightedIndex}/>
        anchorElementRef.current && ReactDOM.createPortal(
          <MetricDisplay selectedIndex={selectedIndex} selectOptionAndCleanUp={selectOptionAndCleanUp} setHighlightedIndex={setHighlightedIndex}/>
          ,
          anchorElementRef.current)
      }
    />
  );
};

export default MetricsAutoCompletePlugin;
