import React, { useRef } from 'react';
import { Metric } from '../@types/common';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import VerifiedMetricContainer from './CustomMetricTreeContainer';
import { useMetrics } from '../hooks/useMetrics';


const MetricLeft: React.FC = () => {
    const {setCurrentItem} = useMetrics();
    const [editor] = useLexicalComposerContext();
    const parentRef = useRef<HTMLDivElement>(null);

    return (
        <div className="h-screen min-w-[384px] w-[384px] pt-[56px] text-left">
            {/* <div className="h-full w-full p-2 flex flex-col"> */}
                <div className={`flex flex-col h-full bg-white shadow px-4 pr-[3] overflow-x-visible overflow-y-auto scrollbar`}
                    style={{
                        scrollbarGutter: "stable",
                    }}
                    ref = {parentRef}
                >
                    <VerifiedMetricContainer onMetricClick={(metric: Metric) => {
                        setCurrentItem(metric);
                        editor.update(() => {
                            const root = $getRoot();
                            root.clear();
                            const paragraph = $createParagraphNode();
                            const text = $createTextNode(metric.metric_logic || "");
                            paragraph.append(text);
                            root.append(paragraph);
                        });
                    }}
                    parentRef={parentRef}/>
                </div>
            {/* </div> */}
        </div>
    );
};

export default MetricLeft;
