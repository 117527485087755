type ChatMessageStatus = "queued" | "loading" | "not viewed" | "success" | "error";

export interface ChatMessage {
  id: string;
  date: Date;
  sender: "user" | "server";
  content?: string;
  chartData?: any[];
  tableData?: any[];
  tableHead?: string[];
  status?: ChatMessageStatus;
  debug?: string;
  insight?: string;
  input?: string;
  sql?: string;
  steps?: StreamMessageStep[];
  currentStep?: number;
  conversationId?: string;
  query_id?: string;
  feedback?: string;
}

export type StreamMessageTypes = "text" | "sql" | "table" | "error" | "plan" | "initialize";

export interface StreamMessageStep {
  type?: StreamMessageTypes;
  plan?: string;
  status: ChatMessageStatus;
  data?: any;
  message?: string;
  timeSpent?: number;
  estimatedTime?: string;
  debug?: string;
  tableHead?: string[];
  tableData?: any[];
}

export interface Library {
  id: number;
  name: string;
  company: string;
  importable: string;
  items?: Item[];
  price?: string;
  // Add any other fields here
}

export interface Item {
  type: string;
  id: number;
  domain_id?: number;
  category?: string;
  name: string;
  desc?: string;
  sql?: string;
  modified_sql?: string;
  logic?: string;
  formular?: string;
  attributes?: Attribute[];
  input_fields?: string;
  output_fields?: string;
  status?: string;
  deprecated?: string;
  // Add any other fields here
}


// if not mentioned, default to ''
// we use null here cause some data in the database are missing, 
// null refers to intentional missing data and undefined refers to data that
// is not "defined" yet, but should eventually be defined

// A.METRIC_ID, 
// B.DOMAIN_NAME, 
// A.METRIC_NAME, 
// A.METRIC_DESC, 
// A.SQL_STATEMENT, 
// A.MODIFIED_SQL, 
// A.METRIC_LOGIC, 
// A.INPUT_FIELDS, 
// A.OUTPUT_FIELDS, 
// A.METRIC_STATUS, 
// A.DEPRECATED, 
// A.DOMAIN_ID

export interface Metric {
  metric_id: number;          // 0
  metric_name: string;        // 2
  domain_id: number | null;   // 11
  domain_name: string | null; // 1
  metric_desc: string | null; // 3
  metric_logic: string | null;    // 6
  input_fields: string | null;    // 7
  output_fields: string | null;   // 8
  deprecated: 'D' | 'N' | null;   // 10
  metric_status: string | null;   // 9
  sql_statement: string | null;   // 4
  modified_sql: 'Y' | 'N' | null; // 5
  all_users: boolean; // 12
}

export const isMetric = (item: any) : item is Metric => {
  return item && typeof item === "object" && "metric_id" in item;
}

export interface Concept extends Item {
  type: "Concept"
  attributes: Attribute[]
}

export const isConcept = (item: any) : item is Concept => {
  return item && "type" in item;
}

export interface Domain {
  id: number;
  name: string;
  parent_id: number | null;
  level_no: number;
  can_move: 'Y' | 'N';
  can_rename: 'Y' | 'N';
  all_users: boolean;
}

export const isDomain = (item: any): item is Domain => {
  return item && typeof item === "object" && "id" in item && "name" in item && "parent_id" in item && "level_no" in item && "can_move" in item && "can_rename" in item && "all_users" in item;
}

export interface Mapper {
  id: number;
  name?: string;
  operator: string;
  map_value: string;
  ori_value: string;
  // Add any other fields here
}

export interface Attribute {
  id: number;
  name: string;
  desc: string;
  mappers: Mapper[];
  linker: string;
  data_connection: string;
  // Add any other fields here
}