import ReactDOM from "react-dom";
import React, { CSSProperties, forwardRef } from 'react';
import { ReactComponent as TooltipArrow } from '../../svg/tooltip_arrow.svg';
import { closeTooltip } from "./ToolTipHelpers";

interface TooltipProps {
    metric: string;
    type: string;
    desc: string;
    bl: string;
    className?: string;
    parentRect?: DOMRect | null;
    style?: CSSProperties;
}

const Tooltip = forwardRef<SVGSVGElement, TooltipProps>(
    ({ metric, type, desc, bl }, ref) => {
        if (!type) return (<div />);
        return (
            <div className='flex flex-col items-left max-w-[500px]'>
                <div className="bg-white h-fit w-fit rounded-[12px] shadow-metricTooltip py-[10px] px-[8px] text-sm">
                    <div className='flex flex-row items-start'>
                        <div className='flex flex-col items-start py-[2px] gap-[8px]'>
                            <div className='font-Inter text-[14px] font-[700]  leading-[17px] text-[#000000]'>
                                Verified Metric:{` ${metric}`}
                            </div>
                            <div className='font-Inter text-[14px] font-[500]  leading-[17px] text-[#000000] opacity-[0.56]'>
                                {desc}
                            </div>
                            <div className='font-Inter text-[14px] font-[700]  leading-[17px] text-[#000000]'>
                                Business Logic:
                            </div>
                            <div className='font-Inter text-[14px] font-[500]  leading-[17px] text-[#412B88]'>
                                {bl}
                            </div>
                        </div>
                    </div>
                </div>
                <TooltipArrow ref={ref}/>
            </div>
        );
});

const spawnMetricTooltip = (e: any, metric: string, desc: string, logic: string, arrowRef: React.MutableRefObject<SVGSVGElement | null>) => {
    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.visibility = 'hidden';
    popup.style.zIndex = '6';
    popup.onmouseleave=((e) => closeTooltip(popup));

    document.body.appendChild(popup);

    const rect = e.target.getBoundingClientRect();
    // const root = createRoot(popup);
    // root.render(<Tooltip metric={metric} type={"Metric"} desc={desc} bl={logic} ref={arrowRef}/>);
    ReactDOM.render(<Tooltip metric={metric} type={"Metric"} desc={desc} bl={logic} ref={arrowRef}/>, popup);

    const popupHeight = popup.getBoundingClientRect().height;
    const popupWidth = popup.getBoundingClientRect().width;
    const arrowWidth = arrowRef.current !== null ? arrowRef.current.getBoundingClientRect().width : 0;

    popup.style.top = `${rect.top - popupHeight}px`;
    let leftPos = rect.left - popupWidth / 2 + rect.width / 2;
    if (leftPos + popupWidth > window.innerWidth) {
        leftPos = window.innerWidth - popupWidth;
        arrowRef.current!.style.marginLeft=`${rect.left + popupWidth - window.innerWidth + rect.width / 2 - arrowWidth / 2}px`;
    }
    else if (leftPos < 60) {
        arrowRef.current!.style.marginLeft=`${popupWidth / 2 - arrowWidth / 2 + leftPos - 60}px`;
        leftPos = 60;
    }
    else {
        arrowRef.current!.style.marginLeft=`${popupWidth / 2 - arrowWidth / 2}px`;
    }
    popup.style.left = `${leftPos}px`
    
    popup.style.visibility = 'visible';
    return popup;
}

export {spawnMetricTooltip};