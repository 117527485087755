import React from "react";
import { Attribute, Concept } from "../../@types/common";
import { closeTooltip } from "../ToolTip/ToolTipHelpers";
import { spawnConceptTooltip } from "../ToolTip/ConceptToolTip";
import { parseFilter } from "../SearchBar";
import { SimpleToolTipId } from "../ToolTip/SimpleToolTip";

interface ConceptEntryProps {
    isSelected: boolean;
    concept: Concept;
    index?: number;
    filter?: string;
    onClick: () => void;
}

interface AttributeEntryProps {
    isSelected: boolean;
    concept: Concept | null;
    attribute: Attribute;
    index?: number;
    filter?: string;
    onClick: () => void;
}

interface ValueEntryProps {
    isSelected: boolean;
    concept: Concept | null;
    attribute: Attribute | null;
    value: string;
    values: string[];
    setValues: React.Dispatch<React.SetStateAction<string[]>>;
    index?: number;
    filter?: string;
    onClick: () => void;
}

interface ValueEntryNewProps {
    isSelected: boolean;
    concept: Concept | null;
    attribute: Attribute | null;
    value: string;
    values: string[];
    setValues: React.Dispatch<React.SetStateAction<string[]>>;
    index?: number;
    filter?: string;
    onClick: () => void;
    length: number;
}

export const ValueEntryNew: React.FC<ValueEntryNewProps> = ({isSelected, concept, attribute, value, values, setValues, index, filter="", onClick, length}) => {
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    // React.useEffect(() => {
    //     return handleLeave;
    // }, []);

    // const handleLeave = () => {
    //     if (tooltipRef.current != null) {
    //         closeTooltip(tooltipRef.current);
    //         tooltipRef.current = null;
    //     }
    // };

    // const handleOver = (e: any, concept: Concept, attribute: Attribute, values: string[]) => {
    //     if (tooltipRef.current === null) {
    //         tooltipRef.current = spawnConceptTooltip({e, concept, attribute, values, conceptLevel: 2, arrowRef, allowCohort: false, onCohort: (v) => {}});
    //         e.stopPropagation();
    //     }
    // }

    return (
        <p 
            className={`flex flex-row items-center h-[22px] rounded-[10px] border-[1px] ${isSelected ? "border-[#6E43F8]" : "border-[#E5DDFD]"} px-[8px] ${isSelected ? "bg-[#6E43F8]" : "bg-[#F3F4F6] hover:bg-[#EEE8FF]"} cursor-pointer whitespace-pre select-none`}
            style={{width: `${length}px`}}
            onClick={(e) => {
                onClick();
            }}
            // onMouseEnter={(e) => {
            //     if (concept !== null && attribute !== null)
            //     setTimeoutId(setTimeout(() => handleOver(e, concept, attribute, [value]), 500));
            //     // handleOver(e, concept?.name || "", attribute?.name || "", [value]);
            // }}
            // onMouseLeave={(e) => {
            //     if (timeoutId !== null) {
            //         clearTimeout(timeoutId);
            //         setTimeoutId(null);
            //     }
            //     handleLeave();
            // }}
            key={`_value_${value}_${index}`}
            id={`_value_${value}_${index}`}
            data-tooltip-id={SimpleToolTipId}
            data-tooltip-content={value}
        >
            {/* {value} */}
            {parseFilter(`${value}`, filter).map((chunk, index) => {
                return chunk.type === "text" ? 
                <span 
                    className={`font-Inter text-[13px] font-[500] text-left truncate align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_value_${index}_text`}
                    >
                    {chunk.content}
                </span> : 
                <span className={`font-Inter text-[13px] font-[700] text-left truncate align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_value_${index}_text`}>
                    {chunk.content}
                </span>
            })}
            
        </p>
    )
}

export const ValueEntry: React.FC<ValueEntryProps> = ({isSelected, concept, attribute, value, values, setValues, index, filter="", onClick}) => {
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        return handleLeave;
    }, []);

    const handleLeave = () => {
        if (tooltipRef.current != null) {
            closeTooltip(tooltipRef.current);
            tooltipRef.current = null;
        }
    };

    const handleOver = (e: any, concept: Concept, attribute: Attribute, values: string[]) => {
        if (tooltipRef.current === null) {
            tooltipRef.current = spawnConceptTooltip({e, concept, attribute, values, conceptLevel: 2, arrowRef, allowCohort: false});
            e.stopPropagation();
        }
    }

    return (
        <div 
            className={`flex flex-row items-center h-[22px] w-fit rounded-[10px] border-[1px] ${isSelected ? "border-[#6E43F8]" : "border-[#E5DDFD]"} px-[8px] py-[3px] ${isSelected ? "bg-[#6E43F8]" : "bg-[#F3F4F6] hover:bg-[#EEE8FF]"} cursor-pointer whitespace-pre select-none`}
            onClick={(e) => {
                onClick();
            }}
            onMouseEnter={(e) => {
                if (concept !== null && attribute !== null)
                setTimeoutId(setTimeout(() => handleOver(e, concept, attribute, [value]), 500));
                // handleOver(e, concept?.name || "", attribute?.name || "", [value]);
            }}
            onMouseLeave={(e) => {
                if (timeoutId !== null) {
                    clearTimeout(timeoutId);
                    setTimeoutId(null);
                }
                handleLeave();
            }}
            key={`_value_${value}_${index}`}
            id={`_value_${value}_${index}`}
        >
            {parseFilter(`${value}`, filter).map((chunk, index) => {
                return chunk.type === "text" ? 
                <span 
                    className={`font-Inter text-[13px] font-[500] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_value_${index}_text`}
                    >
                    {chunk.content}
                </span> : 
                <span className={`font-Inter text-[13px] font-[700] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_value_${index}_text`}>
                    {chunk.content}
                </span>
            })}
            
        </div>
    )
}

export const AttributeEntry: React.FC<AttributeEntryProps> = ({isSelected, concept, attribute, index, filter="", onClick}) => {
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        return handleLeave;
    }, []);

    const handleLeave = () => {
        if (tooltipRef.current !== null) {
            closeTooltip(tooltipRef.current);
            tooltipRef.current = null;
        }
    };

    const handleOver = (e: any, concept: Concept, attribute: Attribute) => {
        if (tooltipRef.current === null) {
            tooltipRef.current = spawnConceptTooltip({e, concept, attribute, conceptLevel: 1, arrowRef});
            e.stopPropagation();
        }
    }

    return (
        <div 
            className={`flex flex-row items-center h-[22px] w-fit rounded-[10px] border-[1px] ${isSelected ? "border-[#6E43F8]" : "border-[#E5DDFD]"} px-[8px] py-[3px] ${isSelected ? "bg-[#6E43F8]" : "bg-[#F3F4F6] hover:bg-[#EEE8FF]"} cursor-pointer whitespace-pre select-none`}
            onClick={(e) => {
                onClick();
            }}
            // onMouseEnter={(e) => {
            //     if (concept !== null)
            //         setTimeoutId(setTimeout(() => handleOver(e, concept, attribute), 500));
            //     // handleOver(e, concept?.name || "", attribute.name);
            // }}
            // onMouseLeave={() => {
            //     if (timeoutId !== null) {
            //         clearTimeout(timeoutId);
            //         setTimeoutId(null);
            //     }
            //     handleLeave();
            // }}
            key={`_attribute_${index}_${attribute.id}`}
            id={`_attribute_${index}`}
        >
            {parseFilter(`${attribute.name}`, filter).map((chunk, index) => {
                return chunk.type === "text" ? 
                <span 
                    className={`font-Inter text-[13px] font-[500] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_attribute_${index}_${attribute.id}_text`}
                    >
                    {chunk.content}
                </span> : 
                <span className={`font-Inter text-[13px] font-[700] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_attribute_${index}_${attribute.id}_text`}>
                    {chunk.content}
                </span>
            })}
            
        </div>
    )
}


export const ConceptEntry: React.FC<ConceptEntryProps> = ({isSelected, concept, index, filter="", onClick}) => {
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        return handleLeave;
    }, []);

    const handleLeave = () => {
        if (tooltipRef.current != null) {
            closeTooltip(tooltipRef.current);
            tooltipRef.current = null;
        }
    };

    const handleOver = (e: any, concept: Concept) => {
        if (tooltipRef.current === null) {
            tooltipRef.current = spawnConceptTooltip({e, concept, conceptLevel: 0, arrowRef});
            e.stopPropagation();
        }
    }

    return (
        <div 
            className={`flex flex-row items-center h-[22px] w-fit rounded-[10px] border-[1px] ${isSelected ? "border-[#6E43F8]" : "border-[#E5DDFD]"} px-[8px] py-[3px] ${isSelected ? "bg-[#6E43F8]" : "bg-[#F3F4F6] hover:bg-[#EEE8FF]"} cursor-pointer whitespace-pre select-none`}
            onClick={(e) => {
                onClick();
            }}
            // onMouseEnter={(e) => {
            //     setTimeoutId(setTimeout(() => {handleOver(e, concept)}, 500));
            // }}
            // onMouseLeave={(e) => {
            //     if (timeoutId !== null) {
            //         clearTimeout(timeoutId);
            //         setTimeoutId(null);
            //     }
            //     handleLeave();
            // }}
            key={`_concept_${index}_${concept.id}`}
            id={`_concept_${index}`}
        >
            {parseFilter(concept.name, filter).map((chunk, index) => {
                return chunk.type === "text" ? 
                <span 
                    className={`font-Inter text-[13px] font-[500] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_concept_${index}_${concept.id}_text`}
                    >
                    {chunk.content}
                </span> : 
                <span className={`font-Inter text-[13px] font-[700] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_concept_${index}_${concept.id}_text`}>
                    {chunk.content}
                </span>
            })}
            
        </div>
    )
}
