import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ClipboardIcon, CheckIcon } from "@heroicons/react/24/outline";

interface CodeContainerProps {
  code: string;
}

const CodeContainer: React.FC<CodeContainerProps> = ({ code }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000); // Reset after 3 seconds
  };
  return (
    <div className="text-left rounded-md max-w-[65ch]">
      <CopyToClipboard text={code} onCopy={handleCopy}>
        <div className="flex items-center relative text-gray-200 bg-gray-600 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
          <span>sql</span>
          <button className="flex ml-auto gap-2">
            {isCopied ? (
              <>
                <CheckIcon className="h-4 w-4" />
                Copied!
              </>
            ) : (
              <>
                <ClipboardIcon className="h-4 w-4" />
                Copy Code
              </>
            )}
          </button>
        </div>
      </CopyToClipboard>
      <SyntaxHighlighter
        className="rounded-b-md"
        language="sql"
        style={atomOneDark}
        customStyle={{ padding: "1rem" }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeContainer;
