import React, { createContext, useState, useContext, useEffect } from "react";
import { Domain, Item, Metric } from "../@types/common"
import { useLibrary } from "./useLibrary"

// import { TreeItem, TreeItemIndex } from "react-complex-tree";
import * as api from "../api";
import { useToast } from "./useToast";
import { useAuth } from "./useAuth";
import { useMetrics } from "./useMetrics";

// type TreeItems = {[key: TreeItemIndex] : TreeItem<Domain | Item | "root">};

interface MetricDomainContextData {
    // focusedItem: Domain | Metric | null;
    // setFocusedItem: React.Dispatch<React.SetStateAction<Domain | Metric | null>>;
    domains: Domain[];
    createDomain: (domainName: string, canMove?: string, canRename?: string, levelNo?: number, parentDomainId?: number) => Promise<Domain>;
    updateDomain: (domainId: number, domainName: string, canMove: string, canRename: string, levelNo: number, parentDomainId: number) => Promise<void>;
    deleteDomain: (domainId: number) => void;
}

const MetricDomainContext = createContext<MetricDomainContextData | undefined>(undefined);

const useMetricDomain = () => {
    const context = useContext(MetricDomainContext);
    if (!context) {
        throw new Error("useMetricDomain must be used within a MetricDomainProvider");
    }
    return context;
};

const MetricDomainProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const {setCurrentItem} = useMetrics();
    const [ domains, setDomains ] = useState<Domain[]>([]);
    const { library } = useLibrary();
    const { userProfile } = useAuth();
    const toast = useToast();

    useEffect(() => {
        // fetch all domains on library change
        const fetchData = async () => {
            if (library && userProfile) {
                const domains = await api.fetchDomainList(library.id, userProfile.email);
                console.log("fetching domains");
                console.log(domains);
                return domains;
            } else {
                return [];
            }
        }
        fetchData().then((domains) => {
            setDomains(domains);
        }).catch((error) => {
            toast.error(error.message);
        });
    }, [library]);

    const createDomain = async (domainName: string, canMove = 'Y', canRename = 'Y', levelNo = 0, parentDomainId = 0) => {
        const id = await api.createDomain(library?.id || 0, userProfile?.email || "", domainName, canMove, canRename, levelNo, parentDomainId);
        const newDomain : Domain = {
            id: id,
            name: domainName,
            parent_id: parentDomainId,
            level_no: levelNo,
            can_move: canMove as "Y" | "N",
            can_rename: canRename as "Y" | "N",
            all_users: false,
        }
        setDomains((domains) => [newDomain, ...domains]);
        return newDomain;
    }

    const updateDomain = async (domainId: number, domainName: string, canMove: string, canRename: string, levelNo: number, parentDomainId: number) => {
        console.log("updating domain");
        return api.updateDomain(library?.id || 0, userProfile?.email || '', domainId, domainName, canMove, canRename, levelNo, parentDomainId).then(() => {
            setDomains((domains) => {
                const updatedDomains = domains.map((domain) => {
                if (domain.id !== domainId) {
                    return domain;
                } else {
                    console.log("found updated domain", domain);
                    return {
                            id: domainId,
                            name: domainName,
                            parent_id: parentDomainId,
                            level_no: levelNo,
                            can_move: canMove,
                            can_rename: canRename,
                            all_users: domain.all_users
                        } as Domain;
                    }
                })
                return [...updatedDomains];
            })
        })
    }

    const deleteDomain = async (domainId: number) => {
        api.deleteDomain(library?.id || 0, userProfile?.email || "", domainId);
        setDomains(domains.filter((domain) => domain.id !== domainId));
    }

    // const appendDomain = (domain: Domain[]) => {
    //   setDomain((oldDomain) => {
    //     let newDomain = [...oldDomain];
    //     domain.forEach((domain) => {
    //       if (oldDomain.every((old) => old.id !== domain.id)) {
    //         newDomain.push(domain);
    //       }
    //     })
    //     return newDomain;
    //   })
    // }

    return (
        <MetricDomainContext.Provider value={{ domains, createDomain, updateDomain, deleteDomain }}>
        {children}
        </MetricDomainContext.Provider>
    );
};

export { MetricDomainProvider, useMetricDomain };
