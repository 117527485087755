import React, { useRef, useEffect, useState } from "react";
import { format } from "sql-formatter";

import { ReactComponent as RepeatButton} from "../svg/repeat_button.svg";
import { ChatMessage } from "../@types/common";
import Loader from "./Loader";
import MoreButton from "./MoreButton";
import CodeContainer from "./CodeContainer";
import TableContainer from "./TableContainer";
import { useMetrics } from "../hooks/useMetrics";
import { spawnMetricTooltip } from "./ToolTip/MetricToolTip";
import FavoriteButton from "./FavoriteButton";
import { HistoryEntry, useHistory } from "../hooks/useHistory";
import { parseConcept, spawnConceptTooltip } from "./ToolTip/ConceptToolTip";
import { useConcept } from "../hooks/useConcepts";
import { closeTooltip, parseMessageContent } from "./ToolTip/ToolTipHelpers";
import { useCohort } from "../hooks/useCohorts";
import { spawnCohortTooltip } from "./ToolTip/CohortToolTip";
import SimpleToolTip, { SimpleToolTipId } from "./ToolTip/SimpleToolTip";
import { Page, useAuth } from "../hooks/useAuth";
import { useLibrary } from "../hooks/useLibrary";
import { useToast } from "../hooks/useToast";

interface MessageContainerProps {
  message: ChatMessage;
  shared?: boolean;
  page: Page;
}

const MessageContainer: React.FC<MessageContainerProps> = ({ message, shared=false, page }) => {
  const proseRef = useRef<HTMLDivElement>(null);
  const [showDetails, setShowDetails] = useState(0);
  const { metrics } = useMetrics();
  const { concepts } = useConcept();
  const { cohorts } = useCohort();
  const popupRef = useRef<HTMLDivElement | null>(null);
  const { history, addFavorite, removeFavoriteId } = useHistory();
  const popupArrowRef = useRef<SVGSVGElement | null>(null);
  const [ mouseOver, setMouseOver ] = useState(false);
  const {checkEditPrivilege} = useAuth();
  const {library} = useLibrary();
  const toast = useToast();

  const formatDateToTime = (date: Date): string => {
    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const minutes = date.getMinutes();
    const amPm = hours24 < 12 ? "am" : "pm";

    const hoursString = hours12.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");

    return `${hoursString}:${minutesString}${amPm}`;
  };

  const formatTimeSpent = (timeInSeconds: number) => {
    if (timeInSeconds > 60) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.round(timeInSeconds % 60);
      return `${minutes} minutes ${seconds} seconds`;
    }
    return `${timeInSeconds.toFixed(1)} seconds`;
  };

  const isSql = (message: ChatMessage) => {
    return message.content?.startsWith("SELECT");
  };

  const handleMetricHover = (e: any, name: string) => {
    const metric = metrics.find(m => m.metric_name.toLowerCase().trim() === name.toLowerCase().trim());
    if (!metric) return;

    const popup = spawnMetricTooltip(e, metric.metric_name, metric.metric_desc || "", metric.metric_logic || "", popupArrowRef);
    popupRef.current = popup;
    e.stopPropagation();
  }

  const handleTooltipLeave = (e: any) => {
    if (popupRef.current !== null) {
      closeTooltip(popupRef.current);
      popupRef.current = null;
    }
  }

  const handleConceptHover = (e: any, conceptString: string) => {
    const parsedConcept = parseConcept(conceptString);
    const concept = concepts.find(m => m.name.toLowerCase().trim() === parsedConcept.conceptName.toLowerCase().trim());
    if (!concept) return;

    const attribute = concept.attributes.find(a => a.name.toLowerCase().trim() === parsedConcept.attribute.toLowerCase().trim());
    if (!attribute) return;

    let popup : HTMLDivElement | null = null;
    if (parsedConcept.conceptLevel === 0) {
      popup = spawnConceptTooltip({e, concept, conceptLevel: 0, arrowRef: popupArrowRef});
    } else if (parsedConcept.conceptLevel === 1) {
      popup = spawnConceptTooltip({e, concept, attribute, conceptLevel: 1, arrowRef: popupArrowRef});
    } else if (parsedConcept.conceptLevel === 2) {
      popup = spawnConceptTooltip({e, concept, attribute, values: parsedConcept.values, conceptLevel: 2, arrowRef: popupArrowRef, allowCohort: false});
    }
    popupRef.current = popup;
    e.stopPropagation();
  }

  const handleCohortHover = (e: any, cohortName: string) => {
    const cohort = cohorts.find(c => c.name.toLowerCase().trim() === cohortName.toLowerCase().trim());
    if (!cohort) return;

    const concept = concepts.find(concept => concept.attributes.find(attribute => attribute.id === cohort.attribute_id) !== undefined);
    if (!concept) return;

    const attribute = concept.attributes.find(attribute => attribute.id === cohort.attribute_id);
    if (!attribute) return;

    popupRef.current = spawnCohortTooltip(e, cohort, concept.name, attribute.name, cohort.attribute_values, false, popupArrowRef);
    e.stopPropagation();
  }

  const metricPill = (index: number, chunk: any) => 
    <span key={index} className="h-fit w-fit rounded-[10px] border-[1px] border-[#E5DDFD] px-[8px] py-[3px] bg-metric-entry font-Inter text-[13px] font-[500] tracking-normal text-left leading-[16px] text-[#412B88]" 
      onMouseEnter={(e) => {
        handleMetricHover(e, chunk.content);
      }}
      onMouseLeave={(e) => handleTooltipLeave(e)}
    >
      {`[${chunk.content}]`}
    </span>

  const conceptPill = (index: number, chunk: any) => 
    <span key={index} className="h-fit w-fit rounded-[10px] border-[1px] border-[#E5DDFD] px-[8px] py-[3px] bg-metric-entry font-Inter text-[13px] font-[500] tracking-normal text-left leading-[16px] text-[#412B88]" 
      onMouseEnter={(e) => {
        handleConceptHover(e, chunk.content);
      }}
      onMouseLeave={(e) => handleTooltipLeave(e)}
    >
      {`{${chunk.content}}`}
    </span>
  
  const cohortPill = (index: number, chunk: any) =>
    <span key={index} className="h-fit w-fit rounded-[10px] border-[1px] border-[#E5DDFD] px-[8px] py-[3px] bg-metric-entry font-Inter text-[13px] font-[500] tracking-normal text-left leading-[16px] text-[#412B88]" 
      onMouseEnter={(e) => {
        handleCohortHover(e, chunk.content);
      }}
      onMouseLeave={(e) => handleTooltipLeave(e)}
    >
      {`@${chunk.content}@`}
    </span>

  const formatedMessage = (content: string) => {
    return <> 
      {
        parseMessageContent(content, cohorts).map((chunk, index) =>
          chunk.type === "text" ? (
            <span className="break-words" key={index}>{chunk.content}</span>
          ) : chunk.type === "link" ? (
            metricPill(index, chunk)
          ) : chunk.type === "link_concept" ? (
            conceptPill(index, chunk)
          ) : cohortPill(index, chunk)
        )
      }
    </>
  }

  const renderMessage = () => {
    return (
      <>
        {isSql(message) ? (
          <CodeContainer code={(() => {
            try {
              return format(message.content!);
            } catch {
              return message.content!;
            }
          })()} />
        ) : (
          <>
            <div
              className="prose max-w-none break-words text-left"
              ref={proseRef}
            >
              {message.content && formatedMessage(message.content)}

              {message.tableData && message.tableData.length > 0 && (
                <TableContainer
                  input={message.input!}
                  sql={message.sql!}
                  data={message.tableData}
                  head={message.tableHead!}
                  id={message.id}
                />
              )}
              {message.insight && message.insight !== "" && (
                <>
                  <p>Insights from above data:</p>
                  <p>{message.insight}</p>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  };

  const renderStreamMessage = () => {
    if (message.steps && message.steps.length > 0) {
      const lastStep = message.steps[message.steps.length - 1];
      let currentStep = message.currentStep ? message.steps[message.currentStep] : lastStep;
      if (! currentStep) {
        currentStep = lastStep;
      }

      if ((lastStep.status === "success" || currentStep.status === "error") && showDetails === 0) {
        // Mark last step complete
        setShowDetails(1);
      }
      // If last step is completed, render only last step content.
      if (showDetails === 1) {
        if (currentStep.status === "error") {
          return (
            <div className="flex flex-col divide-y divide-neutral-200">
              <div className="flex flex-col py-2 gap-2">
                <div className="">
                  {renderStepContent(currentStep, true)}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className="flex flex-col divide-y divide-neutral-200">
            <div className="flex flex-col py-2 gap-2">
              <div className="">
                {renderStepContent(lastStep, true)}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="flex flex-col divide-y divide-neutral-200 pt-2">
          {message.steps?.map((step, index) => (
            <div
              key={`${message.id}_${index}`}
              className="flex flex-col py-2 gap-2"
            >
              <div className={`flex items-center justify-between ${step.status === "queued" ? "text-gray-300" : "text-black"}`}>
                <p>
                  Step {index + 1}: {step.plan}
                </p>
                <div className="flex items-center">
                  {step.status === "loading" ? (
                    <div>
                      <span className="text-sm text-neutral-500 px-2">
                        Typically takes {step.estimatedTime} seconds
                      </span>
                      <Loader />
                    </div>
                  ) : (
                    step.timeSpent && (
                      <span className="text-sm text-neutral-500 px-2">
                        {formatTimeSpent(step.timeSpent)}
                      </span>
                    )
                  )}
                </div>
              </div>
              {step.status !== "loading" && (
                <div className="">
                  {renderStepContent(step)}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
  };

  const renderStepContent = (step: any, last?: boolean) => {
    switch (step.type) {
      case "plan":
        return <p className="text-left p-2 rounded-md bg-violet-100">{step.message}</p>
      case "text":
        return <div className={`text-left p-2 rounded-md ${last ? "" : "bg-violet-100"}`}>
          {formatedMessage(step.data)}
        </div>
      case "sql":
        return <CodeContainer code={(() => {
          try {
            return format(step.data);
          } catch {
            return step.data;
          }
        })()} />
      case "table":
        return <TableContainer
          input={message.input!}
          sql={message.sql!}
          data={step.tableData!}
          head={step.tableHead!}
          id={message.id}
        />
      case "error":
        return <div className={`text-left p-2 rounded-md ${last ? "" : "bg-violet-100"}`}>
          {formatedMessage(step.data)}
        </div>
      default:
        return null;
    }
  };


  useEffect(() => {
    // Scroll to the bottom when a new message is received
    if (proseRef.current) {
      proseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  return (
    <div
      className={`w-full h-fit flex flex-col ${message.sender === "user" ? "items-end" : "items-start"
        }`}
      id={message.id}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {
        message.sender === "user" && 
        <>
          <div className="w-fit h-fit flex flex-row gap-[12px] items-center">
            {!shared && 
              <div className="w-fit h-fit flex flex-row gap-[4px]">
                <RepeatButton className={`cursor-pointer outline-none ${mouseOver ? "opacity-[1]" : "opacity-[0]"}`} onClick={() => {
                  if (!checkEditPrivilege(page, library?.id || 0)) {
                    toast.warning("You do not have edit access to this page");
                    return;
                  }
                  document.dispatchEvent(new CustomEvent('messageRetry', {
                    detail: message.content
                  }));
                }}
                  data-tooltip-id={SimpleToolTipId}
                  data-tooltip-content={"retry"}
                />
                <FavoriteButton className={`cursor-pointer ${mouseOver ? "opacity-[1]" : "opacity-[0]"}`} isFav={() => {
                  let entry: HistoryEntry | undefined;
                  if (message.query_id !== undefined) {
                    entry = history.find((entry) => {return (entry.historyid === message.query_id);});
                  } else {
                    entry = history.find((entry) => {return (entry.userid === message.id);});
                  }
                  return (entry?.favorite === "Y");
                }}
                onClick={() => {
                  if (!checkEditPrivilege(page, library?.id || 0)) {
                    toast.warning("You do not have edit access to this page");
                    return;
                  }
                  let entry: HistoryEntry | undefined;
                  if (message.query_id !== undefined) {
                    entry = history.find((entry) => {return (entry.historyid === message.query_id);});
                  } else {
                    entry = history.find((entry) => {return (entry.userid === message.id);});
                  }
                  if (entry !== undefined) {
                    if (entry.favorite === "Y") {
                      removeFavoriteId(entry?.historyid || "");
                    } else {
                      addFavorite({
                          id: entry.id,
                          message: entry.message,
                          favorite: "Y",
                          historyid: entry.historyid,
                      });
                  }
                }}}/>
              </div>
            }
            <div className="w-full max-w-[700px] h-fit rounded-[10px] rounded-br-[0px] border-[1px] border-[#6A5A9D] py-[8px] px-[11px] bg-[#6E43F8]">
              <div className="font-Inter text-[13px] font-[500] text-white text-left break-words">
                {message.content && formatedMessage(message.content)}
              </div>
            </div>
          </div>
          <div className="h-[21px] w-fit pr-[21px]">
            <div className="font-Inter text-[12px] font-[500] opacity-[0.40] text-[#6B7280] text-right">
              {formatDateToTime(message.date)}
            </div>
          </div>
        </>
      }
      {
        message.sender === "server" &&
        <>
          {message.status === "loading" ? (
          <div className="w-full flex justify-end py-2">
            <Loader />
          </div>
        ) : (
          <div>
            <div
              className={`grow w-fit h-fit shadow-sm bg-[#ffffff] px-[13px] rounded-[10px] rounded-bl-[0px]`}
              ref={proseRef}
            >
              {message.steps && message.steps.length > 0 ? (
                <>{renderStreamMessage()}</>
              ) : (
                <>{renderMessage()}</>
              )}
            </div>
            <MoreButton message={message} showDetails={showDetails} setShowDetails={setShowDetails} mouseOver={mouseOver} page={shared?'Shared':"Q&A"} />
          </div>
        )}
        </>
      }
    </div>
  );
};

export default MessageContainer;
