import React, { createContext, useState, useContext, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import mixpanel from 'mixpanel-browser';
import * as api from '../api';

export interface UserProfile {
  id: string;
  email: string;
  name: string;
  picture: string;
  expiresAt: number;
  privilege: Privilege;
}

export type Page = 'Q&A' | 'MetricCentral' | 'ConceptBuilder' | 'MetricStore' | 'MyXolved' | 'Shared'

interface AuthContextData {
  isAuthenticated: boolean;
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile | null) => void;
  checkEditPrivilege: (page: Page, library: number) => boolean;
  checkAdmin: () => Promise<boolean>;
  checkSuperAdmin: () => Promise<boolean>;
}

export interface Privilege {
  library_1: 0|1|2; 
  library_2: 0|1|2;
  library_3: 0|1|2;
  library_4: 0|1|2;
  library_5: 0|1|2;
  library_6: 0|1|2;
  library_7: 0|1|2;
  library_10: 0|1|2;
  library_11: 0|1|2;
  library_12: 0|1|2;
  library_13: 0|1|2;
  library_14: 0|1|2;
  library_15: 0|1|2;
  library_16: 0|1|2;
  library_17: 0|1|2;
  library_18: 0|1|2;
  library_19: 0|1|2;
  library_20: 0|1|2;
  library_21: 0|1|2;
  library_22: 0|1|2;
  page_concept_builder: 0|1|2;
  page_metric_central: 0|1|2;
  page_my_xolved: 0|1|2;
  page_qa: 0|1|2;
  page_report_central: 0|1|2;
}

export type PrivilegeKey = keyof Privilege;

const defaultPrivilege : Privilege = {
  library_1: 0,
  library_2: 0,
  library_3: 0,
  library_4: 0,
  library_5: 0,
  library_6: 0,
  library_7: 0,
  library_10: 0,
  library_11: 0,
  library_12: 0,
  library_13: 0,
  library_14: 0,
  library_15: 0,
  library_16: 0,
  library_17: 0,
  library_18: 0,
  library_19: 0,
  library_20: 0,
  library_21: 0,
  library_22: 0,
  page_concept_builder: 0,
  page_metric_central: 0,
  page_my_xolved: 0,
  page_qa: 0,
  page_report_central: 0,
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(() => {
    const storedProfileString = localStorage.getItem("userProfile");
    if (!storedProfileString) {
      return null;
    }
    const storedProfile = JSON.parse(storedProfileString) as UserProfile;
    if (storedProfile.expiresAt < Date.now() / 1000) {
      localStorage.removeItem("userProfile");
      return null;
    }
    return storedProfile;
  });

  const isAuthenticated = userProfile !== null;

  useEffect(() => {
    if (userProfile) {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
      mixpanel.identify(userProfile.id);
      mixpanel.people.set({
        $email: userProfile.email,
        $last_login: new Date(),
      });
    } else {
      localStorage.removeItem("userProfile");
      mixpanel.reset();
    }
  }, [userProfile]);

  const checkEditPrivilege = (page: Page, library: number) => {
    if (userProfile === null || userProfile.privilege === undefined) {
      return false;
    }
    let pageEditable = false;
    if (page === 'Q&A') {
      pageEditable = userProfile.privilege.page_qa >= 2;
    } else if (page === 'MetricCentral') {
      pageEditable = userProfile.privilege.page_metric_central >= 2;
    } else if (page === 'ConceptBuilder') {
      pageEditable = userProfile.privilege.page_concept_builder >= 2;
    } else if (page === 'MetricStore') {
      pageEditable = true;
    } else if (page === 'MyXolved') {
      pageEditable = userProfile.privilege.page_my_xolved >= 2;
    } else if (page === 'Shared') {
      pageEditable = userProfile.privilege.page_qa >= 2;
    }
    const libraryKey = `library_${library}`;
    return (pageEditable && libraryKey in userProfile.privilege && userProfile.privilege[libraryKey as PrivilegeKey] >= 2) as boolean;
  }

  const checkAdmin = async () => {
    return await api.checkUserAdmin(userProfile?.email || "");
  }

  const checkSuperAdmin = async () => {
    return await api.checkUserSuperAdmin(userProfile?.email || "");
  }

  return (
    <GoogleOAuthProvider clientId="702059312908-9caa5ren1aqa25o77r1uf436hehd7tre.apps.googleusercontent.com">
      <AuthContext.Provider
        value={{ isAuthenticated, userProfile, setUserProfile, checkEditPrivilege, checkAdmin, checkSuperAdmin }}
      >
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

export { AuthProvider, useAuth };
