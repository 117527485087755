// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
// this is used for overlay scroll bar, importing here so all children will inherit these css
import Login from "./pages/Login";
import AuthedRoute from "./components/AuthedRoute";
import Home from "./pages/Home";
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import MetricCentral from "./pages/MetricCentral";
import ConceptCentral from "./pages/ConceptCentral";
import MetricStore from "./pages/MetricStore";
import MyXolved from "./pages/MyXolved";
import SharedSessionPage from "./pages/SharedSession";
import { useAuth } from "./hooks/useAuth";


const App: React.FC = () => {
  const GA_MEASUREMENT_ID = 'G-DWJVC6GXMB';
  ReactGA.initialize(GA_MEASUREMENT_ID);

  const MIXPANEL_TOKEN = "f0474f33876d744e8784c23e4c19b3ff"
  mixpanel.init(MIXPANEL_TOKEN);

  const {userProfile} = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={() => { }} />} />
        <Route element={<AuthedRoute />}>
          {(userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_qa >= 1) && <Route path="/" element={<Home />} />}
          {(userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_my_xolved >= 1) && <Route path="/myxolved" element={<MyXolved />} />}
          {(userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_metric_central >= 1) && <Route path="/metriccentral" element={<MetricCentral />} />}
          {(userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_concept_builder >= 1) && <Route path="/conceptbuilder" element={<ConceptCentral />} />}
          <Route path="/metricstore" element={<MetricStore />} />
          <Route path="/shared" element={<SharedSessionPage/>}/>
          <Route path="*" element={<Login onLogin={() => {}}/>}/>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
