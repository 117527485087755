import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg"
import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as QAButton } from "../svg/qa_button.svg" 
import { ReactComponent as PinIcon } from "../svg/pin_icon_large.svg" 
import { PinEntry, usePin } from "../hooks/usePin";
import PinContainer from "./PinContainer";
import MyXolvedStaticSidePanel from "./StaticLeftPanel/MyXolvedStaticSidePanel";
import { ReactComponent as PlusIcon } from "../svg/myxolved/plus_icon.svg";
import { ReactComponent as ResetIcon } from "../svg/myxolved/reset_icon.svg";
import { useMetrics } from "../hooks/useMetrics";
import { Metric } from "../@types/common";
import { groupByLetter } from "./BottomPanel/ChatBottomPanel";
import SearchBar, { applyFilterValue, applyFilterValueRaw } from "./SearchBar";
import MetricEntry from "./Entry/MetricEntry";
import { Cohort, useCohort } from "../hooks/useCohorts";
import { CohortEntry } from "./Entry/CohortEntry";

interface MyXolvedPanelProps {
    panelName: string;
    setPanelName: (t: string) => void;
}

const MyXolvedPanel: React.FC<MyXolvedPanelProps> = ({panelName, setPanelName}) => {
    const { pin } = usePin();
    const [ pinLoaded, setPinLoaded ] = React.useState(false);

    const [ insightState, setInsightState ] = React.useState("init");

    const {metrics} = useMetrics();
    const {cohorts} = useCohort();

    const [selectedMetric, setSelectedMetric] = React.useState<Metric | null>(null);
    const [selectedGroups, setSelectedGroups] = React.useState<Cohort[]>([]);
    const [groupSelected, setGroupSelected] = React.useState(false);

    const [allSelected, setAllSelected] = React.useState(true);

    // const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [metricFilterValue, setMetricFilterValue] = useState("");
    const [metricIsSearching, setMetricIsSearching] = useState(false);
    const [groupsFilterValue, setGroupsFilterValue] = useState("");
    const [groupsIsSearching, setGroupsIsSearching] = useState(false);

    const groupedMetrics : {[key: string]: Metric[]} = groupByLetter(metrics.filter((metric) => applyFilterValueRaw(metricFilterValue)(metric.metric_name)));
    const groupedMetricsNum = metrics.filter((metric) => applyFilterValueRaw(metricFilterValue)(metric.metric_name)).length;

    const groupedCohorts = cohorts.filter(applyFilterValue(groupsFilterValue))
    const groupedCohortsNum = groupedCohorts.length;

    const handleMetricClick = (metric: Metric) => {
        setSelectedMetric(metric);
    }
    
    const reset = () => {
        setInsightState("create");
        setSelectedMetric(null);
    }

    return (
        <div className="h-screen w-full overflow-x-auto flex-1 flex flex-row bg-[#f5f5f5] pt-[56px] justify-between" id="main_panel_3">
            <MyXolvedStaticSidePanel
                panelName={panelName} 
                setPanelName={setPanelName}
            />

            {
                insightState === "init" && 
                <div className="flex flex-col w-full h-full items-center justify-center gap-[18px]">
                    <StartIcon className="h-150 w-150" transform="scale(1)" />
                    <span className="font-Inter font-[700] text-[22px]">Use the Q&A to save queries on your Daily Snapshot.</span>
                    <span className="font-Inter font-[700] text-[17px]">Get start with quickly from left side favourited query or create a new one</span>
                    <div className="h-[77px]"></div>
                    <button className="w-[184px] h-[96px] rounded-[15px] bg-white flex items-center justify-center"
                        onClick={() => setInsightState("create")}
                    >
                        <PlusIcon/>
                    </button>
                </div>
            }
            {
                insightState === "create" &&
                <div className="flex flex-col w-full h-full items-center justify-center">
                    <div className="w-[503px] h-fit rounded-[15px] border-[1px] bg-white border-[#6E43F8] flex flex-col gap-[20px] px-[20px] py-[16px]">
                        {
                            selectedMetric === null ? 
                            <div className="flex flex-1 flex-col items-start max-h-[250px] min-h-[250px]">
                                <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                                    <div className="font-Inter font-[700] text-[16px]">
                                        Metrics
                                    </div>
                                    {
                                        groupedMetricsNum > 0 && 
                                        <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                            ({groupedMetricsNum})
                                        </div>
                                    }
                                    <SearchBar isSearching={metricIsSearching} setIsSearching={setMetricIsSearching} filterValue={metricFilterValue} setFilterValue={setMetricFilterValue} />
                                </div>
                                <div className="scrollbar w-full h-full pt-[10px] gap-[8px] flex flex-col overflow-y-auto items-start justify-start">
                                    {
                                        Object.keys(groupedMetrics).map((key, index) => {
                                            return (
                                                <div className="w-full h-fit gap-[8px] flex flex-row items-start justify-start" key={`_groupByLetterParent_${key}`}>
                                                    <span className="font-Inter font-[700] w-[16px] h-[20px] text-[14px] text-black opacity-[0.3] align-middle" key={`_groupByLetterParent_KeyName_${key}`}>
                                                        {key}
                                                    </span>
                                                    <div className="w-full h-fit pt-10px gap-[8px] flex flex-row flex-wrap">
                                                        {groupedMetrics[key].map((item, index) => {
                                                            return (
                                                            <div key={item.metric_id}>
                                                                <MetricEntry metric={item} isSelected={false} filter={metricFilterValue} 
                                                                    onClick={() => {
                                                                        handleMetricClick(item);
                                                                    }}
                                                                />
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div> :
                            <div className="w-full h-fit flex flex-row justify-between whitespace-nowrap items-center">
                                <div className="flex flex-row w-[200px] h-full gap-[8px] items-center">
                                    <span className="font-Inter font-[700] text-[16px] text-black">Selected Metric:</span>
                                    <MetricEntry metric={selectedMetric} isSelected={true}/>
                                </div>
                                <button className="border-[1px] border-[#BB0000] rounded-[8px] gap-[6px] flex flex-row py-[6px] px-[10px]" onClick={reset}>
                                    <ResetIcon/>
                                    <span className="font-Inter font-[500] text-[13px] text-[#BB0000]"> Reset</span>
                                </button>
                            </div>
                        }
                        {
                            selectedMetric !== null && (
                                !groupSelected ? 
                                <div className="flex flex-col items-start max-h-[250px] min-h-[250px]">
                                    <div className="w-full h-fit flex flex-row">
                                        <div className="flex-1 w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                                            <div className="font-Inter font-[700] text-[16px]">
                                                My Groups
                                            </div>
                                            {
                                                groupedCohortsNum > 0 && 
                                                <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                                    ({groupedCohortsNum})
                                                </div>
                                            }
                                            <SearchBar isSearching={groupsIsSearching} setIsSearching={setGroupsIsSearching} filterValue={groupsFilterValue} setFilterValue={setGroupsFilterValue} />
                                        </div>
                                        <button className="w-fit h-fit px-[10px] py-[6px] border-[1px] rounded-[8px] border-[#6E43F8] font-Inter font-[500] text-[13px] text-[#6E43F8]"
                                            onClick={() => {setGroupSelected(true)}}
                                        >
                                            Confirm
                                        </button>
                                    </div>

                                    
                                    <div className="scrollbar w-full h-full pt-[10px] gap-[8px] flex flex-col overflow-y-auto items-start justify-start">
                                        {
                                            groupedCohorts.map((item, index) =>
                                                <div key={item.id}>
                                                    <CohortEntry cohort={item} isSelected={selectedGroups.find((cohort) => cohort.id === item.id) !== undefined} index={index} filter={groupsFilterValue} 
                                                        onClick={() => {
                                                            const contain = selectedGroups.find((cohort) => cohort.id === item.id) !== undefined;
                                                            if (contain) {
                                                                setSelectedGroups(selectedGroups.filter((cohort) => cohort.id !== item.id));
                                                            } else {
                                                                setSelectedGroups([...selectedGroups, item]);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div> : 
                                <div className="w-full h-fit flex flex-row justify-between whitespace-nowrap items-center">
                                    <div className="scrollbar flex flex-row w-[350px] h-full gap-[8px] items-center">
                                        <span className="font-Inter font-[700] text-[16px] text-black">Applied Groups:</span>
                                        <div className="overflow-auto w-full h-full flex flex-row items-center gap-[8px]">
                                            {
                                                selectedGroups.map((cohort) => <CohortEntry cohort={cohort} isSelected={true} index={0} filter={groupsFilterValue} 
                                                    onClick={() => {}}
                                                />)
                                            }
                                        </div>
                                    </div>
                                    <button className="font-Inter font-[500] text-[13px] text-black underline"
                                        onClick={() => {
                                            setGroupSelected(false);
                                            setSelectedGroups([]);
                                        }}
                                    >Change</button>
                                </div>
                            )
                        }
                        {
                            groupSelected && 
                            <div className="w-full h-fit flex flex-col">
                                <div className="scrollbar flex flex-row w-full h-full gap-[8px] items-center justify-start">
                                    <span className="font-Inter font-[700] text-[16px] text-black">Report period:</span>
                                    <div className="bg-[#6E43F8] h-[22px] w-fit rounded-[10px] px-[9px] gap-[1px] flex flex-row items-center">
                                        <span className="font-Inter font-[500] text-[13px] text-white">Year to day</span>
                                    </div>
                                    <span className="font-Inter font-[600] text-[11px]"> 01 Jan 2024 - 06 Mar 2024 (today) </span>
                                </div>
                                <button className="self-end w-fit h-fit px-[10px] py-[6px] border-[1px] rounded-[8px] border-[#6E43F8] font-Inter font-[500] text-[13px] text-[#6E43F8]"
                                    onClick={() => {setAllSelected(true)}}
                                >
                                    Confirm
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default MyXolvedPanel;
