import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Privilege, useAuth } from "../hooks/useAuth";
import { Dialog, Listbox, Menu, Transition } from "@headlessui/react";
import * as api from "../api";
import { useToast } from "../hooks/useToast";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

interface UserProfileProps {
    className: string
}

interface TripleDialsProps {
  name: string;
  value: 0|1|2;
  setValue: (i: 0|1|2) => void
}

const TripleDials: React.FC<TripleDialsProps> = ({name, value, setValue}) => {
  return (
    <div className="flex flex-row w-full h-fit justify-between items-center select-none pl-[20px]">
      <div className="font-Inter font-[500] text-[13px] text-black">
        {name}
      </div>
      <div className="flex flex-row">
        <div className="h-fit w-[80px] min-w-[80px] flex justify-center">
          <input type="radio" className='w-4 h-4 shadow border-indigo-900 border-1 checked:border-4 focus:border-indigo-900 checked:border-indigo-900 focus:ring-[0] cursor-pointer'
            onClick={() => setValue(0)}
            checked={value === 0}
          />
        </div>
        <div className="h-fit w-[80px] min-w-[80px] flex justify-center">
          <input type="radio" className='w-4 h-4 shadow border-indigo-900 border-1 checked:border-4 focus:border-indigo-900 checked:border-indigo-900 focus:ring-[0] cursor-pointer'
            onClick={() => setValue(1)}
            checked={value === 1}
          />
        </div>
        <div className="h-fit w-[80px] min-w-[80px] flex justify-center">
          <input type="radio" className='w-4 h-4 shadow border-indigo-900 border-1 checked:border-4 focus:border-indigo-900 checked:border-indigo-900 focus:ring-[0] cursor-pointer'
            onClick={() => setValue(2)}
            checked={value === 2}
          />
        </div>
      </div>
    </div>
  )
}

const UserProfile: React.FC<UserProfileProps> = ({className}) => {
  const { userProfile, setUserProfile, checkAdmin, checkSuperAdmin } = useAuth();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [allUsers, setAllUsers] = useState<string[]>([]);
  const [targetEmail, setTargetEmail] = useState("");
  const [targetPrivilege, setTargetPrivilege] = useState<Privilege | null>(null);
  const [targetIsAdmin, setTargetIsAdmin] = useState(false);
  const toast = useToast();

  const [qaPriv, setQAPriv] = useState<0|1|2>(0);
  const [metricPriv, setMetricPriv] = useState<0|1|2>(0);
  const [conceptPriv, setConceptPriv] = useState<0|1|2>(0);
  const [myXolvedPriv, setMyXolvedPriv] = useState<0|1|2>(0);
  const [acutFinaPriv, setAcutFinaPriv] = useState<0|1|2>(0);
  const [acutSuppPriv, setAcutSuppPriv] = useState<0|1|2>(0);
  const [acutRevePriv, setAcutRevePriv] = useState<0|1|2>(0);
  const [ambuPatiPriv, setAmbuPatiPriv] = useState<0|1|2>(0);
  const [ambuOperPriv, setAmbuOperPriv] = useState<0|1|2>(0);
  const [ambuQualPriv, setAmbuQualPriv] = useState<0|1|2>(0);
  const [hediEffePriv, setHediEffePriv] = useState<0|1|2>(0);

  const [makeAdmin, setMakeAdmin] = useState(false);

  useEffect(() => {
    setMakeAdmin(targetIsAdmin);
  }, [targetIsAdmin]);

  useEffect(() => {
    if (targetEmail !== "")
      handleGetTargetPrivilege();
  }, [targetEmail])

  useEffect(() => {
    api.getUserEmails(userProfile?.email || "").then((users) => {
      console.log(users);
      setAllUsers(users);
    })
  }, [userProfile])

  useEffect(() => {
    if (targetPrivilege) {
      setQAPriv(targetPrivilege.page_qa);
      setMetricPriv(targetPrivilege.page_metric_central);
      setConceptPriv(targetPrivilege.page_concept_builder);
      setMyXolvedPriv(targetPrivilege.page_my_xolved);

      setAcutFinaPriv(targetPrivilege.library_5);
      setAcutSuppPriv(targetPrivilege.library_10);
      setAcutRevePriv(targetPrivilege.library_11);

      setAmbuPatiPriv(targetPrivilege.library_12);
      setAmbuOperPriv(targetPrivilege.library_13);
      setAmbuQualPriv(targetPrivilege.library_14);

      setHediEffePriv(targetPrivilege.library_15);
    } else {
      setQAPriv(0);
      setMetricPriv(0);
      setConceptPriv(0);
      setMyXolvedPriv(0);

      setAcutFinaPriv(0);
      setAcutSuppPriv(0);
      setAcutRevePriv(0);

      setAmbuPatiPriv(0);
      setAmbuOperPriv(0);
      setAmbuQualPriv(0);

      setHediEffePriv(0);
    }
  }, [targetPrivilege]);

  useEffect(() => {
    setTargetEmail("");
    setTargetPrivilege(null);
    setTargetIsAdmin(false);
    setMakeAdmin(false);
  }, [isAdminOpen]);

  useEffect(() => {
    checkAdmin().then((isAdmin) => {
      setIsAdmin(isAdmin);
    }).catch(() => {
      setIsAdmin(false);
    });
    checkSuperAdmin().then((isSuperAdmin) => {
      setIsSuperAdmin(isSuperAdmin);
    }).catch(() => {
      setIsSuperAdmin(false);
    })
  }, []);

  const handleLogout = () => {
    setUserProfile(null);
    navigate("/login");
  };

  const handleGetTargetPrivilege = () => {
    api.checkUserSuperAdmin(targetEmail).catch((e) => {
      console.log(e);
      toast.error("target email not found");
    }).then((targetIsSuperAdmin) => {
      if (targetIsSuperAdmin) {
        toast.error("You cannot edit privilege of another admin user");
        return;
      }
      api.checkUserAdmin(targetEmail).catch((e) => {
        console.log(e);
        toast.error("target email not found");
      }).then((targetIsAdmin) => {
        setTargetIsAdmin(targetIsAdmin || false);
        if (targetIsAdmin && !isSuperAdmin) {
          toast.error("You cannot edit privilege of another admin user");
          return;
        }
        api.fetchUserPrivilege(targetEmail).catch((e) => {
          console.log(e);
          toast.error("target email not found");
        }).then((targetPrivilege) => {
          if (targetPrivilege) {
            if (! ("page_qa" in targetPrivilege)) {
              toast.error("target email not found");
              return;
            }
            setTargetPrivilege(targetPrivilege);
          } else {
            toast.error("target email not found");
          }
        })
      })
    })
  }

  return (
    <>
    <Menu as="div" className={className}>
      <Menu.Button
        className="rounded-full box-content border-2 border-gray-50 hover:border-blue-300 hover:shadow-lg"
        role="button"
      >
        <img className="h-full w-full rounded-full" src={userProfile?.picture} alt="avatar"></img>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute flex flex-col left-12 transform -translate-y-full p-1 min-w-[100px] divide-y divide-gray-100 rounded-md bg-gray-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="flex p-4">
            <span>
              <p className="w-full whitespace-nowrap py-1 text-gray-900">
                Hello, <span className="font-bold">{userProfile?.name}</span>
              </p>
              <p className="text-gray-500">{userProfile?.email}</p>
            </span>
          </div>
          <div className="px-1 py-1 ">
            <Menu.Item>
              <button
                className="w-full py-1 text-gray-900 rounded-md hover:bg-gray-200"
                onClick={handleLogout}
              >
                Logout
              </button>
            </Menu.Item>
            {
              isAdmin &&
              <Menu.Item>
                <button
                  className="w-full py-1 text-gray-900 rounded-md hover:bg-gray-200"
                  onClick={() => setIsAdminOpen(true)}
                >
                  Manage Privilege
                </button>
              </Menu.Item>
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    <Transition appear show={isAdminOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => setIsAdminOpen(false)}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-[700px] transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-Inter font-medium leading-6 text-gray-900"
              >
                Edit Privilege
              </Dialog.Title>
              <div className="w-full h-fit flex flex-row mt-2 items-center gap-[20px]">
              <Listbox value={targetEmail} onChange={setTargetEmail}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full min-w-[300px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate">{targetEmail === "" ? "Please select email to modify privilege" : targetEmail}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {allUsers.map((user_email) => (
                        <Listbox.Option
                          key={user_email}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            }`
                          }
                          value={user_email}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {user_email}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              </div>
              {
                targetPrivilege &&
                <div className="mt-2 w-full h-fit flex flex-col select-none">
                  <div className="flex flex-row justify-end">
                    <div className="w-[80px] min-w-[80px] font-[500] text-[13px] text-black font-Inter text-center">
                      None
                    </div>
                    <div className="w-[80px] min-w-[80px] font-[500] text-[13px] text-black font-Inter text-center">
                      Can View
                    </div>
                    <div className="w-[80px] min-w-[80px] font-[500] text-[13px] text-black font-Inter text-center">
                      Can Edit
                    </div>
                  </div>
                  <div className="font-Inter font-[500] text-[16px] text-black">
                    Page Privilege
                  </div>
                  <TripleDials name="Q&A" value={qaPriv} setValue={setQAPriv}/>
                  <TripleDials name="Metric Central" value={metricPriv} setValue={setMetricPriv}/>
                  <TripleDials name="Concept Builder" value={conceptPriv} setValue={setConceptPriv}/>
                  <TripleDials name="My Xolved" value={myXolvedPriv} setValue={setMyXolvedPriv}/>
                  <div className="font-Inter font-[500] text-[16px] text-black">
                    Library Privilege
                  </div>
                  <TripleDials name="Acute - Finance" value={acutFinaPriv} setValue={setAcutFinaPriv}/>
                  <TripleDials name="Acute - Supply Chain" value={acutSuppPriv} setValue={setAcutSuppPriv}/>
                  <TripleDials name="Acute - Revenue Cycle" value={acutRevePriv} setValue={setAcutRevePriv}/>
                  <TripleDials name="Ambulatory - Patient Experience" value={ambuPatiPriv} setValue={setAmbuPatiPriv}/>
                  <TripleDials name="Ambulatory - Operations" value={ambuOperPriv} setValue={setAmbuOperPriv}/>
                  <TripleDials name="Ambulatory - Quality" value={ambuQualPriv} setValue={setAmbuQualPriv}/>
                  <TripleDials name="HEDIS - Effectiveness of Care" value={hediEffePriv} setValue={setHediEffePriv}/>
                  <div className="flex flex-row gap-[10px] items-center mt-2">
                    <div className="font-Inter font-[500] text-[16px] text-black">
                      Make Admin?
                    </div>
                    <input type="radio" className="w-4 h-4 shadow border-indigo-900 border-1 checked:border-4 focus:border-indigo-900 checked:border-indigo-900 focus:ring-[0] cursor-pointer"
                      checked={makeAdmin}
                      onClick={() => {
                        if (!makeAdmin && !targetIsAdmin)
                          toast.warning("Making user admin will give them full access and allow them to change other users' privilege");
                        setMakeAdmin(!makeAdmin);
                      }}
                    />
                    <div className="flex-1"></div>
                    <button className="h-fit w-fit font-[400] text-[15px] text-green-300 font-Inter border-1 border-green-300 rounded-[5px] px-[4px]"
                      onClick={() => {
                        if (makeAdmin && !targetIsAdmin) {
                          api.makeUserAdmin(userProfile?.email || "", targetEmail);
                        }
                        api.updateUserPrivilege(userProfile?.email || "", targetEmail, 
                          ['Q&A', 'MetricCentral', 'ConceptBuilder', 'MyXolved'], 
                          [qaPriv, metricPriv, conceptPriv, myXolvedPriv], 
                          [5, 10, 11, 12, 13, 14, 15], 
                          [acutFinaPriv, acutSuppPriv, acutRevePriv, ambuPatiPriv, ambuOperPriv, ambuQualPriv, hediEffePriv])
                        .then(() => {
                          toast.success("Updated user privilege");
                        }).catch(() => {
                          toast.error("failed to update user privilege");
                        });
                        setIsAdminOpen(false);
                      }}
                    >
                      Save
                    </button>
                    <button className="h-fit w-fit font-[400] text-[15px] text-red-300 font-Inter border-1 border-red-300 rounded-[5px] px-[4px]"
                      onClick={() => {
                        setIsAdminOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              }
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
    </Transition>
    </>
  );
};

export default UserProfile;
