import { forwardRef, useState } from "react";
import { ReactComponent as TooltipArrow } from '../../svg/tooltip_arrow.svg';
import ReactDOM from "react-dom";
import { ReactComponent as CheckIcon } from '../../svg/metric_bottom/check_icon.svg';
import { ReactComponent as SmallCheckIcon } from '../../svg/metric_bottom/small_check_icon.svg';
import { Attribute, Concept } from "../../@types/common";
import { closeTooltip } from "./ToolTipHelpers";

type TooltipProps = {
    concept: Concept;
    conceptLevel: 0;
} | {
    concept: Concept;
    attribute: Attribute;
    conceptLevel: 1;
} | {
    concept: Concept;
    attribute: Attribute;
    values: string[];
    conceptLevel: 2;
    allowCohort: boolean;
}

const Tooltip = forwardRef<SVGSVGElement, TooltipProps>(
    (tooltipProps, arrowRef) => {
        const [editing, setEditing] = useState(false);
        const [editorValue, setEditorValue] = useState("");
        return (
            <div className='flex flex-col items-left max-w-[500px] min-w-[200px]'>
                <div className="bg-white h-fit w-full rounded-[12px] shadow-metricTooltip py-[10px] px-[8px] text-sm flex flex-col items-start gap-[10px]">
                    
                    <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                        <span className="font-Inter font-[700] text-[13px] text-black">
                            Concept:
                        </span>
                        <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle">
                            {tooltipProps.concept.name}
                        </span>
                    </div>
                    {
                        (tooltipProps.conceptLevel === 1 || tooltipProps.conceptLevel === 2) && 
                        <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                            <span className="font-Inter font-[700] text-[13px] text-black">
                                Attribute:
                            </span>
                            <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle">
                                {tooltipProps.attribute.name}
                            </span>
                        </div>
                    }
                    {
                        tooltipProps.conceptLevel === 2 &&
                        <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                            <span className="font-Inter font-[700] text-[13px] text-black">
                                Values:
                            </span>
                            {tooltipProps.values.map((value, index) => 
                            <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle"
                                key={`_concept_tooltip_${index}`}
                            >
                                {value}
                            </span>)}
                        </div>
                    }
                </div>
                <TooltipArrow ref={arrowRef}/>
            </div>
        );
});

type spawnConceptTooltipProps = {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    arrowRef: React.MutableRefObject<SVGSVGElement | null>;
    concept: Concept;
    conceptLevel: 0;
} | {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    arrowRef: React.MutableRefObject<SVGSVGElement | null>;
    concept: Concept;
    attribute: Attribute;
    conceptLevel: 1;
} | {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    arrowRef: React.MutableRefObject<SVGSVGElement | null>;
    concept: Concept;
    attribute: Attribute;
    values: string[];
    conceptLevel: 2;
    allowCohort: boolean;
}

const spawnConceptTooltip = (props: spawnConceptTooltipProps) => {
    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.visibility = 'hidden';
    popup.style.zIndex = '6';
    popup.onmouseleave=((e) => closeTooltip(popup));

    document.body.appendChild(popup);
    // document.body.appendChild(popup);

    const target = props.e.target as HTMLDivElement;
    const rect = target.getBoundingClientRect();
    ReactDOM.render(<Tooltip {...props} ref={props.arrowRef}/>, popup);
    // ReactDOM.render(<Tooltip concept={concept} attribute={attribute} values={values} conceptLevel={conceptLevel} ref={arrowRef} allowCohort={allowCohort} onCohort={onCohort}/>, popup);

    const popupHeight = popup.getBoundingClientRect().height;
    const popupWidth = popup.getBoundingClientRect().width;
    const arrowWidth = props.arrowRef.current !== null ? props.arrowRef.current.getBoundingClientRect().width : 0;

    // popup.style.top = `${rect.top - popupHeight}px`;
    // console.log(window.innerHeight - rect.top);
    popup.style.bottom = `${window.innerHeight - rect.top}px`;
    let leftPos = rect.left - popupWidth / 2 + rect.width / 2;
    if (leftPos + popupWidth > window.innerWidth) {
        leftPos = window.innerWidth - popupWidth;
        props.arrowRef.current!.style.marginLeft=`${rect.left + popupWidth - window.innerWidth + rect.width / 2 - arrowWidth / 2}px`;
    }
    else if (leftPos < 60) {
        props.arrowRef.current!.style.marginLeft=`${popupWidth / 2 - arrowWidth / 2 + leftPos - 60}px`;
        leftPos = 60;
    }
    else {
        props.arrowRef.current!.style.marginLeft=`${popupWidth / 2 - arrowWidth / 2}px`;
    }
    popup.style.left = `${leftPos}px`

    popup.style.visibility = 'visible';
    return popup;
}

const parseConcept = (inputText: string) => {
    const nameSplitLocation = inputText.indexOf(".");
    let conceptName: string;
    let attribute: string;
    let values: string[];
    let conceptLevel: number;
    if (nameSplitLocation === -1) {
      conceptName = inputText;
      attribute = "";
      values = [];
      conceptLevel = 0;
    } else {
      conceptName = inputText.slice(0, nameSplitLocation);
      const attributeValueText = inputText.slice(nameSplitLocation + 1);
      const attributeSplitLocation = attributeValueText.indexOf(" = ");
      if (attributeSplitLocation === -1) {
        attribute = attributeValueText;
        values = [];
        conceptLevel = 1;
      } else {
        attribute = attributeValueText.slice(0, attributeSplitLocation);
        let valueText = attributeValueText.slice(attributeSplitLocation + 3);
        values = [];
        let valueSplitIndex = valueText.indexOf(",");
        while (valueSplitIndex !== -1) {
          values.push(valueText.slice(0, valueSplitIndex));
          valueText = valueText.slice(valueSplitIndex + 1);
          valueSplitIndex = valueText.indexOf(",");
        }
        values.push(valueText);
        conceptLevel = 2;
      }
    }
    return {conceptName: conceptName, attribute: attribute, values: values, conceptLevel: conceptLevel};
  }


export {spawnConceptTooltip, parseConcept};