import React, { useState } from "react";
import {
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "../svg/X_icon.svg";
import { ReactComponent as Chart } from "../svg/chart_icon.svg";
import { ReactComponent as Chat } from "../svg/chat_icon.svg";
import { ReactComponent as Chemistry } from "../svg/chemistry_icon.svg";
import { ReactComponent as Shop } from "../svg/shop_icon.svg";
import { ReactComponent as Pin } from "../svg/pin_icon.svg";
import { NavLink, useLocation } from "react-router-dom";
import UserProfile from "./UserProfile";
import { useAuth } from "../hooks/useAuth";

const Sidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [curPage, setCurPage] = useState("Q&A");
  const location = useLocation();
  const {userProfile} = useAuth();

  const NavLinkWithIcon = (to: any, Icon: any, label: any) => (
    <NavLink
      to={to}
      className={`flex items-center rounded-md space-x-3 h-10 p-[3px] hover:bg-white hover:bg-opacity-10 ${location.pathname === to && 'bg-white bg-opacity-25'}`}
      onClick={e => e.stopPropagation()}
    >
      <Icon className="w-[35px] h-[35px] min-w-[35px]" />
      <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>{label}</span>
    </NavLink>
  );

  const OutsideLinkWithIcon = (to: any, Icon: any, label: any) => (
    <a
      href={to}
      className={`flex items-center rounded-md space-x-3 h-10 hover:bg-white hover:bg-opacity-10 ${curPage === label && 'bg-white bg-opacity-25'}`}
      onClick={e => e.stopPropagation()}
    >
      <Icon className="h-6 w-6 min-w-[24px]" />
      <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>{label}</span>
    </a>
  );

  return (
    <>
      <nav
        className={`z-[20] flex left-0 fixed flex-col divide-y divide-indigo-700 h-full text-white hover:cursor-pointer
        ${isExpanded ? "w-[240px] fixed top-0 left-0" : "w-[56px]"} transition-all duration-300 z-10 overflow-visible`}
        onMouseEnter={() => setIsExpanded(true)} onMouseLeave={() => setIsExpanded(false)}
        style={{ background: `linear-gradient(180deg, #321891 0%, #150850 100%)` }}
      >
        <div className="flex items-center space-x-3 p-2" id="sidebar_logo">
          <Logo className="h-10 w-10 min-w-[40px]" />
          <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>Xolved</span>
        </div>
        <ul
          className="flex-1 flex flex-col p-2 justify-between space-y-2"
          id="sidebar_content"
        >
          {
            (userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_my_xolved >= 1) && 
            <li className="" key={"myxolved"}>
              {NavLinkWithIcon("/myxolved", Pin, "MyXolved")}
            </li>
          }
          {
            (userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_qa >= 1) && 
            <li className="" key={"Q&A"}>
              {NavLinkWithIcon("/", Chat, "Q&A")}
            </li>
          }
          {
            (userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_metric_central >= 1) &&
            <li className="" key={"metriccentral"}>
              {NavLinkWithIcon("/metriccentral", Chart, "Metric Central")}
            </li>
          }
          {
            (userProfile !== null && userProfile.privilege !== undefined && userProfile.privilege.page_concept_builder >= 1) &&
            <li className="" key={"conceptbuilder"}>
              {NavLinkWithIcon("/conceptbuilder", Chemistry, "Concept Builder")}
            </li>
          }
          <li className="" key={"metricstore"}>
            {NavLinkWithIcon("/metricstore", Shop, "Metric Store")}
          </li>
          <div className="grow" key={"space"}></div>
          <li className="flex items-center space-x-3 h-10 p-2" key={"setting"}>
            <Cog8ToothIcon className="h-6 w-6 min-w-[24px]" />
            <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>Settings</span>
          </li>
          <li className="flex items-center space-x-3 h-10 p-2" key={"profile"}>
            <UserProfile className="h-6 w-6 min-w-[24px] relative" />
            <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>Profile</span>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
