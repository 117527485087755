import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { useLibrary } from '../hooks/useLibrary';
import { Item, Library } from '../@types/common';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as XolvedIcon } from "../svg/XolvedIcon.svg"
import Loader from './Loader';
import LibraryBox from './LibraryBox';
import darkBG from "../svg/darkBG.svg"
import { useMetricDomain } from '../hooks/useMetricDomain';

const StorePanel: React.FC = () => {

    const [expandedLibrary, setExpandedLibrary] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const { library, setLibrary, libraries } = useLibrary();

    const handleLibraryClick = async (libraryId: number) => {
        if (expandedLibrary === libraryId) {
            setExpandedLibrary(null);
            return;
        }

        setLoading(true);
        setExpandedLibrary(libraryId);

        const selectedLibrary = libraries?.find(lib => lib.id === libraryId);
        setLibrary(selectedLibrary || null);

        setLoading(false);
    };

    const getStatusIcon = (status: string) => {
        if (status === "Deprecated") {
            return <XMarkIcon className="h-5 w-5 ml-1 inline-block" />
        } else if (status === "Verified") {
            return <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />
        }
        return null;
    }

    return (
        <div className="text-white text-left w-full px-4 flex flex-col justify-between overflow-auto"
            style={{ backgroundImage: `url(${darkBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>
            <div className='flex m-2 items-center'>
                <XolvedIcon className='h-10 w-10' />
                <span className='text-4xl font-bold'>Xolved</span>
                <div className='px-4 font-semibold'>Access the right metrics to understand your healthcare operations </div>
            </div>
            <div className='px-4'>1) Subscribe to just one or as many metric libraries as you need. </div>
            <div className='px-4'>2) Quickly connect to your institutions’ data sources using our tools and AI assistance. </div>
            <div className='px-4'>3) Extend and customize metrics to fit your unique needs. </div>
            <div className="h-full w-full py-2 px-2 flex flex-col">
                {libraries?.map((library) => (
                    <LibraryBox
                        key={library.id}
                        library={library}
                        expanded={expandedLibrary === library.id}
                        onLibraryClick={handleLibraryClick}
                        // currentItemSelected={item}
                    />
                ))}
            </div>
        </div>
    );
};

export default StorePanel;
