import React, { useState } from "react";

import ConceptLeft from "../components/ConceptLeft";
import ConceptPanel from "../components/ConceptPanel";
import Sidebar from "../components/Sidebar";
import { MetricNode } from "../lexical/MetricNode";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import TopBar from "../components/TopBar/TopBar";
import { Concept } from "../@types/common";

const ConceptCentral: React.FC = () => {
    const [ currentConcept, setCurrentConcept ] = useState<Concept | null>(null);

    const onError = (error: any) => {
        console.error(error);
    };

    const prepopulatedRichText = () => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const text = $createTextNode(currentConcept?.logic || "");
        paragraph.append(text);
        root.append(paragraph);
    }

    const editorConfig = {
        namespace: "metrics-editor",
        onError: onError,
        nodes: [MetricNode],
        editorState: prepopulatedRichText,
    };

    return (
        <div className="App pl-[56px] w-full h-screen relative flex">
            <LexicalComposer initialConfig={editorConfig}>
                <TopBar title="Concept Builder" />
                <Sidebar />
                <ConceptLeft currentConcept={currentConcept} setCurrentConcept={setCurrentConcept}/>
                <ConceptPanel page="ConceptBuilder" currentConcept={currentConcept} setCurrentConcept={setCurrentConcept}/>
            </LexicalComposer>
        </div>
    );
};

export default ConceptCentral;
