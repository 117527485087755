import React, { createContext, useState, useContext, useEffect } from "react";
import { Item, Metric, Concept, isMetric, isConcept } from "../@types/common"
import { useLibrary } from "./useLibrary"
import { useAuth } from "./useAuth";
import * as api from "../api";
import { useToast } from "./useToast";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $createTextNode, $getRoot, LexicalEditor } from "lexical";

interface MetricsContextData {
    metrics: Metric[];
    currentItem: Metric | Concept | null;
    setCurrentItem: (item: Metric | Concept | null, updateHistory?: boolean) => void;

    addNewMetric: (domain_id: number, editor?: LexicalEditor | null) => Promise<Metric>;
    duplicateMetric: (metric_id: number, domain_id: number, editor?: LexicalEditor | null) => Promise<Metric>;
    deleteMetric: (metric_id: number) => Promise<void>;
    updateMetric: (metric: Metric) => void;

    // fetchData: (metric_id: number) => Promise<string>;
    generateSQL: (metric_id: number) => Promise<void>;

    undo: (editor: LexicalEditor) => void;
    canUndo: () => boolean;
    redo: (editor: LexicalEditor) => void;
    canRedo: () => boolean;

    savingMetric: boolean;
}

const MetricsContext = createContext<MetricsContextData | undefined>(undefined);

const useMetrics = () => {
    const context = useContext(MetricsContext);
    if (!context) {
        throw new Error("useMetrics must be used within a MetricsProvider");
    }
    return context;
};

const MetricsProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [metrics, setMetrics] = useState<Metric[]>([]);
    const [metricHistory, setMetricHistory] = useState<Metric[]>([]);
    const [lastCurrentMetric, setLastCurrentMetric] = useState<Metric | null>(null);
    const [currentItem, _setCurrentItem] = useState<Metric | Concept | null>(null);
    const [autoSaveId, setAutoSaveId] = React.useState<NodeJS.Timeout | null>(null);

    const {library} = useLibrary();
    const {userProfile} = useAuth();
    const toast = useToast();

    const [historyId, setHistoryId] = useState(0);
    const canUndo = () => metricHistory.length > historyId + 1;
    const canRedo = () => historyId > 0;

    const [savingMetric, setSavingMetric] = useState(false);

    useEffect(() => {
        // fetch all metrics on library change
        const fetchData = async () => {
            if (userProfile && library) {
                const metrics = await api.fetchMetricNew(userProfile.email, library.id);
                console.log("fetching metrics");
                console.log(metrics);
                return metrics;
            }
            return [];
        }
        fetchData().then((metrics) => {
            setMetrics(metrics);
        }).catch((error) => {
            toast.error(error.message);
        });
    }, [library]);

    const addNewMetric = (domain_id: number, editor: LexicalEditor | null = null) => {
        return new Promise<Metric>((res, rej) => {
            const newMetric: Metric = {
                metric_id: -1,
                metric_name: "Untitled Metric",
                domain_id: domain_id,
                domain_name: null,
                metric_desc: "",
                metric_logic: "",
                input_fields: "",
                output_fields: "",
                deprecated: "N",
                metric_status: "",
                sql_statement: "",
                modified_sql: "N",
                all_users: false,
            };
            api.createMetric(library?.id || 0, userProfile?.email || "", newMetric.domain_id || 0, newMetric.metric_name, newMetric.metric_desc || "", newMetric.sql_statement || "", newMetric.modified_sql || "N", newMetric.metric_logic || "", "", newMetric.input_fields || "", newMetric.output_fields || "", newMetric.metric_status || "", newMetric.deprecated || "N")
            .then((metric_id: number) => {
                newMetric.metric_id = metric_id;
                setMetrics([newMetric, ...metrics]);
                if (autoSaveId) {
                    clearTimeout(autoSaveId);
                }
                setAutoSaveId(null);
                _setCurrentItem(newMetric);
                initializeHistoryForMetric(newMetric);
                if (editor) {
                    editor.update(() => {
                        const root = $getRoot();
                        root.clear();
                        const paragraph = $createParagraphNode();
                        const text = $createTextNode("");
                        paragraph.append(text);
                        root.append(paragraph);
                    });
                }
                res(newMetric);
            }).catch((error) => {
                console.log(error);
                rej(error);
            })
        })
    }

    const duplicateMetric = (metric_id: number, domain_id: number, editor: LexicalEditor | null = null) => {
        return new Promise<Metric>((res, rej) => {
            const targetMetric: Metric | undefined = metrics.find((metric) => metric.metric_id === metric_id);
            if (targetMetric) {
                const newMetric : Metric = {
                    ...targetMetric, 
                    metric_name: `Copy of ${targetMetric.metric_name}`, 
                    metric_id: -1, 
                    metric_status: 'Draft', 
                    domain_id: domain_id,
                    all_users: false,
                };
                api.createMetric(library?.id || 0, userProfile?.email || "", newMetric.domain_id || 0, newMetric.metric_name, newMetric.metric_desc || "", newMetric.sql_statement || "", newMetric.modified_sql || "N", newMetric.metric_logic || "", "", newMetric.input_fields || "", newMetric.output_fields || "", newMetric.metric_status || "", newMetric.deprecated || "N")
                .then((metric_id: number) => {
                    newMetric.metric_id = metric_id;
                    setMetrics([newMetric, ...metrics]);
                    if (autoSaveId) {
                        clearTimeout(autoSaveId);
                    }
                    setAutoSaveId(null);
                    _setCurrentItem(newMetric);
                    initializeHistoryForMetric(newMetric);
                    if (editor) {
                        editor.update(() => {
                            const root = $getRoot();
                            root.clear();
                            const paragraph = $createParagraphNode();
                            const text = $createTextNode(newMetric.metric_logic || "");
                            paragraph.append(text);
                            root.append(paragraph);
                        });
                    }
                    res(newMetric);
                }).catch((error) => {
                    console.log(error);
                    rej(error);
                });
            } else {
                rej("metric id not found");
            }
        })
    }

    const deleteMetric = (metric_id: number) => {
        return new Promise<void>((res, rej) => {
            const newMetric: Metric | undefined = metrics.find((metric) => metric.metric_id === metric_id);
            if (newMetric) {
                const oldCurrentItem = currentItem;
                const oldMetrics: Metric[] = [...metrics];
                newMetric.metric_status = "Deprecated";
                newMetric.deprecated = "D";
                _setCurrentItem(null);
                setMetrics((metrics) => {
                    api.updateMetric(library?.id || 0, userProfile?.email || "", newMetric.metric_id, newMetric.domain_id || 0, newMetric.metric_name, newMetric.metric_desc || "", newMetric.sql_statement || "", newMetric.modified_sql || "N", newMetric.metric_logic || "", "", newMetric.input_fields || "", newMetric.output_fields || "", newMetric.metric_status || "", newMetric.deprecated || "N")
                    .then(() => {
                        res();
                    }).catch((error) => {
                        console.log(error);
                        setMetrics(oldMetrics);
                        _setCurrentItem(oldCurrentItem);
                        rej(error);
                    });
                    return metrics.filter((metric) => metric.metric_id !== metric_id)
                });
            } else {
                rej("metric id not found");
            }
        })
    }

    const generateSQL = (metric_id: number) => {
        return new Promise<void>((res, rej) => {
            const metric = metrics.find((metric) => metric.metric_id === metric_id);
            if (metric) {
                api.metricGenerateSQL(library?.id || 0, userProfile?.email || "", metric.metric_name, metric.metric_desc || "", metric.metric_logic || "")
                .then((sql) => {
                    setMetrics((metrics) => 
                        metrics.map((metric) => metric.metric_id === metric_id ? {
                            ...metric,
                            sql_statement: sql,
                            modified_sql: 'N',
                        } : metric)
                    );
                    res();
                })
                .catch((error) => {
                    console.log(error);
                    rej(error);
                })
            }
        });
    }

    const updateMetric = (metric: Metric) => {
        setMetrics((metrics) => 
            metrics.map((old_metric) => old_metric.metric_id === metric.metric_id ? metric : old_metric)
        )
        setCurrentItem(metric);
    }

    const undo = (editor: LexicalEditor) => {
        if (canUndo()) {
            _setCurrentItem({...metricHistory[historyId + 1]});
            setLastCurrentMetric({...metricHistory[historyId + 1]});
            const metric = metricHistory[historyId + 1];
            setMetrics(metrics => metrics.map((other) => other.metric_id === metric.metric_id ? {...metric} : other));
            setHistoryId(historyId + 1);
            editor.update(() => {
                const root = $getRoot();
                root.clear();
                const paragraph = $createParagraphNode();
                const text = $createTextNode(metric.metric_logic || "");
                paragraph.append(text);
                root.append(paragraph);
            });
        }
    }

    const redo = (editor: LexicalEditor) => {
        if (canRedo()) {
            _setCurrentItem({...metricHistory[historyId - 1]});
            setLastCurrentMetric({...metricHistory[historyId - 1]});
            const metric = metricHistory[historyId - 1];
            setMetrics(metrics => metrics.map((other) => other.metric_id === metric.metric_id ? {...metric} : other));
            setHistoryId(historyId - 1);
            editor.update(() => {
                const root = $getRoot();
                root.clear();
                const paragraph = $createParagraphNode();
                const text = $createTextNode(metric.metric_logic || "");
                paragraph.append(text);
                root.append(paragraph);
            });
        }
    }

    const saveOldMetric = () => {
        if (lastCurrentMetric && !lastCurrentMetric.all_users) {
            setMetrics(metrics.map((metric) => metric.metric_id === lastCurrentMetric.metric_id ? {...lastCurrentMetric} : metric));
            api.updateMetric(library?.id || 0, userProfile?.email || "", lastCurrentMetric.metric_id, lastCurrentMetric.domain_id || 0, lastCurrentMetric.metric_name, lastCurrentMetric.metric_desc || "", lastCurrentMetric.sql_statement || "", lastCurrentMetric.modified_sql || "N", lastCurrentMetric.metric_logic || "", "", lastCurrentMetric.input_fields || "", lastCurrentMetric.output_fields || "", lastCurrentMetric.metric_status || "", lastCurrentMetric.deprecated || "N");
        }
    }

    const initializeHistoryForMetric = (metric: Metric) => {
        setMetricHistory([{...metric}]);
        setHistoryId(0);
        setLastCurrentMetric({...metric});
    }

    const delayedSaveHistory = (metric: Metric) => {
        if (metricHistory.length === 0) {
            console.log("error: history length is 0");
            return;
        }
        if (metricHistory[0].metric_id !== metric.metric_id) {
            console.log("error: history id does not equal metric id");
        }
        setMetricHistory([metric, ... (metricHistory.slice(historyId, undefined))]);
        setHistoryId(0);
        setMetrics(metrics => metrics.map((other) => other.metric_id === metric.metric_id ? {...metric} : other));
        setSavingMetric(false);
    }

    // in this function, currentItem is the old copy
    // item is the newer copy that is attempting to be saved
    const setCurrentItem = (item: Metric | Concept | null) => {
        if (isConcept(currentItem) || currentItem === null) {
            if (isMetric(item)) {
                // initialize history. then immediately finish
                if (autoSaveId) {
                    setSavingMetric(false);
                    clearTimeout(autoSaveId);
                }
                setAutoSaveId(null);
                initializeHistoryForMetric(item);
            }
        } else {
            // currentItem is metric
            if (isConcept(item) || item === null) {
                if (autoSaveId) {
                    setSavingMetric(false);
                    clearTimeout(autoSaveId);
                }
                setAutoSaveId(null);
                saveOldMetric();
                setMetricHistory([]);
                setHistoryId(0);
                setLastCurrentMetric(null);
            } else if (item.metric_id !== currentItem.metric_id) {
                // switching to new metric, force update last metric and setup history for new item
                if (autoSaveId) {
                    setSavingMetric(false);
                    clearTimeout(autoSaveId);
                }
                setAutoSaveId(null);
                saveOldMetric();
                initializeHistoryForMetric(item);
            } else {
                // item.id === currentItem.id
                if (autoSaveId) {
                    setSavingMetric(false);
                    clearTimeout(autoSaveId);
                }
                setSavingMetric(true);
                const id = setTimeout(() => {
                    delayedSaveHistory({...item});
                }, 500);
                setAutoSaveId(id);
                setLastCurrentMetric({...item});
            }
        }
        _setCurrentItem(item);
    }

    return (
        <MetricsContext.Provider value={{ metrics, currentItem, setCurrentItem, addNewMetric, duplicateMetric, deleteMetric, generateSQL, updateMetric, redo, undo, canRedo, canUndo, savingMetric}}>
            {children}
        </MetricsContext.Provider>
    );
};

export { MetricsProvider, useMetrics };
