import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useRef, useState } from "react";
import { Cohort, useCohort } from "../../hooks/useCohorts";
import { Attribute, Concept } from "../../@types/common";

import { ReactComponent as SaveGroupIcon } from "../../svg/metric_bottom/save_group_icon.svg";
import { useLibrary } from "../../hooks/useLibrary";
import { useAuth } from "../../hooks/useAuth";
import { useToast } from "../../hooks/useToast";

interface GroupButtonsProp {
    selectedValues: string[];
    selectedAttribute: Attribute | null;
    selectedConcept: Concept | null;
    replaceCurrentConceptWithCohort: (concept: Concept, attribute: Attribute | null, values: string[], cohort: Cohort) => void
}

const GroupButtons : React.FC<GroupButtonsProp> = ({selectedValues, selectedAttribute, selectedConcept, replaceCurrentConceptWithCohort}) => {
    const [editor] = useLexicalComposerContext();
    const {library} = useLibrary();
    const {userProfile, checkEditPrivilege} = useAuth();
    const {addCohort} = useCohort();
    
    const [savingGroup, setSavingGroup] = useState(false);
    const [groupName, setGroupName] = useState("");

    const toast = useToast();
    
    return (
        savingGroup ? 
        <div className="flex flex-row h-fit w-fit gap-[7px]">
            <div className="h-fit w-fit border-1 border-[#6E43F8] bg-white gap-[3px] rounded-[8px] py-[6px] px-[10px] select-none cursor-pointer flex flex-row items-center">
                <span className="font-Inter font-[500] text-[13px] text-[#6E43F8]">@</span>
                <input className="font-Inter font-[500] text-[13px] text-[#6E43F8] outline-0"
                    value={groupName}
                    autoFocus={true}
                    onChange={(e) => setGroupName(e.target.value)}
                    onBlur={() => editor.focus()}
                />
            </div>
            <div className={`h-fit w-fit border-1 ${groupName !== "" ? "border-[#6E43F8] bg-[#6E43F8] cursor-pointer" : "border-[#010616] bg-[#010616] opacity-[0.5]"} text-white rounded-[8px] py-[6px] px-[10px] select-none font-Inter font-[500] text-[13px]`}
                onClick={() => {
                    const cohort : Cohort = {
                        id: -1,
                        name: groupName,
                        library_id: library?.id || 0,
                        attribute_id: selectedAttribute?.id || 0,
                        user_email: userProfile?.email || "",
                        attribute_values: selectedValues,
                    }
                    editor.focus();
                    if (selectedConcept) {
                        addCohort(cohort).then(() => replaceCurrentConceptWithCohort(selectedConcept, selectedAttribute, selectedValues, cohort))
                        .catch((e: any) => {
                            if (e === "duplicate_name") {
                                toast.error("duplicate group name");
                            } else {
                                toast.error("unable to save group, please try again later");
                            }
                        });
                    }
                }}
            >
                Save
            </div>
            <div className="h-fit w-fit border-1 border-[#010616] bg-white rounded-[8px] py-[6px] px-[10px] select-none cursor-pointer font-Inter font-[500] text-[13px] text-[#010616] opacity-[0.5]"
                onClick={() => {
                    editor.focus();
                    setSavingGroup(false);
                    setGroupName("");
                }}
            >
                Cancel
            </div>
        </div> : 
        <div className="h-fit w-fit border-1 border-[#6E43F8] bg-white gap-[3px] rounded-[8px] py-[6px] px-[10px] select-none cursor-pointer flex flex-row items-center"
            onClick={() => {
                if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                    toast.warning("You do not have edit access to this page");
                    return;
                }
                setSavingGroup(true);
            }}
        >
            <SaveGroupIcon/>
            <span className="font-Inter font-[500] text-[13px] text-[#6E43F8]">
                Save as Group
            </span>
        </div>
    )
}

export default GroupButtons;