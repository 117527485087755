import { forwardRef, useState } from "react";
import { ReactComponent as TooltipArrow } from '../../svg/tooltip_arrow.svg';
import ReactDOM from "react-dom";
import { ReactComponent as PenIcon } from '../../svg/metric_bottom/pen_icon.svg';
import { ReactComponent as TrashIcon } from '../../svg/metric_bottom/trash_icon.svg';
import { ReactComponent as SmallCheckIcon } from '../../svg/metric_bottom/small_check_icon.svg';
import { closeTooltip } from "./ToolTipHelpers";
import { Cohort } from "../../hooks/useCohorts";
import * as api from "../../api";

interface TooltipProps {
    cohort: Cohort;
    concept: string;
    attribute: string;
    values: string[];
    allowEdit: boolean;
}

const Tooltip = forwardRef<SVGSVGElement, TooltipProps>(
    ({ cohort, concept, attribute, values, allowEdit}, ref) => {
        const [editorValue, setEditorValue] = useState<string>(cohort.name);
        const [isEditing, setIsEditing] = useState(false);
        return (
            <div className='flex flex-col items-left max-w-[500px] min-w-[200px]'>
                <div className="bg-white h-fit w-full rounded-[12px] shadow-metricTooltip py-[10px] px-[8px] text-sm flex flex-col items-start gap-[10px]">
                    <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                        <span className="font-Inter font-[700] text-[13px] text-black">
                            My Group:
                        </span>
                        <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle">
                            {cohort.name}
                        </span>
                    </div>
                    <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                        <span className="font-Inter font-[700] text-[13px] text-black">
                            Concept:
                        </span>
                        <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle">
                            {concept}
                        </span>
                    </div>
                    <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                        <span className="font-Inter font-[700] text-[13px] text-black">
                            Attribute:
                        </span>
                        <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle">
                            {attribute}
                        </span>
                    </div>
                    <div className="h-fit w-fit flex flex-col gap-[8px] items-start ">
                        <span className="font-Inter font-[700] text-[13px] text-black">
                            Values:
                        </span>
                        {values.map((value, index) => 
                        <span className="font-Inter font-[500] text-[13px] text-[#412B88] w-fit h-[22px] px-[8px] border-1 border-[#E5DDFD] rounded-[10px] bg-[#F3F4F6] align-middle"
                            key={`_concept_tooltip_${index}`}
                        >
                            {value}
                        </span>)}
                    </div>
                </div>
                <TooltipArrow ref={ref}/>
            </div>
        );
});

const spawnCohortTooltip = (e: any, cohort: Cohort, concept: string, attribute: string, values: string[], allowEdit: boolean, arrowRef: React.MutableRefObject<SVGSVGElement | null>) => {
    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.visibility = 'hidden';
    popup.style.zIndex = '6';
    popup.onmouseleave=((e) => {closeTooltip(popup)});

    document.body.appendChild(popup);

    const rect = e.target.getBoundingClientRect();
    ReactDOM.render(<Tooltip cohort={cohort} concept={concept} attribute={attribute} values={values} ref={arrowRef} allowEdit={allowEdit}/>, popup);

    const popupHeight = popup.getBoundingClientRect().height;
    const popupWidth = popup.getBoundingClientRect().width;
    const arrowWidth = arrowRef.current !== null ? arrowRef.current.getBoundingClientRect().width : 0;

    // popup.style.top = `${rect.top - popupHeight}px`;
    popup.style.bottom = `${window.innerHeight - rect.top}px`;
    let leftPos = rect.left - popupWidth / 2 + rect.width / 2;
    if (leftPos + popupWidth > window.innerWidth) {
        leftPos = window.innerWidth - popupWidth;
        arrowRef.current!.style.marginLeft=`${rect.left + popupWidth - window.innerWidth + rect.width / 2 - arrowWidth / 2}px`;
    }
    else if (leftPos < 60) {
        arrowRef.current!.style.marginLeft=`${popupWidth / 2 - arrowWidth / 2 + leftPos - 60}px`;
        leftPos = 60;
    }
    else {
        arrowRef.current!.style.marginLeft=`${popupWidth / 2 - arrowWidth / 2}px`;
    }
    popup.style.left = `${leftPos}px`

    popup.style.visibility = 'visible';
    return popup;
}

export {spawnCohortTooltip};