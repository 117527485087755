import React, { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Domain, Item, Metric, isDomain, isMetric } from "../@types/common";
import { createPortal } from "react-dom";
import { useMetricDomain } from "../hooks/useMetricDomain";


import { ReactComponent as NewFolderIcon } from "../svg/metric_tree/new_folder.svg";
import { ReactComponent as NewFolderWhiteIcon } from "../svg/metric_tree/new_folder_white.svg";
import { ReactComponent as ChevronRight } from "../svg/metric_tree/chevron_right.svg";
import { ReactComponent as ChevronDown } from "../svg/metric_tree/chevron_down.svg";
import { ReactComponent as ThreeDots } from "../svg/metric_tree/three_dots.svg";
import { ReactComponent as OptionDelete } from "../svg/session_panel/option_delete.svg";
import { ReactComponent as OptionRename } from "../svg/session_panel/option_rename.svg";
import { ReactComponent as VerifiedIcon } from "../svg/metric_tree/verified.svg";
import { ReactComponent as BigChevronUp } from "../svg/metric_tree/big_chevron_up.svg";
import { ReactComponent as BigChevronDown } from "../svg/metric_tree/big_chevron_down.svg";
import { ReactComponent as NewMetricIcon } from "../svg/metric_tree/new_metric_icon.svg";
import SimpleToolTip, { SimpleToolTipId } from "./ToolTip/SimpleToolTip";
import useMousePosition from "../hooks/useMousePosition";
import { useToast } from "../hooks/useToast";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useAuth } from "../hooks/useAuth";
import { useLibrary } from "../hooks/useLibrary";
import { Dialog, Transition } from "@headlessui/react";
import { useMetrics } from "../hooks/useMetrics";
import { useCustomTree } from "../hooks/useCustomTree";
import { useConcept } from "../hooks/useConcepts";

import {ReactComponent as FolderIcon} from "../svg/metric_tree/folder_icon.svg";
import {ReactComponent as DocumentIcon} from "../svg/metric_tree/document_icon.svg";

// import { FolderIcon, DocumentIcon } from "@heroicons/react/24/outline";

import "./CustomMetricTreeContainer.css";

const GAP_SIZE = 5;
const LINE_HEIGHT = 24;

interface DraggableMetricProps {
    draggedPosition: {x: number, y: number};
    setDraggedItem: Dispatch<SetStateAction<Metric | null>>;
    metric: Metric;
    focusedMetric: Metric | null;
    focusedDomain: Domain | null;
}

const DraggableMetric: React.FC<DraggableMetricProps> = ({draggedPosition, setDraggedItem, metric, focusedDomain, focusedMetric}) => {
    const mousePostion = useMousePosition();
    const {updateMetric} = useMetrics();
    const {metricToTreeID, domainToTreeID, canMove} = useCustomTree();

    let canMoveOn = false;
    if (focusedDomain) {
        canMoveOn = canMove(metricToTreeID(metric.metric_id), domainToTreeID(focusedDomain.id));
    } else if (focusedMetric) {
        if (focusedMetric.domain_id || 0 === 0) {
            canMoveOn = canMove(metricToTreeID(metric.metric_id), focusedMetric.all_users ? "public_root" : "private_root");
        } else {
            canMoveOn = canMove(metricToTreeID(metric.metric_id), domainToTreeID(focusedMetric.domain_id || 0));
        }
    }

    return (
        <div className={`w-fit max-w-full flex items-center rounded-md cursor-pointer px-2 fixed bg-[#6E43F8] text-white ${!canMoveOn && "opacity-[0.1]"}`}
            style={{
                top: `${mousePostion.y ? mousePostion.y + draggedPosition.y : 0}px`,
                left: `${mousePostion.x ? mousePostion.x + draggedPosition.x : 0}px`,
                height: `${LINE_HEIGHT}px`,
            }}
            onMouseUp={() => {
                setDraggedItem(null);
                if (focusedDomain) {
                    if (canMoveOn) {
                        const newMetric : Metric = {
                            ...metric,
                            domain_id: focusedDomain.id
                        }
                        updateMetric(newMetric);
                    }
                } else if (focusedMetric) {
                    if (canMoveOn) {
                        const newMetric : Metric = {
                            ...metric,
                            domain_id: focusedMetric.domain_id || 0,
                        }
                        updateMetric(newMetric);
                    }
                }
            }}
        >
            <span className={`font-Inter text-[13px] truncate`}>
                {metric.metric_name}
            </span>
        </div>
    )
}

interface DraggableDomainProps {
    draggedPosition: {x: number, y: number};
    setDraggedDomain: Dispatch<SetStateAction<Domain | null>>;
    domain: Domain;
    focusedMetric: Metric | null;
    focusedDomain: Domain | null;
}

const DraggableDomain : React.FC<DraggableDomainProps> = ({draggedPosition, setDraggedDomain, domain, focusedDomain, focusedMetric}) => {
    const mousePostion = useMousePosition();
    const {updateDomain, domains} = useMetricDomain();
    const {moveTreeItem, domainToTreeID, metricToTreeID, canMove, setFocusedItem} = useCustomTree();

    let canMoveOn = false;
    if (focusedDomain) {
        canMoveOn = canMove(domainToTreeID(domain.id), domainToTreeID(focusedDomain.id));
    } else if (focusedMetric) {
        if (focusedMetric.domain_id || 0 === 0) {
            canMoveOn = canMove(domainToTreeID(domain.id), focusedMetric.all_users ? "public_root" : "private_root");
        } else {
            canMoveOn = canMove(domainToTreeID(domain.id), domainToTreeID(focusedMetric.domain_id || 0));
        }
    }

    return (
        <span className={`w-fit h-fit fixed cursor-pointer ${!canMoveOn && "opacity-[0.1]"}`}
            style={{
                top: `${mousePostion.y ? mousePostion.y + draggedPosition.y : 0}px`,
                left: `${mousePostion.x ? mousePostion.x + draggedPosition.x : 0}px`,
            }}
            onMouseUp={() => {
                setDraggedDomain(null);
                if (focusedDomain) {
                    if (canMoveOn) {
                        // moveTreeItem(domainToTreeID(domain.id), domainToTreeID(focusedDomain.id));
                        updateDomain(domain.id, domain.name, domain.can_move, domain.can_rename, domain.level_no, focusedDomain.id).then(() => {
                            setFocusedItem(domainToTreeID(domain.id));
                        });
                    }
                } else if (focusedMetric) {
                    if (canMoveOn) {
                        // moveTreeItem(domainToTreeID(domain.id), domainToTreeID(focusedMetric.domain_id || 0), metricToTreeID(focusedMetric.metric_id));
                        updateDomain(domain.id, domain.name, domain.can_move, domain.can_rename, domain.level_no, focusedMetric.domain_id || 0).then(() => {
                            setFocusedItem(domainToTreeID(domain.id));
                        });
                    }}
            }}
        >
            <span className={`font-Inter text-[16px] font-[500] tracking-normal text-left leading-[16px] px-2 py-1 rounded-md bg-[#6E43F8] text-white`} >
                {domain.name}
            </span>
        </span>
    )
}

interface DomainOptionProps {
    item: Domain;
    dotsRef: React.RefObject<SVGSVGElement>;
    onRename: () => void;
    onDelete: () => void;
    onNewFolder: () => void;
    closeOption: () => void;
}

const DomainOptionMenu: React.FC<DomainOptionProps> = ({item, dotsRef, onRename, onDelete, onNewFolder, closeOption}) => {
    const leftPos = dotsRef.current?.getBoundingClientRect().right || 0;
    const topPos = dotsRef.current?.getBoundingClientRect().top || 0;
    const menuRef = useRef<HTMLDivElement>(null);

    const [openWarning, setOpenWarning] = useState(false);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeOption();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <div className="flex flex-col px-[10px] py-[8px] gap-[12px] w-fit h-fit bg-[#4D3890] rounded-[5px] fixed z-[6] select-none"
            style={{
                top: `${topPos}px`,
                left: `${leftPos}px`,
            }}
            onClick={(e) => {closeOption(); e.stopPropagation();}}
            ref={menuRef}
        >
            {
                <Transition appear show={openWarning} as={Fragment}>
                    <Dialog as="div" className="relative z-100" onClose={() => setOpenWarning(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto z-[10]">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <div className="mt-2">
                                <p className="font-Inter text-sm text-gray-500">
                                Are you sure you want to delete this folder? All metrics under the folder will be moved under "My Metrics".
                                </p>
                            </div>

                            <div className="mt-4 flex flex-row gap-[10px]">
                                <button
                                type="button"
                                className="font-Inter inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => {setOpenWarning(false)}}
                                >
                                No
                                </button>
                                <button
                                type="button"
                                className="font-Inter inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                    setOpenWarning(false);
                                    onDelete();
                                }}
                                >
                                Yes
                                </button>
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                    </Dialog>
                </Transition>
            }
            {
                item.can_rename === 'Y' &&
                <div className="flex flex-row w-fit h-fit gap-[10px] justify-start items-center cursor-pointer"
                    onClick={onRename}
                >
                    <OptionRename/>
                    <span className="font-Inter font-[400] text-white text-[12px]">
                        Rename
                    </span>
                </div>
            }
            {
                item.can_move === 'Y' &&
                <div className="flex flex-row w-fit h-fit gap-[10px] justify-start items-center cursor-pointer"
                    onClick={onNewFolder}
                >
                    <NewFolderWhiteIcon/>
                    <span className="font-Inter font-[400] text-white text-[12px]">
                        New Folder
                    </span>
                </div>
            }
            {
                item.can_move === 'Y' &&
                <div className="flex flex-row w-fit h-fit gap-[10px] justify-start items-center cursor-pointer"
                    onClick={(e) => {
                        setOpenWarning(true);
                        e.stopPropagation();
                    }}
                >
                    <OptionDelete/>
                    <span className="font-Inter font-[400] text-white text-[12px]">
                        Delete Folder
                    </span>
                </div>
            }
        </div>
    );
}

interface MetricContainerProps {
    metric: Metric;
    setDraggedItem: (m: Metric | null) => void;
    setDraggedPosition: (pos: {x: number, y: number}) => void;
    onMetricClick: (it: Metric) => void;
    focusedMetric: Metric | null;
    focusedDomain: Domain | null;
    metricFilter: string;
}

const MetricContainer: React.FC<MetricContainerProps> = ({metric, focusedDomain, focusedMetric, metricFilter, setDraggedItem, setDraggedPosition, onMetricClick}) => {
    const mousePosition = useMousePosition();
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
    const metricRef = useRef<HTMLDivElement>(null);
    const {tree, focusedItem, setFocusedItem, metricToTreeID} = useCustomTree();
    const {setCurrentItem} = useMetrics();

    const [editingName, setEditingName] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const onMouseDown = () => {
        if (!tree[metricToTreeID(metric.metric_id)].can_move) {
            return;
        }
        setFocusedItem("");
        const border = metricRef.current?.getBoundingClientRect();
        if (border && mousePosition.x && mousePosition.y) {
            setDraggedPosition({x: border.x - mousePosition.x, y: border.y - mousePosition.y});
        } else {
            setDraggedPosition({x: 0, y: 0});
        }
        setDraggedItem(metric);
    }

    return (
        <div className="flex items-center pl-[10px] gap-[5px]">
            <div className="min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px] flex items-center justify-center ml-[5px]">
                <DocumentIcon className="h-[16px] w-[16px]"/>
            </div>
            <div 
                className={`flex w-fit max-w-full flex items-center rounded-md cursor-pointer px-2 ${(focusedItem === metricToTreeID(metric.metric_id)) && 'bg-[#6E43F8] text-white cursor-text'}`}
                style={{
                    height: `${LINE_HEIGHT}px`
                }}
                ref={metricRef}
            >
                {
                    editingName ?
                    <input 
                        className={`containerTitle outline-none w-full font-Inter text-[13px] ${(focusedItem === metricToTreeID(metric.metric_id)) && 'bg-[#6E43F8] text-white'}`} 
                        autoFocus={true} 
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onFocus={e => {
                            e.target.select();
                        }}
                        onBlur={e => {
                            setEditingName(false);
                            setCurrentItem({
                                ...metric,
                                metric_name: inputValue,
                            });
                        }}
                        onKeyDown={(keyEvent) => {
                            if (keyEvent.key === "Enter") {
                                setEditingName(false);
                                setCurrentItem({
                                    ...metric,
                                    metric_name: inputValue,
                                });
                            } else if (keyEvent.key === "Escape") {
                                setEditingName(false);
                            }
                        }}
                        onClick = {(e) => {
                            e.stopPropagation();
                        }}
                    />
                    :
                    <span className={`font-Inter text-[13px] truncate`}
                        onMouseDown={() => {
                            const id = setTimeout(() => {
                                onMouseDown();
                            }, 100);
                            setTimeoutId(id);
                        }}
                        onMouseUp={() => {
                            if (timeoutId !== null) {
                                clearTimeout(timeoutId);
                                setTimeoutId(null);
                            }
                            setDraggedItem(null);
                        }}
                        onClick={() => {
                            if (focusedItem === metricToTreeID(metric.metric_id)) {
                                setEditingName(true);
                                setInputValue(metric.metric_name);
                            } else {
                                setFocusedItem(metricToTreeID(metric.metric_id));
                                onMetricClick(metric);
                            }
                        }}
                    >
                        {metric.metric_name}
                    </span>
                }
            </div>
        </div>
    )
}

interface DomainContainerProps {
    domain: Domain;
    depth: number;
    setDraggedItem: (m: Metric | null) => void;
    setDraggedDomain: (m: Domain | null) => void;
    setDraggedPosition: (pos: {x: number, y: number}) => void;
    onMetricClick: (it: Metric) => void;
    draggedItem: Metric | null;
    draggedDomain: Domain | null;
    focusedMetric: Metric | null;
    focusedDomain: Domain | null;
    metricFilter: string;
}

const DomainContainer: React.FC<DomainContainerProps> = ({domain, depth, draggedItem, draggedDomain, focusedMetric, focusedDomain, metricFilter, setDraggedItem, setDraggedDomain, setDraggedPosition, onMetricClick}) => {
    const {updateDomain, deleteDomain, createDomain} = useMetricDomain();
    const {tree, focusedItem, setFocusedItem, domainToTreeID, isParent, expandedTreeItem, setExpandedTreeItem, calculateHeight} = useCustomTree();

    const rootElement = document.getElementById("root");
    const [showMenu, setShowMenu] = useState(false);

    const dotsRef = useRef<SVGSVGElement>(null);

    const [editingName, setEditingName] = useState(false);
    const [inputValue, setInputValue] = useState(domain.name);

    const mousePosition = useMousePosition();
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    const domainRef = useRef<HTMLDivElement>(null);

    const [expanded, _setExpanded] = useState(false);
    const expandRef = useRef<HTMLDivElement>(null);

    const setExpanded = (e: boolean) => {
        _setExpanded(e);
        setExpandedTreeItem(domainToTreeID(domain.id), e);
    }

    const onMouseDown = () => {
        if (!tree[domainToTreeID(domain.id)].can_move) {
            return;
        }
        setFocusedItem("");
        const border = domainRef.current?.getBoundingClientRect();
        if (border && mousePosition.x && mousePosition.y) {
            setDraggedPosition({x: border.x - mousePosition.x + 20, y: border.y - mousePosition.y});
        } else {
            setDraggedPosition({x: 0, y: 0});
        }
        setDraggedDomain(domain);
    }

    useEffect(() => {
        if (isParent(domainToTreeID(domain.id), focusedItem)) {
            setExpanded(true);
        }
    }, [focusedItem, tree]);

    return (
        <div className={`h-fit w-full flex flex-col`}
            style={{
                paddingLeft: `${depth > 0 ? 0 : 0}px`
            }}
        >
            <div className={`w-full flex flex-row justify-between items-center cursor-pointer`}
                style={{
                    height: `${LINE_HEIGHT}px`
                }}
                onClick={() => {
                    setExpanded(!expanded);
                    setFocusedItem(domainToTreeID(domain.id));
                }}
                ref={domainRef}
                id={`metric_left_domain_${domain.id}`}
            >
                {
                    rootElement && showMenu && createPortal(
                        <DomainOptionMenu 
                            item={domain}
                            dotsRef={dotsRef} 
                            onRename={() => {
                                setInputValue(domain.name);
                                setEditingName(true);
                            }}
                            onDelete={() => {
                                // TODO: warn user about delete on popup window
                                deleteDomain(domain.id);
                            }}
                            onNewFolder={() => {
                                createDomain("untitled folder", 'Y', 'Y', 1, domain.id).then((newId) => {
                                    // setExpanded(true);
                                    // expand(domainToTreeID(domain.id));
                                    setFocusedItem(domainToTreeID(newId.id));
                                });
                            }}
                            closeOption={() => setShowMenu(false)
                        }/>, rootElement
                    )
                }
                <span className={`${
                    (draggedItem || (draggedDomain && draggedDomain.id !== domain.id )) && ((focusedDomain && focusedDomain.id === domain.id) || (focusedMetric && focusedMetric?.domain_id === domain.id)) && "bg-[#6E43F8]"
                    } font-Inter text-[16px] font-[500] tracking-normal text-left leading-[16px] text-[#412B88] cursor-default flex-row flex items-center rounded-md gap-[5px]`}>
                    {
                        expanded ?
                        <ChevronDown className="min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px]"/> : 
                        <ChevronRight className="min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px]"/>
                    }
                    <div className="min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px] flex items-center justify-center">
                        <FolderIcon className="h-[20px] w-[20px]"/>
                    </div>
                    {
                        editingName ?
                        <input 
                            className={`containerTitle outline-none w-full px-2 py-1 rounded-md ${focusedItem === domainToTreeID(domain.id) ? 'bg-[#6E43F8] text-white' : ''}`} 
                            autoFocus={true} 
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            onFocus={e => {
                                e.target.select();
                            }}
                            onBlur={e => {
                                setEditingName(false);
                                updateDomain(domain.id, inputValue, domain.can_move, domain.can_rename, domain.level_no, domain.parent_id || 0).then(() => {
                                    setFocusedItem(domainToTreeID(domain.id));
                                });
                            }}
                            onKeyDown={(keyEvent) => {
                                if (keyEvent.key === "Enter") {
                                    setEditingName(false);
                                    updateDomain(domain.id, inputValue, domain.can_move, domain.can_rename, domain.level_no, domain.parent_id || 0).then(() => {
                                        setFocusedItem(domainToTreeID(domain.id));
                                    });
                                    // updateSessionName(session.id, inputValue);
                                } else if (keyEvent.key === "Escape") {
                                    setEditingName(false);
                                }
                            }}
                            onClick = {(e) => {
                                e.stopPropagation();
                            }}
                        /> :
                        <span className={`px-2 py-1 rounded-md ${expanded && domain.can_rename === 'Y' ? 'cursor-text' : 'cursor-default'} ${focusedItem === domainToTreeID(domain.id) ? 'bg-[#6E43F8] text-white' : ''}`} 
                            onMouseDown={() => {
                                const id = setTimeout(() => {
                                    onMouseDown();
                                }, 100);
                                setTimeoutId(id);
                            }}
                            onMouseUp={() => {
                                if (timeoutId !== null) {
                                    clearTimeout(timeoutId);
                                    setTimeoutId(null);
                                }
                                setDraggedDomain(null);
                            }}
                        
                            onClick={(e) => {
                            if (expanded && domain.can_rename === 'Y') {
                                setInputValue(domain.name);
                                setEditingName(true);
                            }
                        }}>
                            {domain.name}
                        </span>
                    }
                    <SimpleToolTip/>
                </span>
                <div className="w-[33px] flex flex-row items-center justify-center">
                {
                    expanded && (domain.can_move === 'Y' || domain.can_rename === 'Y' ) && focusedItem === domainToTreeID(domain.id) &&
                    <ThreeDots className="cursor-pointer min-h-[19px] min-w-[19px]" ref={dotsRef} onClick={(e) => {
                        setShowMenu(!showMenu);
                        e.stopPropagation();
                    }}/>
                }
                </div>
            </div>
            <div className={`transition-all duration-300 overflow-hidden`}
                style={{
                    minHeight: expanded ? `${calculateHeight(domainToTreeID(domain.id)) - 29}px` : "0px",
                    maxHeight: expanded ? `${calculateHeight(domainToTreeID(domain.id)) - 29}px` : "0px",
                    paddingTop: expanded && calculateHeight(domainToTreeID(domain.id)) - 29 > 0 ? `${GAP_SIZE}px` : "0px",
                }}
            >
                <div ref={expandRef} className={`flex flex-col border-l-[1px] ml-[9.5px]`} style={{gap: `${GAP_SIZE}px`}}>
                    {
                        tree[domainToTreeID(domain.id)].children.map((tree_id) => {
                            const item = tree[tree_id].data;
                            if (isDomain(item)) {
                                return <li key={`Domain_${item.id}`}>
                                    <DomainContainer 
                                        domain={item} 
                                        depth={depth + 1}
                                        draggedItem={draggedItem}
                                        draggedDomain={draggedDomain}
                                        focusedMetric={focusedMetric}
                                        focusedDomain={focusedDomain}
                                        metricFilter={metricFilter}
                                        setDraggedItem={setDraggedItem} 
                                        setDraggedDomain={setDraggedDomain}
                                        setDraggedPosition={setDraggedPosition}
                                        onMetricClick={onMetricClick}
                                    />
                                </li>
                            } else if (isMetric(item)) {
                                return <li key={`Metric_${item.metric_id}`} className="pl-[10px] " id={`metric_left_metric_${item.metric_id}`}>
                                    <MetricContainer metric={item} focusedMetric={focusedMetric} focusedDomain={focusedDomain} metricFilter={metricFilter} setDraggedItem={setDraggedItem} setDraggedPosition={setDraggedPosition} onMetricClick={onMetricClick}/>
                                </li>
                            } else {
                                console.log("warning", item, "is nothing");
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

interface VerifiedMetricContainerProps {
    onMetricClick: (it: Metric) => void;
    parentRef: React.RefObject<HTMLDivElement>;
}

const VerifiedMetricContainer: React.FC<VerifiedMetricContainerProps> = ({onMetricClick, parentRef}) => {
    const { metrics, addNewMetric, currentItem, setCurrentItem } = useMetrics();
    const {domains, createDomain} = useMetricDomain();
    const [draggedItem, setDraggedItem] = useState<Metric | null>(null);
    const [draggedDomain, setDraggedDomain] = useState<Domain | null>(null);
    const [draggedPosition, setDraggedPosition] = useState({x: 0, y: 0});
    const rootElement = document.getElementById("root");

    const {tree, focusedItem, setFocusedItem, metricToTreeID, domainToTreeID, calculateHeight, publicMetricExpanded, setPublicMetricExpanded, privateMetricExpanded, setPrivateMetricExpanded, conceptExpanded, setConcpetExpanded} = useCustomTree();

    const mousePosition = useMousePosition();
    const toast = useToast();
    const [editor] = useLexicalComposerContext();
    const {checkEditPrivilege} = useAuth();    
    const {library} = useLibrary();

    const [focusedMetric, setFocusedMetric] = useState<Metric | null>(null);
    const [focusedDomain, setFocusedDomain] = useState<Domain | null>(null);

    const [metricFilter, setMetricFilter] = useState("");
    const [isFiltering, setIsFiltering] = useState(false);

    // const [publicMetricExpanded, setPublicMetricExpanded] = useState(false);
    // const [privateMetricExpanded, setPrivateMetricExpanded] = useState(false);
    // const [conceptExpanded, setConcpetExpanded] = useState(false);
    const { concepts } = useConcept();

    const privateMetricRef = useRef<HTMLUListElement>(null);
    const publicMetricRef = useRef<HTMLUListElement>(null);
    const conceptRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!(focusedItem in tree)) {
            return;
        }
        const item = tree[focusedItem].data;
        if (isMetric(item)) {
            const metricElement = document.getElementById(`metric_left_metric_${item.metric_id}`);
            metricElement?.scrollIntoView({ behavior: "smooth", block: "center"});
        } else if (isDomain(item)) {
            const domainElement = document.getElementById(`metric_left_domain_${item.id}`);
            domainElement?.scrollIntoView({ behavior: "smooth", block: "center"});
        }
    }, [focusedItem]);

    useEffect(() => {
        const onMetric = metrics.find((metric) => {
            const metricElement = document.getElementById(`metric_left_metric_${metric.metric_id}`);
            if (metricElement) {
                const border = metricElement.getBoundingClientRect();
                if (mousePosition.x && border.x <= mousePosition.x && mousePosition.x <= border.x + border.width &&
                    mousePosition.y && border.y <= mousePosition.y && mousePosition.y < border.y + border.height + GAP_SIZE) {
                        return true;
                    }
            }
            return false;
        });
        setFocusedMetric(onMetric || null);

        const onDomain = domains.find((domain) => {
            const domainElement = document.getElementById(`metric_left_domain_${domain.id}`);
            if (domainElement) {
                const border = domainElement.getBoundingClientRect();
                if (mousePosition.x && border.x <= mousePosition.x && mousePosition.x <= border.x + border.width &&
                    mousePosition.y && border.y <= mousePosition.y && mousePosition.y < border.y + border.height + GAP_SIZE) {
                        return true;
                    }
            }
            return false;
        });
        setFocusedDomain(onDomain || null);

        // TODO: change how scrolling work
        const parentBorder = parentRef.current?.getBoundingClientRect();
        if (draggedItem && parentBorder) {
            if (mousePosition.y && mousePosition.y < parentBorder.y + 20) {
                parentRef.current?.scrollTo({top: 0, behavior: 'smooth'});
            } else if (mousePosition.y && mousePosition.y > parentBorder.y + parentBorder.height - 20) {
                parentRef.current?.scrollTo({top: parentRef.current.scrollHeight, behavior: 'smooth'});
            } else {
                parentRef.current?.scrollTo({top: parentRef.current.scrollTop, behavior: 'smooth'});
            }
        } else {
            parentRef.current?.scrollTo({top: parentRef.current.scrollTop, behavior: 'smooth'});
        }
    }, [mousePosition]);

    return (
        <div className="h-fit w-full flex flex-col">
            {
                rootElement && draggedItem &&
                createPortal(
                    <DraggableMetric
                        draggedPosition={draggedPosition}
                        setDraggedItem={setDraggedItem}
                        metric={draggedItem}
                        focusedDomain={focusedDomain}
                        focusedMetric={focusedMetric}
                    />,
                    rootElement
                )
            }
            {
                rootElement && draggedDomain &&
                createPortal(
                    <DraggableDomain
                        draggedPosition={draggedPosition}
                        setDraggedDomain={setDraggedDomain}
                        domain={draggedDomain}
                        focusedDomain={focusedDomain}
                        focusedMetric={focusedMetric}
                    />,
                    rootElement
                )
            }
            <div className={`h-[50px] w-full flex flex-row gap-[5px] items-center select-none ${!publicMetricExpanded && "border-b-1"} border-[#F3F4F6]`}
                onClick={() => {
                    setPublicMetricExpanded(!publicMetricExpanded);
                }}
            >
                <div className='font-Inter font-bold text-black text-base text-[16px]'>
                    Approved Metrics
                </div>
                <VerifiedIcon className="min-w-[18px] min-h-[18px]" 
                    data-tooltip-id={SimpleToolTipId}
                    data-tooltip-content={"verified metrics in this folder cannot be edited"}
                />
                <div className="flex-1"/>
                { publicMetricExpanded ? <BigChevronUp/> : <BigChevronDown/> }
            </div>
            <div className={`transition-all  duration-300 overflow-hidden`}
                style={{
                    minHeight: publicMetricExpanded ? `${calculateHeight("public_root") - 29}px` : "0px",
                    maxHeight: publicMetricExpanded ? `${calculateHeight("public_root") - 29}px` : "0px",
                }}
            >
                <ul className="h-fit w-full gap-[5px] flex flex-col select-none"
                    ref={publicMetricRef}
                >
                    {
                        tree["public_root"].children.map((tree_id) => {
                            const item = tree[tree_id].data;
                            if (isDomain(item)) {
                                return <li key={`Domain_${item.id}`} className="">
                                    <DomainContainer 
                                        domain={item} 
                                        depth={0}
                                        draggedItem={draggedItem}
                                        draggedDomain={draggedDomain}
                                        focusedMetric={focusedMetric}
                                        focusedDomain={focusedDomain}
                                        metricFilter={metricFilter}
                                        setDraggedItem={setDraggedItem} 
                                        setDraggedDomain={setDraggedDomain}
                                        setDraggedPosition={setDraggedPosition}
                                        onMetricClick={onMetricClick}
                                    />
                                </li>
                            }
                            if (isMetric(item)) {
                                return <li key={`Metric_${item.metric_id}`} className="pl-[10px]" id={`metric_left_metric_${item.metric_id}`}>
                                    <MetricContainer metric={item} focusedMetric={focusedMetric} focusedDomain={focusedDomain} metricFilter={metricFilter} setDraggedItem={setDraggedItem} setDraggedPosition={setDraggedPosition} onMetricClick={onMetricClick}/>
                                </li>
                            }
                        })
                    }
                </ul>
            </div>
            <div className={`h-[50px] w-full flex flex-row gap-[5px] items-center select-none ${publicMetricExpanded && "border-t-1"} ${!privateMetricExpanded && "border-b-1"} border-[#F3F4F6]`}
                onClick={() => {
                    setPrivateMetricExpanded(!privateMetricExpanded)
                }}
            >
                <div className='font-Inter font-bold text-black text-base text-[16px]'>
                    My Metrics
                </div>
                <div className="flex-1"/>
                <div className='px-[5px] py-[2px] border-1 border-[#CACCD2] rounded-[8px] cursor-pointer'
                    data-tooltip-id={SimpleToolTipId}
                    data-tooltip-content={"create new metric"}
                    onClick={(e) => {
                        if (!checkEditPrivilege('MetricCentral', library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        if (!(focusedItem in tree)) {
                            addNewMetric(0, editor);
                        } else {
                            const item = tree[focusedItem].data;
                            if (isMetric(item) && !item.all_users && item.domain_id != null)
                                addNewMetric(item.domain_id, editor);
                            else if (isDomain(item) && !item.all_users)
                                addNewMetric(item.id, editor);
                            else
                                addNewMetric(0, editor);
                        }
                        e.stopPropagation();
                    }}
                >
                    <NewMetricIcon className="h-[17px] w-[16px]"/>
                </div>
                <div className='bg-[#FBFAFE] w-fit h-fit px-[5px] py-[2px] border-1 border-[#CACCD2] rounded-[8px] cursor-pointer '
                    data-tooltip-id={SimpleToolTipId}
                    data-tooltip-content={"create new folder"}
                    onClick={(e) => {
                        if (!checkEditPrivilege('MetricCentral', library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        if (!(focusedItem in tree)) {
                            createDomain("untitled folder", 'Y', 'Y', 1, 0).then((domain) => {
                                setFocusedItem(domainToTreeID(domain.id));
                            });
                        } else {
                            const item = tree[focusedItem].data;
                            if (isMetric(item) && !item.all_users && item.domain_id != null) {
                                createDomain("untitled folder", 'Y', 'Y', 1, item.domain_id).then((domain) => {
                                    setFocusedItem(domainToTreeID(domain.id));
                                });
                            } else if (isDomain(item) && !item.all_users) {
                                createDomain("untitled folder", 'Y', 'Y', 1, item.id).then((domain) => {
                                    setFocusedItem(domainToTreeID(domain.id));
                                });
                            }
                            else
                                createDomain("untitled folder", 'Y', 'Y', 1, 0).then((domain) => {
                                    setFocusedItem(domainToTreeID(domain.id));
                                });
                        }
                        e.stopPropagation();
                    }}
                >
                    <NewFolderIcon/>
                </div>
                <div className="">
                    { privateMetricExpanded ? <BigChevronUp/> : <BigChevronDown/> }
                </div>
            </div>
            <div className={`transition-all  duration-300 overflow-hidden`}
                style={{
                    minHeight: privateMetricExpanded ? `${calculateHeight("private_root") - 29}px` : "0px",
                    maxHeight: privateMetricExpanded ? `${calculateHeight("private_root") - 29}px` : "0px",
                }}
            >
                <ul className="h-fit w-full gap-[5px] flex flex-col select-none "
                    ref={privateMetricRef}
                >
                    {
                        tree["private_root"].children.map((tree_id) => {
                            const item = tree[tree_id].data;
                            if (isDomain(item)) {
                                return <li key={`Domain_${item.id}`}>
                                    <DomainContainer 
                                        domain={item} 
                                        depth={0}
                                        draggedItem={draggedItem}
                                        draggedDomain={draggedDomain}
                                        focusedMetric={focusedMetric}
                                        focusedDomain={focusedDomain}
                                        metricFilter={metricFilter}
                                        setDraggedItem={setDraggedItem} 
                                        setDraggedDomain={setDraggedDomain}
                                        setDraggedPosition={setDraggedPosition}
                                        onMetricClick={onMetricClick}
                                    />
                                </li>
                            }
                            if (isMetric(item)) {
                                return <li key={`Metric_${item.metric_id}`} className="pl-[10px]" id={`metric_left_metric_${item.metric_id}`}>
                                    <MetricContainer metric={item} focusedMetric={focusedMetric} focusedDomain={focusedDomain} metricFilter={metricFilter} setDraggedItem={setDraggedItem} setDraggedPosition={setDraggedPosition} onMetricClick={onMetricClick}/>
                                </li>
                            }
                        })
                    }
                </ul>
            </div>

            <div className={`h-[50px] w-full flex flex-row gap-[5px] items-center select-none ${privateMetricExpanded && "border-t-1"} ${!conceptExpanded && "border-b-1"} border-[#F3F4F6]`}
                onClick={() => {
                    setConcpetExpanded(!conceptExpanded);
                    // setExpandedFolder("Metric_Public");
                }}
            >
                <div className='font-Inter font-bold text-black text-base text-[16px]'>
                    Concepts
                </div>
                <div className="flex-1"/>
                { conceptExpanded ? <BigChevronUp/> : <BigChevronDown/> }
            </div>
            <div className={`transition-all  duration-300 overflow-hidden`}
                style={{
                    minHeight: conceptExpanded ? `${conceptRef.current?.scrollHeight || 0}px` : "0px",
                    maxHeight: conceptExpanded ? `${conceptRef.current?.scrollHeight || 0}px` : "0px",
                }}
            >
                {
                    <div className="rounded-lg flex-grow"
                        ref={conceptRef}
                    >
                        {concepts.map((it, index) => (
                            <div className={`px-2 py-1 rounded-md hover:cursor-pointer ${(currentItem !== null && "type" in currentItem && currentItem.id === it.id) ? 'bg-[#6E43F8] text-white' : ''}`} key={index} onClick={() => {
                                setCurrentItem(it)
                            }}>
                                {it.name}
                                {/* {getStatusIcon(it.status!)} */}
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

export default VerifiedMetricContainer;
