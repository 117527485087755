import React, { useEffect, useRef } from "react";

import { ChatMessage } from "../@types/common";
import MessageContainer from "./MessageContainer";
import { Page } from "../hooks/useAuth";

interface ConversationContainerProps {
  messages: ChatMessage[];
  shared?: boolean;
  page: Page;
}

const ConversationContainer: React.FC<ConversationContainerProps> = ({
  messages,
  shared=false,
  page,
}) => {
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      setTimeout(
        () =>
          lastMessageRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
          }),
        0
      );
    }
  }, [messages]);

  return (
    <>
      <div
        ref={lastMessageRef}
        className="flex flex-col items-center"
      >
        {messages.map((message) => (
          <MessageContainer message={message} key={message.id} shared={shared} page={page}/>
        ))}
        {/* <div className="w-full h-24 md:h-32 flex-shrink-0"></div> */}
      </div>
    </>
  );
};

export default ConversationContainer;
