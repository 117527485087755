import React, { useState } from 'react';
import AttributeBox from './AttributeBox';
import { Attribute, Item } from '../@types/common';

interface Props {
    items: Attribute[];
    item: Item;
    setItem: (item: Item) => void;
    disabled?: boolean;
}

const AttributeList: React.FC<Props> = ({ items, item, setItem, disabled = false }) => {
    const [expandedBoxId, setExpandedBoxId] = useState<number | null>(items[0]?.id);

    return (
        <div>
            {items.map(attribute => (
                <AttributeBox
                    key={attribute.id}
                    data={attribute}
                    type="Attribute"
                    item={item}
                    setItem={setItem}
                    isExpanded={attribute.id === expandedBoxId}
                    onExpand={() => setExpandedBoxId(attribute.id)}
                    onClose={() => setExpandedBoxId(null)}
                    disabled={disabled}
                />
            ))}
        </div>
    );
}

export default AttributeList;
