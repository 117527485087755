import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useConcept } from "../../hooks/useConcepts";
import { Attribute, Concept } from "../../@types/common";
import SearchBar, { applyFilterValue, applyFilterValueRaw } from "../SearchBar";
import { AttributeEntry, ConceptEntry, ValueEntryNew } from "../Entry/ConceptEntry";
import * as api from "../../api";
import { groupByLetter } from "./ChatBottomPanel";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { $createConceptNode, $isConceptNode, attributeMatch, conceptMatch, parseConceptNode, valuesMatch } from "../../lexical/ConceptNode";
import { Cohort } from "../../hooks/useCohorts";
import { $createCohortNode } from "../../lexical/CohortNode";
import { ReactComponent as ResetIcon } from "../../svg/metric_bottom/reset_icon.svg";
import GroupButtons from "./GroupButtons";

interface ConceptBottomPanelProps {
    attributes: Attribute[];
    setAttributes : Dispatch<SetStateAction<Attribute[]>>;
    values: string[];
    setValues: Dispatch<SetStateAction<string[]>>;
    selectedConcept: Concept | null;
    setSelectedConcept: Dispatch<SetStateAction<Concept | null>>;
    selectedAttribute: Attribute | null;
    setSelectedAttribute: Dispatch<SetStateAction<Attribute | null>>;
    selectedValues: string[];
    setSelectedValues: Dispatch<SetStateAction<string[]>>;
    conceptLevel: number;
    setConceptLevel: Dispatch<SetStateAction<number>>;
}

const ConceptBottomPanel : React.FC<ConceptBottomPanelProps> = ({
    attributes, setAttributes, values, setValues, selectedConcept, setSelectedConcept,
    selectedAttribute, setSelectedAttribute, selectedValues, setSelectedValues, conceptLevel, setConceptLevel
}) => {
    const {concepts} = useConcept();

    const [filterValue, setFilterValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    const [filterValueAttr, setFilterValueAttr] = useState("");
    const [isSearchingAttr, setIsSearchingAttr] = useState(false);

    const [filterValueVal, setFilterValueVal] = useState("");
    const [isSearchingVal, setIsSearchingVal] = useState(false);

    const groupedConcepts  = groupByLetter(concepts.filter(applyFilterValue(filterValue)));
    const groupedConceptsNum = concepts.filter(applyFilterValue(filterValue)).length;

    const groupedAttributes = groupByLetter(attributes.filter(applyFilterValue(filterValueAttr)));
    const groupedAttributesNum = attributes.filter(applyFilterValue(filterValueAttr)).length;

    const filteredValues = values.filter((value) => applyFilterValueRaw(filterValueVal)(value) || selectedValues.find((val) => val === value) !== undefined).sort((a, b) => a.localeCompare(b))

    const [editor] = useLexicalComposerContext();

    const valuesRef = useRef<HTMLDivElement>(null);
    const [valuesWidth, setValuesWidth] = useState(200);
    const [showMore, setShowMore] = useState(false);
    const scrollHeight = 30 * selectedValues.length - 8;

    useEffect(() => {
        if (showMore) {
            setValuesWidth(200);
        } else {
            if (valuesRef.current && scrollHeight > valuesRef.current.getBoundingClientRect().height) {
                setValuesWidth(100);
            } else {
                setValuesWidth(200);
            }
        }
    }, [selectedValues, valuesRef, showMore, scrollHeight]);

    const replaceCurrentConceptWithCohort = (concept: Concept, attribute: Attribute | null, values: string[], cohort: Cohort) => {
        editor.update(() => {
            const selection = $getSelection();
            const nodes = selection?.getNodes();
            if (nodes !== undefined && nodes.length > 0 && $isConceptNode(nodes[0])) {
                const mouseConceptProps = parseConceptNode(nodes[0]);
                console.log(mouseConceptProps);
                if (mouseConceptProps !== undefined && mouseConceptProps.conceptLevel === 2) {
                    // could replace
                    if (conceptMatch(mouseConceptProps, concept) && attribute !== null && attributeMatch(mouseConceptProps, attribute) && valuesMatch(mouseConceptProps, values)) {
                        const cohortNode = $createCohortNode(cohort, concept.name, attribute?.name || "", cohort.attribute_values, false);
                        nodes[0].remove();
                        selection?.insertNodes([cohortNode]);
                    }
                }
            }
        })
    }

    // actively insert selected (concept / attribute / values)
    const spawnConceptNode = (concept: Concept, attribute: Attribute | null, values: string[], conceptLevel: number, isRemoving: boolean) => {
        editor.update(() => {
            const selection = $getSelection();
            const nodes = selection?.getNodes();
            const conceptNode = $createConceptNode(concept, attribute, values, conceptLevel);
            if (nodes !== undefined && nodes.length > 0 && $isConceptNode(nodes[0])) {
                const mouseConceptProps = parseConceptNode(nodes[0]);
                if (mouseConceptProps !== undefined) {
                    // current concept under mouse is well defined
                    if (conceptLevel === 0) {
                        if (conceptMatch(mouseConceptProps, concept) && isRemoving) {
                            nodes[0].remove();
                        } else {
                            selection?.insertNodes([conceptNode]);
                        }
                    } else if (conceptLevel === 1) {
                        if (mouseConceptProps.conceptLevel === 0) {
                                if (conceptMatch(mouseConceptProps, concept)) {
                                // update current node
                                nodes[0].remove();
                                selection?.insertNodes([conceptNode]);
                            } else {
                                selection?.insertNodes([conceptNode]);
                            }
                        } else {
                            if (conceptMatch(mouseConceptProps, concept) && attribute !== null && attributeMatch(mouseConceptProps, attribute) && isRemoving) {
                                // roll back to level 0
                                nodes[0].remove();
                                selection?.insertNodes([$createConceptNode(concept, null, [], 0)]);
                            } else {
                                selection?.insertNodes([conceptNode]);
                            }
                        }
                    } else {
                        // conceptLevel === 2
                        if (conceptMatch(mouseConceptProps, concept) && attribute !== null && attributeMatch(mouseConceptProps, attribute)) {
                            // update current
                            nodes[0].remove();
                            selection?.insertNodes([conceptNode]);
                        } else {
                            selection?.insertNodes([conceptNode]);
                        }
                    }
                } else {
                    console.warn("failed to parse current mouse concept props, treat as no concept under mouse");
                    selection?.insertNodes([conceptNode]);
                }
            } else {
                // no concept under mouse
                selection?.insertNodes([conceptNode]);
            }
          });
    }

    return (
        <>
            {
                conceptLevel !== 0 && 
                <div className={`flex ${showMore ? "w-[450px]" : "w-[350px]"} flex-col gap-[12px] items-start h-full border-[#CACCD2] border-r-[1px] select-none`}>
                    {selectedConcept && 
                        <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                            <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                Concept:
                            </div>
                            <div className="absolute left-[100px] h-fit w-fit">
                                <ConceptEntry concept={selectedConcept} index={0} filter={""} isSelected={true} 
                                    onClick={() => {}}/>
                            </div>
                            {/* <div className="select-none cursor-pointer font-Inter font-[500] text-[13px] text-black underline" onClick={() => {
                                resetLocalValues();
                                spawnConceptNode(selectedConcept, null, [], 0, true);
                            }}>
                                See all
                            </div> */}
                        </div>
                    }
                    {selectedAttribute && 
                        <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                            <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                Attribute:
                            </div>
                            <div className="absolute left-[100px] h-fit w-fit">
                                <AttributeEntry attribute={selectedAttribute} concept={selectedConcept} index={0} isSelected={true} filter={""} onClick={() => {}}/>
                            </div>
                            {/* <div className="select-none cursor-pointer font-Inter font-[500] text-[13px] text-black underline" onClick={() => {
                                setSelectedAttribute(null);
                                setValues([]);
                                setSelectedValues([]);
                                setConceptLevel(1);
                                if (selectedConcept)
                                    spawnConceptNode(selectedConcept, selectedAttribute, [], 1, true);
                            }}>
                                See all
                            </div> */}
                        </div>
                    }
                    {selectedValues.length > 0 &&
                        <>
                            <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                    Values:
                                </div>
                                {
                                    (showMore || valuesWidth < 200) && 
                                    <div className="select-none cursor-pointer font-Inter font-[500] text-[13px] text-black underline" onClick={() => {
                                        setShowMore(!showMore);
                                    }}>
                                        {`Show ${showMore ? "less" : "more"}`}
                                    </div>
                                }
                            </div>
                            <div className={`scrollbar flex flex-row flex-wrap h-full w-full overflow-y-auto items-start content-start gap-[8px]`}
                                ref={valuesRef}
                            >
                                {selectedValues.map((value, index) => 
                                <div key={value}>
                                    <ValueEntryNew concept={selectedConcept} attribute={selectedAttribute} values={selectedValues} setValues={setSelectedValues} value={value} isSelected={true} index={index} filter={""} onClick={() => {
                                        let newVals = [];
                                        if (selectedValues.find((val) => val === value) !== undefined) {
                                            newVals = (selectedValues.filter((val) => val !== value));
                                        } else {
                                            newVals = ([...selectedValues, value]);
                                        }
                                        if (selectedConcept)
                                            spawnConceptNode(selectedConcept, selectedAttribute, [...newVals], 2, false);
                                        setSelectedValues(newVals);
                                    }} length={valuesWidth}/>
                                </div>
                                )}
                            </div>
                            <GroupButtons selectedAttribute={selectedAttribute} selectedConcept={selectedConcept} selectedValues={selectedValues} replaceCurrentConceptWithCohort={replaceCurrentConceptWithCohort}/>
                        </>
                    }
                </div>
            }
            {
                conceptLevel === 0 && 
                <div className="flex flex-1 flex-col items-start h-full select-none">
                    <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                        <div className="font-Inter font-[700] text-[16px]">
                            Concepts
                        </div>
                        {
                            groupedConceptsNum > 0 &&
                            <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                ({groupedConceptsNum})
                            </div>
                        }
                        <SearchBar isSearching={isSearching} setIsSearching={setIsSearching} filterValue={filterValue} setFilterValue={setFilterValue} />
                    </div>
                    <div className="w-full h-full pt-[10px] gap-[8px] flex flex-col overflow-y-auto items-start justify-start">
                        {
                            Object.keys(groupedConcepts).map((key, index) => {
                                return (
                                    <div className="w-full h-fit gap-[8px] flex flex-row items-start justify-start" key={`_groupByLetterParent_${key}`}>
                                        <span className="font-Inter font-[700] w-[16px] h-[20px] text-[17px] text-black opacity-[0.3] align-middle" key={`_groupByLetterParent_KeyName_${key}`}>
                                            {key}
                                        </span>
                                        <div className="w-full h-fit pt-10px gap-[8px] flex flex-row flex-wrap">
                                            {groupedConcepts[key].map((item, index) => {
                                                return (
                                                <div key={item.id}>
                                                <ConceptEntry concept={item} index={index} filter={filterValue} isSelected={selectedConcept !== null && selectedConcept.id === item.id} 
                                                    onClick={() => {
                                                        setSelectedAttribute(null);
                                                        setValues([]);
                                                        setSelectedValues([]);
                                                        setFilterValueAttr("");
                                                        setFilterValueVal("");
                                                        setIsSearchingAttr(false);
                                                        setIsSearchingVal(false);
                                                        if (item.id !== selectedConcept?.id) {
                                                            setSelectedConcept(item);
                                                            spawnConceptNode(item, null, [], 0, false);
                                                            setAttributes(item.attributes || [])
                                                            setConceptLevel(1);
                                                        } else {
                                                            setSelectedConcept(null);
                                                            spawnConceptNode(item, null, [], 0, true);
                                                            setAttributes([]);
                                                            setConceptLevel(0);
                                                        }
                                                    }}/>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
            {
                conceptLevel === 1 &&
                <div className="flex flex-1 flex-col items-start h-full select-none">
                    <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                        <div className="font-Inter font-[700] text-[16px]">
                            Attributes
                        </div>
                        {
                            groupedAttributesNum > 0 && 
                            <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                ({groupedAttributesNum})
                            </div>
                        }
                        <SearchBar isSearching={isSearchingAttr} setIsSearching={setIsSearchingAttr} filterValue={filterValueAttr} setFilterValue={setFilterValueAttr} />
                    </div>
                    
                    <div className="w-full h-full pt-[10px] gap-[8px] flex flex-col overflow-y-auto items-start justify-start">
                        {
                            Object.keys(groupedAttributes).map((key, index) => {
                                return (
                                    <div className="w-full h-fit gap-[8px] flex flex-row items-start justify-start" key={`_groupByLetterParent_${key}`}>
                                        <span className="font-Inter font-[700] w-[16px] h-[20px] text-[17px] text-black opacity-[0.3] align-middle" key={`_groupByLetterParent_KeyName_${key}`}>
                                            {key}
                                        </span>
                                        <div className="w-full h-fit pt-10px gap-[8px] flex flex-row flex-wrap">
                                            {groupedAttributes[key].map((attr, index) => {
                                                return (
                                                <div key={attr.id}>
                                                <AttributeEntry attribute={attr} concept={selectedConcept} index={index} isSelected={selectedAttribute !== null && selectedAttribute.id === attr.id} filter={filterValueAttr} onClick={() => {
                                                    setConceptLevel(1);
                                                    setValues([]);
                                                    setSelectedValues([]);
                                                    setFilterValueVal("");
                                                    setIsSearchingVal(false);
                                                    if (selectedAttribute !== attr) {
                                                        setSelectedAttribute(attr);
                                                        if (selectedConcept)
                                                            spawnConceptNode(selectedConcept, attr, [], 1, false);
                                                        api.fetchAttributeValues(attr.id).then((vals) => {
                                                            setValues(vals);
                                                            setConceptLevel(2);
                                                        });
                                                    } else {
                                                        setSelectedAttribute(null);
                                                        if (selectedConcept)
                                                            spawnConceptNode(selectedConcept, attr, [], 1, true);
                                                    }
                                                }}/>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
            {
                conceptLevel === 2 &&
                <div className="flex flex-1 flex-col items-start h-full select-none">
                    <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                        <div className="font-Inter font-[700] text-[16px]">
                            Values
                        </div>
                        {
                            filteredValues.length > 0 && 
                            <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                ({filteredValues.length})
                            </div>
                        }
                        <SearchBar isSearching={isSearchingVal} setIsSearching={setIsSearchingVal} filterValue={filterValueVal} setFilterValue={setFilterValueVal} />
                    </div>
                    
                    <div className="scrollbar w-full h-full pt-[10px] gap-[8px] flex flex-row flex-wrap overflow-y-auto items-start content-start justify-start">
                        {
                            filteredValues.filter((value) => selectedValues.every((value2) => value2 !== value)).map((value, index) => 
                                <div key={value}>
                                <ValueEntryNew concept={selectedConcept} attribute={selectedAttribute} values={selectedValues} setValues={setSelectedValues} value={value} isSelected={selectedValues.find((val) => val === value) !== undefined} index={index} filter={filterValueVal} onClick={() => {
                                    let newVals = [];
                                    if (selectedValues.find((val) => val === value) !== undefined) {
                                        newVals = (selectedValues.filter((val) => val !== value));
                                    } else {
                                        newVals = ([...selectedValues, value]);
                                    }
                                    if (selectedConcept)
                                        spawnConceptNode(selectedConcept, selectedAttribute, [...newVals], 2, false);
                                    setSelectedValues(newVals);
                                }}
                                    length={200}
                                />
                                </div>
                            )
                        }
                    </div>
                </div>
            }
            <div className="absolute bottom-[20px] right-[16px] h-fit w-fit flex flex-row bg-white rounded-[8px] border-1 border-[#BB0000] py-[6px] px-[10px] gap-[6px] select-none cursor-pointer"
                onClick={() => {
                    setSelectedConcept(null);
                    setAttributes([]);
                    setSelectedAttribute(null);
                    setValues([]);
                    setSelectedValues([]);
                    setConceptLevel(0);
                }}
            >
                <ResetIcon/>
                <div className="font-Inter font-[500] text-[13px] text-[#BB0000]">
                    Reset
                </div>
            </div>
        </>
    )
}

export default ConceptBottomPanel;