import React, { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {useToast} from "../hooks/useToast";

type LinkPanelProp = {
    sessionId: number,
    setSessionId: (n: number) => void;
}

const LinkPanel: React.FC<LinkPanelProp> = ({sessionId, setSessionId}) => {
    const panelWidth = 532;
    const left = (window.innerWidth - panelWidth) / 2
    const panelRef = React.useRef<HTMLDivElement>(null);
    const toast = useToast();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (panelRef.current && !panelRef.current.contains(event.target)) {
                setSessionId(-1);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        sessionId === -1 ? <></> :
        <div 
            style={{
                left: `${left}px`,
                width: `${panelWidth}px`,
            }}
            className={`fixed h-[49px] top-[85px] rounded-[8px] py-[7px] px-[8px] gap-[10px] flex items-center justify-center bg-[#DBF5F4] shadow-linkPanel`}
            onBlur={() => {
                console.log("blur from link");
                setSessionId(-1);
            }}
            onClick={() => {
                console.log("click on blur");
                panelRef.current?.focus();
            }}
            ref={panelRef}
        >
            <span className="font-Inter font-[500] text-[14px] text-[#008884] cursor-default">
                Link to Session:
            </span>
            <input 
                className="w-[272px] h-full rounded-[8px] border-[1px] border-[#CACCD2] py-[9px] px-[10px] gap-[7px] bg-white shadow-customSmall font-Inter font-[400] text-[13px] text-black"
                value={`https://uguru.xolved.com/shared?id=${sessionId}`}
            />
            <CopyToClipboard 
                text={`https://uguru.xolved.com/shared?id=${sessionId}`}
                onCopy={() => {
                    setSessionId(-1);
                    toast.success("Link copied to clip board");
                }}
            >
                <div className="w-fit h-fit rounded-[8px] py-[9px] px-[21px] bg-[#0FC3BD] shadow-customSmall flex items-center justify-center cursor-pointer">
                    <span className="w-fit h-fit font-Inter font-[500] text-[14px] text-white">
                        Copy
                    </span>
                </div>
            </CopyToClipboard>
        </div>
    )
};

export default LinkPanel;