import React, { useRef, useEffect, useState } from "react";
import { useChart } from "../hooks/useChart";
import ConversationContainer from "./ConversationContainer";
import {
    ChatMessage,
} from "../@types/common";
import { useSession } from "../hooks/useSession";
import mixpanel from 'mixpanel-browser';
import useQuery from "../hooks/useQuery";

const SharedPanel: React.FC = () => {
    const { charts } = useChart();
    const lastChartRef = useRef<HTMLDivElement>(null);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const messagesRef = useRef<ChatMessage[]>([]);
    const conversationContainerRef = useRef<HTMLDivElement>(null);
    const [lastScrollPercentage, setLastScrollPercentage] = useState(0);

    const { fetchHistoryBySession } = useSession();

    const query = useQuery();

    const handleScroll = () => {
        const container = conversationContainerRef.current;
        if (!container) {
            return;
        }

        const scrollPercentage = (container.scrollTop + container.clientHeight) / container.scrollHeight;

        // Trigger event when the scroll crosses the midpoint, from either direction
        if ((lastScrollPercentage <= 0.5 && scrollPercentage > 0.5) || (lastScrollPercentage > 0.5 && scrollPercentage <= 0.5)) {
            // console.log("Scroll tracked", scrollPercentage)
            mixpanel.track('Scroll Conversation Container', {
                'percentageScrolled': scrollPercentage,
            });
        }

        // Update last scroll percentage for next scroll event
        setLastScrollPercentage(scrollPercentage);
    };

    useEffect(() => {
        messagesRef.current = messages;
    }, [messages]);

    useEffect(() => {
        if (lastChartRef.current) {
            setTimeout(() => {
                lastChartRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
    }, [charts]);

    useEffect(() => {
        const session_id = query.get("id");
        if (session_id === null) {
            console.log("cannot load history without session id");
        } else {
            fetchHistoryBySession(Number(session_id)).then(data => setMessages(data));
        }
    }, []);

    return (
        <div className="h-screen w-full overflow-x-auto flex-1 flex flex-row bg-[#f5f5f5] pt-[56px] justify-between">
            <div className="w-full h-full flex flex-col justify-end mx-[20px]">
                <div className="overflow-y-auto pr-[8px] pt-[8px]" ref={conversationContainerRef} onScroll={handleScroll}>
                    <ConversationContainer messages={messages} shared={true} page="Shared"/>
                </div>
            </div>
        </div>
    );
};

export default SharedPanel;
