import React from "react";

const SharedTopBar: React.FC = () => {

    return (
        <div className="absolute h-[56px] top-0 left-0 w-full z-5 bg-white shadow-customSmall flex flex-row items-center justify-between px-[23px]">
            <div className="flex-1"></div>
            <div className="py-[16px] px-[3px] h-fit w-fit flex-1 flex items-center justify-center">
                <div className="h-fit w-fit rounded-[10px] py-[5px] px-[9px] bg-[#E5DDFD7D]">
                    <span className="font-Inter font-[700] text-[15px] text-[#6E43F8]">
                        You are viewing shared session
                    </span>
                </div>
            </div>
            <div className="text-lg font-bold flex-1 flex items-center justify-end">
                <div className="h-fit w-fit rounded-[8px] py-[5px] px-[9px] bg-[#F3F4F6]">
                    <span className="font-Inter font-[700] text-[15px] text-[#6B7280]">
                        ABC Healthcare
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SharedTopBar;
