import { ReactComponent as FavButtonNo } from "../svg/favorite_button/favorite_button_no_passive.svg"
import { ReactComponent as FavButtonNoOver } from "../svg/favorite_button/favorite_button_no_over.svg"
import { ReactComponent as FavButtonNoDown } from "../svg/favorite_button/favorite_button_no_down.svg"
import { ReactComponent as FavButtonYes } from "../svg/favorite_button/favorite_button_yes_passive.svg"
import { ReactComponent as FavButtonYesOver } from "../svg/favorite_button/favorite_button_yes_over.svg"
import { ReactComponent as FavButtonYesDown } from "../svg/favorite_button/favorite_button_yes_down.svg"
import React from "react"
import { SimpleToolTipId } from "./ToolTip/SimpleToolTip"


interface FavoriteButtonProps {
    isFav: () => boolean;
    onClick: () => void;
    className: string;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({isFav, onClick, className}) => {
    const [mouseOver, setMouseOver] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);
    return (
        <div 
            className={className + " outline-none "}
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => {setMouseOver(false); setMouseDown(false);}}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => {if (mouseDown) onClick(); setMouseDown(false); setMouseOver(false)}}
            onClick={() => onClick()}
        >
            {mouseDown ? 
                isFav() ? <FavButtonYesDown/> : <FavButtonNoDown/>
              : mouseOver ?
                    isFav() ? <FavButtonYesOver/> : <FavButtonNoOver/>
              : isFav() ? <FavButtonYes/> : <FavButtonNo/>
            }
        </div>
    )
}

export default FavoriteButton;