import React, { useEffect, useRef, useState } from "react";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import MetricsAutoCompletePlugin from "./MetricsAutoCompletePlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import mixpanel from 'mixpanel-browser';
import { useMetrics } from "../hooks/useMetrics";
import { useChatbox } from "../hooks/useChatbox";
import { $getRoot, $createTextNode, $createParagraphNode, TextNode, LexicalNode, EditorState, LexicalEditor, $getSelection, SELECTION_CHANGE_COMMAND, DELETE_CHARACTER_COMMAND, DELETE_LINE_COMMAND, DELETE_WORD_COMMAND, $getNodeByKey, LexicalCommand, createCommand, $isRangeSelection, KEY_ARROW_LEFT_COMMAND, ElementNode } from "lexical";
import { ReactComponent as SendChatIcon } from "../svg/send_chat_icon.svg";
import { ReactComponent as  PlusIcon} from "../svg/chat_box/plus_icon.svg"
import { ReactComponent as  PlusIconActive} from "../svg/chat_box/plus_icon_active.svg"
import { parseMessageContent } from "./ToolTip/ToolTipHelpers"

import { $createMetricNode, $isMetricNode } from "../lexical/MetricNode";
import { $createConceptNode, $isConceptNode, ConceptNode, attributeMatch, conceptMatch, parseConceptNode } from "../lexical/ConceptNode";
import { parseConcept } from "./ToolTip/ConceptToolTip";
import { useConcept } from "../hooks/useConcepts";
import { useCohort } from "../hooks/useCohorts";
import { $createCohortNode, $isCohortNode } from "../lexical/CohortNode";
import CohortsAutoCompletePlugin from "./CohortsAutoCompletePlugin";
import ChatBottomPanel from "./BottomPanel/ChatBottomPanel";
import { useLibrary } from "../hooks/useLibrary";
import { useAuth } from "../hooks/useAuth";
import { useToast } from "../hooks/useToast";

interface ChatBoxProps {
  onSubmit: (message: string) => void;
  message: string;
  setMessage: (message: string) => void;
  panelName: string;
  setPanelName: (s: string) => void;
}

interface ButtonProps {
  isActive: () => boolean;
  onClick: () => void;
  title: string;
}
const PanelButton: React.FC<ButtonProps> = ({isActive, onClick, title}) => {
  const [mouseOver, setMouseOver] = useState(false);
  return (
    <div 
      className={`w-fit h-fit rounded-[8px] cursor-pointer border-1 ${(isActive() || mouseOver) ? "border-[#E5DDFD]" : "border-[#CACCD2]"} py-[6px] px-[15px] gap-[6px] ${(isActive() || mouseOver) ? "bg-[#E5DDFD]" : "bg-white"} flex flex-row items-center select-none`}
      onClick={onClick}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    >
      {(isActive() || mouseOver) ? <PlusIconActive/> : <PlusIcon/>}
      <div className={`font-Inter font-[500] text-[13px] ${(isActive() || mouseOver) ? "text-[#6E43F8]" : "text-[#010616]"}`}>
        {title}
      </div>
    </div>
  );
}

const ChatBox: React.FC<ChatBoxProps> = ({ onSubmit, message, setMessage, panelName, setPanelName }) => {
  const [editor] = useLexicalComposerContext();
  const { metrics } = useMetrics();
  const { concepts } = useConcept();
  const { inputText, setInput, inputRef } = useChatbox();
  const formRef = useRef<HTMLFormElement>(null);
  const {cohorts} = useCohort();
  const { library } = useLibrary();
  const { checkEditPrivilege } = useAuth();
  const toast = useToast();

  const handleSubmit = (event: React.FormEvent) => {
    if (!checkEditPrivilege('Q&A', library?.id || 0)) {
      toast.warning("You do not have edit access to this page");
      return;
    }
    setPanelName("none");
    event.preventDefault();
    const message = editor.getRootElement()?.textContent || "";
    if (message.trim() === "") return;
    onSubmit(message);
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      const paragraph = $createParagraphNode();
      root.append(paragraph);
      paragraph.select();
    });
    mixpanel.track('Submit new query', {
      'message': message,
    });
    setInput("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event as any);
    }
  };

  const formatMessage = (inputText: string) => {
    const nodeList: LexicalNode[] = [];
    parseMessageContent(inputText, cohorts).map((chunk, index) => {
      // console.log(chunk);
      if (chunk.type === "text") {
        const textNode = $createTextNode(chunk.content);
        nodeList.push(textNode);
      } else if (chunk.type === "link") {
        const metric = metrics.find((item) => { return item.metric_name === chunk.content });
        if (metric) {
          const metricNode = $createMetricNode(metric.metric_name, metric.metric_status!, "Metric", metric.metric_desc!, metric.metric_logic!)
          nodeList.push(metricNode)
        } else {
          const textNode = $createTextNode(`[${chunk.content}]`);
          nodeList.push(textNode);
        }
      } else if (chunk.type === "link_concept") {
        const parsedConcept = parseConcept(chunk.content);
        const concept = concepts.find((item) => { return item.name === parsedConcept.conceptName });
        if (concept) {
          const attribute = concept.attributes.find(a => a.name === parsedConcept.attribute);
          const conceptNode = $createConceptNode(concept, attribute || null, parsedConcept.values, parsedConcept.conceptLevel);
          nodeList.push(conceptNode);
        } else {
          const textNode = $createTextNode(`{${chunk.content}}`);
          nodeList.push(textNode);
        }
      } else if (chunk.type === "link_cohort") {
        const cohort = cohorts.find(cohort => cohort.name === chunk.content);
        if (cohort) {
          const concept = concepts.find(concept => concept.attributes.find(attribute => attribute.id === cohort.attribute_id) !== undefined);
          if (concept) {
            const attribute = concept.attributes.find(attribute => attribute.id === cohort.attribute_id);
            if (attribute) {
              const cohortNode = $createCohortNode(cohort, concept.name, attribute.name, cohort.attribute_values, false);
                nodeList.push(cohortNode);
            }
          }
        }
      } else {
        console.error("unknown chunk type from parse message content");
      }
    })
    // combine adjacent text nodes
    const reducedList = nodeList.reduce<LexicalNode[]>((prevNodes, currNode) => {
      if (prevNodes.length === 0) {
        return [currNode];
      }
      const lastNode = prevNodes[prevNodes.length - 1]
      if (lastNode.getType() === "text" && currNode.getType() === "text") {
        prevNodes[prevNodes.length - 1] = $createTextNode(lastNode.getTextContent() + currNode.getTextContent());
      } else {
        prevNodes.push(currNode);
      }
      return prevNodes;
    }, []);
    return reducedList;
  }

  const onChange = (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => {
    const iterateNodes = ((node: ElementNode) => {
      if (node.getType() === "text") {
        const textContent = node.getTextContent();
        const newNodes = formatMessage(textContent);
        if (newNodes.length > 0) {
          for (let i = 0; i < newNodes.length - 1; i += 1) {
            node.insertBefore(newNodes[i], false);
          }
          node.replace(newNodes[newNodes.length - 1]);
        }
      }
      // TODO: node.getchildren is not a function
      const children: ElementNode[] = node.getChildren() as ElementNode[];
      for (const child of children) {
        iterateNodes(child);
      }
    })

    if (!tags.has("no_update")) {
      editor.update(() => {
        const root = $getRoot();
        iterateNodes(root);
      }, { tag: "no_update" });
    }
  };

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      if (inputText) {
        const paragraph = $createParagraphNode();
        const newNodes = formatMessage(inputText);
        paragraph.append(...newNodes);
        root.append(paragraph);
        newNodes[newNodes.length - 1].selectEnd();
      }
    }, { tag: "no_update" });
  }, [inputText]);

  useEffect(() => {
    return editor.registerCommand(KEY_ARROW_LEFT_COMMAND, () => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes();
        if (nodes !== undefined && nodes.length === 1 && ($isConceptNode(nodes[0]) || $isMetricNode(nodes[0]) || $isCohortNode(nodes[0]))) {
          nodes[0].select(0, 0);
        }
      }
      return false;
    }, 4);
  })

  useEffect(() => {
    return editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes();
        if (nodes !== undefined && nodes.length === 1 && ($isConceptNode(nodes[0]) || $isMetricNode(nodes[0]) || $isCohortNode(nodes[0])) && selection.anchor.offset !== 0) {
          nodes[0].select();
        }
      }
      return false;
    }, 4);
  }, []);

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      const paragraph = $createParagraphNode();
      root.append(paragraph);
      paragraph.select();
    });

    const handleMessageRetry = (e : Event) => {
      const message = (e as CustomEvent).detail;
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        const paragraph = $createParagraphNode();
        const newNodes = formatMessage(message);
        paragraph.append(...newNodes);
        root.append(paragraph);
        newNodes[newNodes.length - 1].selectEnd();
      })
    };
    document.addEventListener('messageRetry', handleMessageRetry);

    return (() => {
      document.removeEventListener('messageRetry', handleMessageRetry);
    })
  }, [])

  return (
    <form 
      onSubmit={handleSubmit} 
      onKeyDown={handleKeyDown} 
      className="w-full h-fit py-[17px] px-[20px] flex flex-row justify-between gap-[10px] items-center rounded-t-[8px] bg-[#ffffff]/[0.7] shadow-card relative"
      ref={formRef}
    >
      {/* <ChatBottomPanel parentRef={formRef} panelName={panelName} setPanelName={setPanelName}/> */}
      {panelName !== "none" && <ChatBottomPanel parentRef={formRef} panelName={panelName} setPanelName={setPanelName}/>}
      <div className="relative w-full flex-1 overflow-auto bg-white flex flex-col gap-[8px]">
        <div className="relative w-full flex-1 overflow-auto bg-white flex flex-row items-center justify-center gap-[8px]">
          <RichTextPlugin
            contentEditable={
              <ContentEditable className={`text-left text-black w-full border border-black/10 rounded-[8px] shadow-customSmall relative p-2`}/>
            }
            placeholder={<span className="absolute top-[11px] left-2 text-gray-500 pointer-events-none" style={{ zIndex: 2 }}>Ask a question...</span>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={onChange} />
          <MetricsAutoCompletePlugin _metrics={metrics} _concepts={concepts} />
          <CohortsAutoCompletePlugin cohorts={cohorts}/>
          <AutoFocusPlugin />
          <div className="w-fit h-fit">
            <SendChatIcon className="cursor-pointer self-end" onClick={(e) => {
              handleSubmit(e);
            }} onMouseEnter={() => { }} onMouseLeave={() => { }} />
          </div>
        </div>
        <div className="flex flex-row h-fit w-fit gap-[8px]">
          <PanelButton isActive={() => panelName === "combined"} title={"Builder"} onClick={() => {
            if (panelName === "combined") {
              setPanelName("none");
            } else {
              setPanelName("combined");
            }
          }}/>
        </div>
      </div>
    </form>
  );
};

export default ChatBox;