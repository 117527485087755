import React, { useEffect, useRef, useState } from "react";
import "./StaticSidePanel.css";
import { ReactComponent as ChevronLeft } from "../../svg/session_panel/chevron_left.svg";
import { ReactComponent as ChevronRight } from "../../svg/session_panel/chevron_right.svg";
import { SimpleToolTipId } from "../ToolTip/SimpleToolTip";
import { ReactComponent as ChevronUp } from "../../svg/session_panel/chevron_up.svg";
import { ReactComponent as ChevronDown } from "../../svg/session_panel/chevron_down.svg";
import { ReactComponent as PlusIconLarge } from "../../svg/session_panel/plus_icon_large.svg";
import { useSession } from "../../hooks/useSession";
import { useHistory } from "../../hooks/useHistory";
import HistoryEntryDiv from "../Entry/HistoryEntry";
import { useChatbox } from "../../hooks/useChatbox";
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';
import { useAuth } from "../../hooks/useAuth";
import LinkPanel from "../LinkPanel";
import SessionEntryDiv from "../Entry/SessionEntry";
import { usePin } from "../../hooks/usePin";
import FavoriteEntryDiv from "../Entry/FavoriteEntry";
import { useMessage } from "../../hooks/useMessage";
import { useLibrary } from "../../hooks/useLibrary";
import { useToast } from "../../hooks/useToast";

export interface StaticSidePanelProps {
    panelName: string;
    setPanelName: (t: string) => void;
}

/**
 * This panel is built into a single file in order to carefully define transition animations
 * Before you change styles here, make sure you understand what you are doing since transition animations
 * can break very easily
 */
const MyXolvedStaticSidePanel: React.FC<StaticSidePanelProps> = ({panelName, setPanelName}) => {
    const sidePanelRef = useRef<HTMLDivElement>(null);
    const sidePanelRect = sidePanelRef.current?.getBoundingClientRect();
    const [lastPanel, setLastPanel] = useState(panelName !== "none" ? panelName : "favorite");
    const [containerName, setContainerName] = useState("history");

    const { sortedSessions, addSession, currentSessionId, fetchHistoryBySession } = useSession();
    const [sharedSessionId, setSharedSessionId] = React.useState(-1);
    const { pin, addPin, removePinId } = usePin();
    const { history, addFavorite, removeFavoriteId, favorite } = useHistory();
    const { setMessages } = useMessage();
    const { setInput, inputRef } = useChatbox();
    const { userProfile, checkEditPrivilege } = useAuth();
    const { library } = useLibrary();
    const toast = useToast();

    const lastHistoryRef = useRef<HTMLDivElement>(null);
    const lastFavoriteRef = useRef<HTMLDivElement>(null);

    // auto scroll history when history is updated
    useEffect(() => {
        if (lastHistoryRef.current) {
            setTimeout(() => {
                lastHistoryRef.current?.scrollIntoView({});
            }, 0); // Adjust the timeout value as needed.
        }
    }, [history, containerName, panelName]);

    // auto scroll favorite when favorite is updated
    useEffect(() => {
        if (lastFavoriteRef.current) {
            setTimeout(() => {
                lastFavoriteRef.current?.scrollIntoView({});
            }, 0); // Adjust the timeout value as needed.
        }
    }, [favorite]);

    useEffect(() => {
        if (panelName !== "none") {
            setLastPanel(panelName);
        }
    }, [panelName]);


    useEffect(() => {
        setMessages([]);
        fetchHistoryBySession(currentSessionId).then(data => {
            setMessages(data);
        });
    }, [currentSessionId]);

    return (
        <div 
            // use this div to group close button and main panel together
            className="relative overflow-x-visible z-[5]"
            ref={sidePanelRef}
        >
            <LinkPanel sessionId={sharedSessionId} setSessionId={setSharedSessionId}/>
            <div className={`scrollbar h-full bg-white overflow-hidden
                    ${panelName === "none" ? "w-[0px]" : "w-[301px]"} 
                    transition-all ease-in-out duration-300 
                    border-r-[#F3F4F6] border-r-[1px]`
                }
            >
                {lastPanel === "favorite" && 
                <div className="h-full overflow-hidden w-[300px] flex flex-col">
                    {/* <div className="flex flex-row w-full h-fit px-[18px] pt-[13px] pb-[10px] items-center justify-start bg-white shadow-customSmall sticky top-0">
                        <div className="h-fit w-fit font-Inter text-[16px] font-[700] text-[#010616] select-none">
                            Favorite Questions
                        </div>
                    </div> */}
                    <ul className="scrollbar overflow-y-auto flex flex-col text-md w-full h-fit py-[4px]"
                        style={{
                            scrollbarGutter: "stable"
                        }}
                    >
                        {favorite.map((entry, index) => {
                            return (
                                <li key={entry.historyid}>
                                    <FavoriteEntryDiv 
                                        favoriteEntry={entry}
                                        favoriteIndex={index}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setInput(entry.message);
                                            inputRef.current?.focus();
                                        }}
                                        onFavorite={() => {
                                            if (entry.favorite === "Y") {
                                                removeFavoriteId(entry?.historyid || "");
                                            } else {
                                                addFavorite({
                                                    id: entry.id,
                                                    message: entry.message,
                                                    favorite: "Y",
                                                    historyid: entry.historyid,
                                                });
                                            }}}
                                            isPinned={() => {
                                            const index = pin.findIndex(
                                                (pin_entry) => {return pin_entry.query_text === entry.message;}
                                            );
                                            return index >= 0;
                                        }}
                                        onPin={() => {
                                            const pin_entry = pin.find((pin_entry) => pin_entry.query_text === entry.message);
                                            if (pin_entry === undefined) {
                                                addPin({
                                                id: "",
                                                name: "",
                                                query_text: entry.message,
                                                create_date: "",
                                                });
                                            } else {
                                                removePinId(pin_entry.id);
                                            }
                                        }}
                                    />
                                </li>
                            )
                        })}
                        <div ref={lastFavoriteRef} />
                    </ul>
                </div>}
            </div>
            <div className={`absolute outline-none rounded-r-[12.5px] right-[-24px] h-[64px] w-[25px] bg-white flex items-center justify-center cursor-pointer`}
                onClick={() => {
                    if (panelName === "none") {
                        setPanelName(lastPanel);
                    } else {
                        setPanelName("none");
                    }
                }}
                style={{
                    top: `${sidePanelRect !== undefined ? sidePanelRect.height / 2 - 32 : window.innerHeight / 2}px`,
                }}
                data-tooltip-id={SimpleToolTipId}
                data-tooltip-content={panelName === "none" ? "open side panel" : "close side panel"}
                data-tooltip-place="right"
            >
                {panelName === "none" ? <ChevronRight/> : <ChevronLeft/> }
            </div>
        </div>
    );
};

export default MyXolvedStaticSidePanel;
