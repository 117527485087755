import React from "react";
import { useLibrary } from "../../hooks/useLibrary";
import LibraryDropdown from "../LibraryDropdown";

import { ReactComponent as FavoriteIcon } from "../../svg/MyXolved_topbar/favorite.svg";
import { SimpleToolTipId } from "../ToolTip/SimpleToolTip";

interface QATopBarProps {
    title: string;
    panelName: string;
    setPanelName: (t: string) => void;
}

interface TopButtonProps {
    title: string;
    panelName: string;
    setPanelName: (t: string) => void;
}

const TopButton: React.FC<TopButtonProps> = ({title, panelName, setPanelName}) => {
    const [mouseHover, setMouseHover] = React.useState(false);
    return (
        <div 
            className={`flex-1 h-full flex items-center justify-center outline-none cursor-pointer gap-[10px]
                ${title === panelName ? "bg-white border-t-[2px] border-[#6E43F8]" : mouseHover ? "bg-white" : "bg-[#F6F3FF]"}
            `}
            onClick={() => {
                if (panelName === title) {
                    setPanelName("none");
                } else {
                    setPanelName(title);
                }
            }}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            data-tooltip-id={SimpleToolTipId} 
            data-tooltip-content={title}
        >
            {title === "favorite" && <>
                <FavoriteIcon/>
                <span className="font-Inter font-[700] text-[17px]">Favourite Queries</span>
            </> }
            {title === "new" && <span className="font-Inter font-[700] text-[17px]">New Queries</span>}
        </div>
    );
}

const MyXolvedTopBar: React.FC<QATopBarProps> = ({ title, panelName, setPanelName }) => {
    const { library, libraries, setLibrary } = useLibrary();

    const handleLibrarySelection = (selectedOption: { value: number, label: string } | null) => {
        if (selectedOption) {
          const selectedLibrary = libraries?.find(lib => lib.id === selectedOption.value);
          setLibrary(selectedLibrary || null);
        }
    };

    return (
        <div className="absolute h-[56px] w-full z-[5] top-0 left-0 bg-white shadow-topbar flex flex-row items-center justify-between px-8 pl-[56px]">
            <div className="flex flex-row w-[300px] h-full">
                <TopButton title="favorite" panelName={panelName} setPanelName={setPanelName}/>
                {/* <TopButton title="new" panelName={panelName} setPanelName={setPanelName}/> */}
            </div>
            <div className="flex-1 flex flex-row gap-[10px] justify-center items-center">
                <div className="w-[300px] bg-[#F3F4F6]">
                    <LibraryDropdown
                        libraries={libraries || []}
                        selectedLibrary={library}
                        onLibrarySelect={handleLibrarySelection}
                        customStyles={{
                            control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: "none",
                                // background: '#F3F4F6',
                            }),
                            // dropdownIndicator: (base) => ({
                            //     ...base,
                            //     color: '#6E43F8',
                            // }),
                            singleValue: (base) => ({
                                ...base,
                                fontWeight: 'bold',
                                // color: '#6E43F8',
                            }),
                            // option: (base) => ({
                            //     ...base,
                            //     color: '#6E43F8',
                            // })
                        }}
                        customComponents={{
                            IndicatorSeparator: () => null
                        }}
                    />
                </div>
            </div>
            <span className="text-[14px] font-Inter font-[700] text-[#6B7280]">ABC Healthcare</span>
        </div>
    );
};

export default MyXolvedTopBar;
