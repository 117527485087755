import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import PinPannel from "../components/PinPannel";
import DebugPanel from "../components/DebugPanel";
import { ChartProvider } from "../hooks/useChart";
import { useLibrary } from "../hooks/useLibrary";
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg"
import LibraryDropdown from "../components/LibraryDropdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { MetricNode } from "../lexical/MetricNode";
import MyXolvedTopBar from "../components/TopBar/MyXolvedTopBar";
import MyXolvedStaticSidePanel from "../components/StaticLeftPanel/MyXolvedStaticSidePanel";
import MyXolvedPanel from "../components/MyXolvedPanel";

const MyXolvedPage: React.FC = () => {
  const { library, setLibrary, libraries } = useLibrary();
  const [isLibrarySelected, setLibrarySelected] = useState<boolean>(library !== null);

  const [sidePanelName, setSidePanelName] = useState("new")

  const handleLibrarySelection = (selectedOption: { value: number, label: string } | null) => {
    if (selectedOption) {
      const selectedLibrary = libraries?.find(lib => lib.id === selectedOption.value);
      setLibrary(selectedLibrary || null);
      setLibrarySelected(true);
    }
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const prepopulatedRichText = () => {
  }

  const editorConfig = {
    namespace: "metrics-editor",
    onError: onError,
    nodes: [MetricNode],
    editorState: prepopulatedRichText,
  };

  if (!isLibrarySelected) {
    return (
      <div className="App w-full h-screen relative flex">
        <Sidebar />
        <div className="flex-1 flex flex-col justify-center items-center">
          <StartIcon className="h-150 w-150 " />
          <div className="font-semibold">Unlock the information you need with Xolved</div>
          <label className="mb-2" htmlFor="librarySelector">To get started select your area of focus:</label>
          <div className="w-1/2">
            <LibraryDropdown
              libraries={libraries || []}
              selectedLibrary={library}
              onLibrarySelect={handleLibrarySelection}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ChartProvider>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="App pl-[56px] w-full h-screen relative flex">
          <MyXolvedTopBar title="My Xolved" panelName={sidePanelName} setPanelName={setSidePanelName}/>
          <Sidebar />
          <MyXolvedPanel panelName={sidePanelName} setPanelName={setSidePanelName}/>
          {/* <PinPannel /> */}
          <DebugPanel />
        </div>
      </LexicalComposer>
    </ChartProvider>
  );

};

export default MyXolvedPage;
