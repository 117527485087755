import {
    $applyNodeReplacement,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalEditor,
    LexicalNode,
    SerializedTextNode,
    Spread,
    TextNode,
} from "lexical";
import { spawnMetricTooltip } from "../components/ToolTip/MetricToolTip";
import { closeTooltip } from "../components/ToolTip/ToolTipHelpers";
import { spawnCohortTooltip } from "../components/ToolTip/CohortToolTip";
import { Cohort } from "../hooks/useCohorts";
  
export type SerializedCohortNode = Spread<
    {
        cohort: Cohort;
        concept: string;
        attribute: string;
        values: string[];
        allowEdit: boolean;
    },
    SerializedTextNode
>;
  
class CohortNode extends TextNode {
    __cohort: Cohort;
    __concept: string;
    __attribute: string;
    __values: string[];
    __popup: {
        current: HTMLDivElement | null;
    };
    __popupArrow: {
        current: SVGSVGElement | null;
    };
    __allowEdit: boolean;
  
    static getType(): string {
        return "cohort";
    }
  
    static clone(node: CohortNode): CohortNode {
        return new CohortNode(node.__cohort, node.__concept, node.__attribute, node.__values, node.__allowEdit, node.__text, node.__key);
    }
  
    constructor(cohort: Cohort, concept: string, attribute: string, values: string[], allowEdit: boolean, text?: string, key?: string) {
        const assembledText = `@${cohort.name}@`
        super(text ?? assembledText, key);
        this.__cohort = cohort;
        this.__concept = concept;
        this.__attribute = attribute;
        this.__values = values;
        this.__popup = {current: null};
        this.__popupArrow = {current: null};
        this.__allowEdit = allowEdit;
    }
  
    handleHover = (e : any) => {
        if (this.__popup.current === null) {
            this.__popup.current = spawnCohortTooltip(e, this.__cohort, this.__concept, this.__attribute, this.__values, this.__allowEdit, this.__popupArrow);
            e.stopPropagation();
        }
    }
  
    handleLeave = (e: any) => {
        if (this.__popup.current !== null && !this.__popup.current.contains(e.relatedTarget)) {
            closeTooltip(this.__popup.current);
            this.__popup.current = null;
        }
    }
  
    createDOM(config: EditorConfig): HTMLElement {
        const dom = super.createDOM(config);
        dom.addEventListener("mouseleave", this.handleLeave);
        dom.addEventListener("mouseenter", this.handleHover);
    
        dom.style.font = "Inter";
        dom.style.fontStyle = "normal";
        dom.style.fontWeight = "500";
        dom.style.fontSize = "13px";
        dom.style.lineHeight = "16px";
        dom.style.color = "#6E43F8";
        dom.style.background = "#F3F4F6";
        dom.style.border = "1px solid #E5DDFD";
        dom.style.borderRadius = "10px";
        dom.style.height = "fit";
        dom.style.width = "fit";
        dom.style.padding = "3px 8px"
    
        return dom;
    }
  
    exportDOM(): DOMExportOutput {
        const element = document.createElement("span");
        element.setAttribute("data-lexical-cohort", "true");
        element.textContent = this.__text;
        return { element };
    }
  
    static importDOM(): DOMConversionMap | null {
        return {
            span: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute("data-lexical-cohort")) {
                    return null;
                }
                return {
                    conversion: convertCohortElement,
                    priority: 1,
                };
            },
        };
    }
  
    static importJSON(serializedNode: SerializedCohortNode): CohortNode {
        const node = $createCohortNode(serializedNode.cohort, serializedNode.concept, serializedNode.attribute, serializedNode.values, serializedNode.allowEdit);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
  
    exportJSON(): SerializedCohortNode {
        return {
            ...super.exportJSON(),
            cohort: this.__cohort,
            concept: this.__concept,
            attribute: this.__attribute,
            values: this.__values,
            allowEdit: this.__allowEdit,
            type: CohortNode.getType(),
            version: 1,
        };
    }
  
    isTextEntity(): boolean {
        return true;
    }
  
    canInsertTextBefore(): boolean {
        return false;
    }
  
    canInsertTextAfter(): boolean {
        return false;
    }
}
  
function convertCohortElement(
    domNode: HTMLElement
): DOMConversionOutput | null {
    // const textContent = domNode.textContent;
    // if (textContent !== null) {
    //   return {
    //     node: $createCohortNode(textContent, "", "", "", ""),
    //   };
    // }
    return null;
}
  
function $createCohortNode(cohort: Cohort, concept: string, attribute: string, values: string[], allowEdit: boolean): CohortNode {
    const cohortNode = new CohortNode(cohort, concept, attribute, values, allowEdit);
    cohortNode.setMode("token").toggleDirectionless();
    return $applyNodeReplacement(cohortNode);
}
  
function $isCohortNode(
    node: LexicalNode | null | undefined
): node is CohortNode {
    return node instanceof CohortNode;
}
  
export {
    CohortNode,
    $createCohortNode,
    $isCohortNode,
};