import React, { useRef, useEffect, useState } from "react";
import ChatBox from "./ChatBox";
import { useChart } from "../hooks/useChart";
import ConversationContainer from "./ConversationContainer";
import {
    ChatMessage,
    StreamMessageStep,
    StreamMessageTypes,
} from "../@types/common";
import { useAuth } from "../hooks/useAuth";
import { HistoryEntry, useHistory } from "../hooks/useHistory";
import { useChatbox } from "../hooks/useChatbox";
import { useToast } from "../hooks/useToast";
import { useLibrary } from "../hooks/useLibrary";
import { useSample } from "../hooks/useSample";
import { usePin } from "../hooks/usePin";
import { useSession } from "../hooks/useSession";
import * as api from "../api";
import { format } from "date-fns";
import mixpanel from 'mixpanel-browser';
import { v4 as UUID } from "uuid";
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg"
import { useLocation } from "react-router-dom";
import StaticSidePanel from "./StaticLeftPanel/StaticSidePanel";
import { strToDate } from "../helpers/datetimehelper";
import { useMessage } from "../hooks/useMessage";
import { ReactComponent as MetricIconPassive } from "../svg/verified_metric_icon_passive.svg" 
import { ReactComponent as MetricIconActive } from "../svg/verified_metric_icon_active.svg" 
import { useCohort } from "../hooks/useCohorts";
import { useConcept } from "../hooks/useConcepts";


interface MainPanelRef {
  panelName: string;
  setPanelName: (t: string) => void;
}

const MainPanel: React.FC<MainPanelRef> = ({panelName, setPanelName}) => {
    const { charts } = useChart();
    const toast = useToast();
    const { library } = useLibrary();
    const { userProfile, isAuthenticated } = useAuth();
    const lastChartRef = useRef<HTMLDivElement>(null);
    const streamRef = useRef<EventSource | null>(null);
    // const [messages, setMessages] = useState<ChatMessage[]>([]);
    const { messages, setMessages } = useMessage();
    const messagesRef = useRef<ChatMessage[]>([]);
    const { inputText, setInput } = useChatbox();
    const { addHistory, updateHistoryStatus, updateHistoryIds } = useHistory();
    const conversationContainerRef = useRef<HTMLDivElement>(null);
    const [lastScrollPercentage, setLastScrollPercentage] = useState(0);
    const [finished] = useState("Processing");
    const id = `user_${Date.now()}`;

    const { currentSessionId } = useSession();
    const { state } = useLocation();

    const [bottonPanelName, setBottomPanelName] = useState("none");

    useEffect(() => {
        if (state !== null && "reask" in state) {
            setInput(state["reask"]);
        }
    }, []);

    const handleScroll = () => {
        const container = conversationContainerRef.current;
        if (!container) {
            return;
        }

        const scrollPercentage = (container.scrollTop + container.clientHeight) / container.scrollHeight;

        // Trigger event when the scroll crosses the midpoint, from either direction
        if ((lastScrollPercentage <= 0.5 && scrollPercentage > 0.5) || (lastScrollPercentage > 0.5 && scrollPercentage <= 0.5)) {
            // console.log("Scroll tracked", scrollPercentage)
            mixpanel.track('Scroll Conversation Container', {
                'percentageScrolled': scrollPercentage,
            });
        }

        // Update last scroll percentage for next scroll event
        setLastScrollPercentage(scrollPercentage);
    };

    const handleUserMessage = async (message: string) => {
        const userMessage: ChatMessage = {
            id: id,
            sender: "user",
            content: message,
            date: new Date(),
        };
        setMessages([...messages, userMessage]);

        let date = userMessage.date;
        let utcDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        );

        const newHistoryEntry : HistoryEntry = {
            id,
            historyid: "",
            userid: id,
            message: message,
            timestamp: format(utcDate, "yyyy-MM-dd HH:mm:ss.SSS"),
            status: finished,
            session_id: currentSessionId,
        };

        const serverMessage: ChatMessage = {
            id: `server_${Date.now()}`,
            sender: "server",
            status: "loading",
            date: new Date(),
            steps: [],
            conversationId: UUID(),
        };

        setMessages((prevMessages) => [...prevMessages, serverMessage]);
        try {
            const response = await fetch(`/api/stream?email=${userProfile?.email}&library_id=${library?.id}&session_id=${currentSessionId}`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                conversation_id: serverMessage.conversationId,
                question: message,
                question_history: messages
                    .filter((message) => message.sender === "user")
                    .slice(-2)
                    .map((message) => message.content)
                    .reverse(),
                answer_history: messages
                    .filter(
                    (message) =>
                        message.sender === "server" && message.status === "success"
                    )
                    .slice(-2)
                    .map((message) =>
                    message.steps && message.steps.length > 0
                        ? message.steps.slice(-1)[0].data
                        : null
                    )
                    .reverse()
                }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                serverMessage.status = "error";
                serverMessage.content = responseData.error;
            } else {
                serverMessage.query_id = responseData.query_id;
                newHistoryEntry.id = serverMessage.conversationId || "";
                newHistoryEntry.historyid = serverMessage.query_id || "";
                addHistory(newHistoryEntry);
            }
            } catch (error) {
                console.error(error);
                serverMessage.status = "error";
                serverMessage.content =
                    "We're continuing to work on your query in the background. Please try it again later.";
            }

            setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.id === serverMessage.id ? serverMessage : message
            )
        );
    };

    useEffect(() => {
        messagesRef.current = messages;
    }, [messages]);

    useEffect(() => {
        if (lastChartRef.current) {
            setTimeout(() => {
                lastChartRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
    }, [charts]);

    useEffect(() => {
        const reconnect = () => {
        if (streamRef.current) streamRef.current.close();
        streamRef.current = new EventSource(`/api/stream/listen?email=${userProfile?.email}`);
        streamRef.current.onmessage = (event) => {
            const streamData = JSON.parse(event.data);
            console.log(streamData);
            const conversationID = streamData.conversation_id;
            console.log("conversationID:", conversationID);
            const messages = messagesRef.current;
            // console.log("messages:", messages);
            const serverMessage = messages.find(
            (message) => message.conversationId === conversationID
            );
            if (!serverMessage) {
            console.error("Server message not found");
            return;
            }
            serverMessage.status = "success";
            const type = streamData.type ? streamData.type as StreamMessageTypes : "error";

            try {
                switch (type) {
                    case "initialize":
                    serverMessage.content = "Identifying user intent";
                    serverMessage.input = streamData.input;
                    const initStep: StreamMessageStep = {
                        type: type,
                        plan: "Identifying user intent",
                        estimatedTime: streamData.estimatedtime,
                        status: "loading",
                        debug: streamData.debug,
                    };
                    serverMessage.steps!.push(initStep);
                    break;
                    case "plan":
                    const planMessageStep: StreamMessageStep = {
                        type: type,
                        timeSpent: streamData.timespent,
                        status: "success",
                        data: streamData.data,
                        debug: streamData.debug,
                        plan: "Identifying user intent",
                        message: "User intent is: " + streamData.message,
                    };
                    serverMessage.steps![0] = planMessageStep;
                    for (let index = 0; index < streamData.data.length; index++) {
                        const newMessage: StreamMessageStep = {
                        plan: streamData.data[index].step,
                        estimatedTime: streamData.data[index].estimated_time,
                        status: "queued",
                        };
                        serverMessage.steps!.push(newMessage);
                    }
                    serverMessage.currentStep = 1;
                    if (serverMessage.currentStep! < serverMessage.steps!.length)
                        serverMessage.steps![serverMessage.currentStep!].status =
                        "loading";
                    break;
                    case "text":
                    if (serverMessage.steps!.length > 0) {
                        const textMessage =
                        serverMessage.steps![serverMessage.currentStep!];
                        if (textMessage) {
                        textMessage.status = "success";
                        textMessage.type = type;
                        textMessage.data = streamData.data;
                        textMessage.timeSpent = streamData.timespent;
                        serverMessage.currentStep! += 1;
                        if (serverMessage.currentStep! < serverMessage.steps!.length)
                            serverMessage.steps![serverMessage.currentStep!].status =
                            "loading";
                        else
                            updateHistoryStatus({
                            id: conversationID,
                            newStatus: "Completed",
                            });
                        } else {
                        console.error("textMessage is undefined");
                        }
                    } else {
                        serverMessage.content = streamData.data;
                        serverMessage.status = "success";
                    }
                    case "sql":
                    var streamMessageStep: StreamMessageStep;
                    if (serverMessage.steps!.length > 0) {
                        streamMessageStep = serverMessage.steps![serverMessage.currentStep!];
                    } else {
                        streamMessageStep = {
                        plan: "Create SQL",
                        estimatedTime: streamData.estimatedtime,
                        status: "loading",
                        };
                        serverMessage.steps!.push(streamMessageStep);
                    }
                    streamMessageStep.type = type;
                    streamMessageStep.timeSpent = streamData.timespent;
                    serverMessage.sql = streamData.data;
                    streamMessageStep.data = streamData.data;
                    streamMessageStep.debug = streamData.debug;
                    streamMessageStep.status = "success";
                    serverMessage.currentStep! += 1;
                    if (serverMessage.currentStep! < serverMessage.steps!.length) {
                        const nextStep = serverMessage.steps![serverMessage.currentStep!];
                        nextStep.status = "loading";
                        if (nextStep.plan?.toLowerCase() === "retrieve result")
                        nextStep.estimatedTime = streamData.estimatedtime;
                    } else
                        updateHistoryStatus({
                        id: conversationID,
                        newStatus: "Completed",
                        });
                    break;
                    case "table":
                    var tableMessageStep: StreamMessageStep;

                    if (serverMessage.steps!.length > 0) {
                        tableMessageStep = serverMessage.steps![serverMessage.currentStep!]
                    } else {
                        tableMessageStep = {
                        plan: "Retrieve result",
                        estimatedTime: streamData.estimatedtime,
                        status: "loading",
                        };
                        serverMessage.steps!.push(tableMessageStep);
                    }


                    tableMessageStep.type = type;
                    tableMessageStep.timeSpent = streamData.timespent;
                    tableMessageStep.data = streamData.data;
                    tableMessageStep.debug = streamData.debug;
                    tableMessageStep.tableData = streamData.table_data;
                    tableMessageStep.tableHead = streamData.table_head;
                    serverMessage.tableHead = streamData.table_head;
                    serverMessage.tableData = streamData.table_data;
                    tableMessageStep.status = "success";
                    serverMessage.currentStep! += 1;
                    if (serverMessage.currentStep! < serverMessage.steps!.length)
                        serverMessage.steps![serverMessage.currentStep!].status =
                        "loading";
                    else
                        updateHistoryStatus({
                        id: conversationID,
                        newStatus: "Completed",
                        });
                    break;
                    case "error":
                    console.log("Hello?");
                    if (serverMessage.steps!.length > 0) {
                        const errorMessage =
                        serverMessage.steps![serverMessage.currentStep!];
                        errorMessage.status = "error";
                        serverMessage.status = "error";
                        errorMessage.type = type;
                        errorMessage.data = streamData.data;
                        if (
                        serverMessage.currentStep! <
                        serverMessage.steps!.length - 1
                        ) {
                        serverMessage.steps!.slice(0, serverMessage.currentStep! + 1);
                        }
                    } else {
                        serverMessage.content = streamData.data;
                        serverMessage.status = "error";
                    }
                    updateHistoryStatus({ id: conversationID, newStatus: "Failed" });
                    break;
                    default:
                    console.error("Unknown message type");
                    break;
                }
            } catch (error) {
                console.error(error);
                serverMessage.status = "error";
                serverMessage.content = "We're continuing to work on your query in the background. Please try it again later.";
            }

            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message.id === serverMessage.id ? serverMessage : message
                )
            );
        };
        streamRef.current.onerror = (err) => {
            console.error("EventSource failed:", err);
            if (streamRef.current) streamRef.current.close();
            setTimeout(() => {
            reconnect();
            }, 2000);
        };
        };

        reconnect();
        // Close all EventSource connections when the component unmounts

        return () => {
        if (streamRef.current) streamRef.current.close();
        };
    }, []);

    const fetchRecentHistory = async (email: string) => {
        const response = await fetch(`/api/recenthistory?email=${email}`);
        if (!response.ok) {
        throw new Error("Failed to fetch user history");
        }
        const responseData = await response.json();

        const formattedMessages: ChatMessage[] = responseData.data.reduce(
        (messages: ChatMessage[], msg: any) => {
            const date = strToDate(msg.timestamp);
            let questionMessage: ChatMessage = {
            id: msg?.id + "-question",
            sender: "user",
            content: msg.question,
            date: date,
            status: "success",
            };

            let responseMessage: ChatMessage = {
            id: msg?.id + "-response",
            query_id: msg.id,
            sender: "server",
            input: msg.question,
            tableHead: msg.response.table_head,
            tableData: msg.response.table_data,
            sql: msg.response.sql,
            content:
                msg.response.table_head ||
                msg.response.table_data ||
                msg.response.sql
                ? null
                : msg.status === "Completed"
                    ? msg.response.data
                    : "Query Failed",
            date: date,
            status:
                msg.status === "Completed" ? "success" : msg.status.toLowerCase(),
            feedback: msg.feedback,
            };

            return [...messages, questionMessage, responseMessage];
        },
        []
        );

        setMessages(formattedMessages);

        const questionMessages = formattedMessages.filter((message) =>
        message.id.endsWith("-question")
        );
        if (questionMessages) updateHistoryIds(questionMessages);
    };

    return (
        <div className="h-screen w-full overflow-x-auto flex-1 flex flex-row bg-[#f5f5f5] pt-[56px] justify-between" id="main_panel_3">
            <StaticSidePanel
                panelName={panelName} 
                setPanelName={setPanelName}
            />
                <div className="w-full h-full flex flex-col justify-end mx-[50px] overflow-x-visible min-w-[750px]">
                    <div className="overflow-y-auto pr-[8px]" ref={conversationContainerRef} onScroll={handleScroll}>
                        <div className="flex flex-col items-center justify-center h-fit pb-[60px] pt-[30px]">
                            <StartIcon className="h-150 w-150" transform="scale(1)" />
                            <h2 className="pt-5 w-3/4 text-2xl font-bold text-gray-700">
                            Use Xolved’s artificial intelligence chatbot to unlock the information you need
                            </h2>
                            <p className="mt-2 text-gray-500">To get started, ask a question below.</p>
                            <p className="mt-2 text-gray-500">No need to be fancy, just use simple language and see what works!</p>
                            <p className="mt-2 text-gray-500">Protip: Try [ ] brackets to create a query using our defined metrics and concepts.</p>
                        </div>
                        <ConversationContainer messages={messages} page="Q&A" />
                    </div>
                    <ChatBox
                    onSubmit={handleUserMessage}
                    message={inputText}
                    setMessage={setInput}
                    panelName={bottonPanelName}
                    setPanelName={setBottomPanelName}
                    />
                </div>
        </div>
    );
};

export default MainPanel;
