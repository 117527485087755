import React, { createContext, useState, useContext } from "react";
import { ChatMessage } from "../@types/common";

interface MessageContextData {
  messages: ChatMessage[];
  // setMessages: (messages: ChatMessage[]) => void;
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  addMessage: (message: ChatMessage) => void;
}

const MessageContext = createContext<MessageContextData | undefined>(undefined);

const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("useMessage must be used within an MessageProvider");
  }
  return context;
};

const MessageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const addMessage = (newMessage: ChatMessage) => {
    setMessages(messages => [...messages, newMessage]);
  };

  return (
    <MessageContext.Provider value={{ messages, setMessages, addMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export { MessageProvider, useMessage };
