import React, { createContext, useState, useContext } from "react";

import { ChatMessage } from "../@types/common";

interface DebugContextData {
  open: boolean;
  message: ChatMessage | null;
  setOpen: (open: boolean) => void;
  setMessage: (message: ChatMessage | null) => void;
}

const DebugContext = createContext<DebugContextData | undefined>(undefined);

const useDebug = () => {
  const context = useContext(DebugContext);
  if (!context) {
    throw new Error("useDebug must be used within an DebugProvider");
  }
  return context;
};

const DebugProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<ChatMessage | null>(null);

  return (
    <DebugContext.Provider value={{ open, message, setOpen, setMessage }}>
      {children}
    </DebugContext.Provider>
  );
};

export { DebugProvider, useDebug };
