import React, { useState, useEffect, Fragment } from 'react';
import { Page, useAuth } from '../hooks/useAuth';
import TextEditor from './TextEditor';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import Buttons from "./Buttons";
import CodeContainer from './EditableCodeContainer';
import TableContainer from "./TableContainer";
import Parameters from './Parameters';
import Loader from './Loader';
import { useToast } from "../hooks/useToast"
import { useLibrary } from '../hooks/useLibrary';
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg";
import { ReactComponent as MagicIcon } from "../svg/magic_icon.svg";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import { SparklesIcon } from '@heroicons/react/24/outline'
import { ReactComponent as CloseIcon } from "../svg/metric_central/close_icon.svg";
import { ReactComponent as DuplicateIcon } from "../svg/metric_central/duplicate_icon.svg";
import { ReactComponent as ShowSQLIcon } from "../svg/metric_central/shevron_down.svg";
import { ReactComponent as HideSQLIcon } from "../svg/metric_central/shevron_up.svg";
import { Item, Metric, isDomain, isMetric } from '../@types/common';
import { useMetricDomain } from '../hooks/useMetricDomain';
// import { Concept } from '../hooks/useConcepts';
import { useMetrics } from '../hooks/useMetrics';
import { useCustomTree } from '../hooks/useCustomTree';

import { ArrowUturnLeftIcon, ArrowUturnRightIcon } from '@heroicons/react/24/outline'
import { SimpleToolTipId } from './ToolTip/SimpleToolTip';
import { Dialog, Transition } from '@headlessui/react';

interface TableColumn {
    COLUMN_NAME: string;
    DATA_TYPE: string;
}

interface MetricPanelProps {
    page: Page;
}

const MetricPanel: React.FC<MetricPanelProps> = ({page}) => {
    const {metrics, addNewMetric, duplicateMetric, deleteMetric, currentItem, setCurrentItem, generateSQL, redo, undo, canRedo, canUndo, savingMetric} = useMetrics();
    const {setPrivateMetricExpanded} = useCustomTree();
    const [status, setStatus] = useState<string>("empty");
    const [showTable, setShowTable] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const [executionResult, setExecutionResult] = useState<string | null>(null);
    const [tableHead, setTableHead] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [quotedTexts, setQuotedTexts] = useState<string[]>([]);
    const [params, setParams] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const { userProfile, checkEditPrivilege } = useAuth();
    const { library } = useLibrary();
    const [showSQL, setShowSQL] = useState<boolean>(false);
    const [SQLType, setSQLType] = useState("generated");
    const {tree, metricToTreeID, focusedItem, setFocusedItem} = useCustomTree();
    const [openWarning, setOpenWarning] = useState(false);
    const [wasSavingMetric, setWasSavingMetric] = useState(false);
    const [metricSaved, setMetricSaved] = useState(false);

    const findParams = (passItem : Item | null = null) => {
        const quoted = (editor.getRootElement()?.textContent || "").match(/{(.*?)}/g) || [];
        setQuotedTexts(quoted);
        setShowTable(true);

        if (quoted.length === 0) {
            executeSQL([], passItem);
        }
        return;
    };

    const executeSQL = async (params: string[], passItem : Item | null = null) => {
        if (currentItem !== null && isMetric(currentItem)) {
            setLoading(true);
            const response = await fetch(`/api/execute_sql?email=${userProfile?.email}&library_id=${library?.id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "sql_statement": (passItem !== null ? passItem.sql : currentItem.sql_statement) || "",
                    "param_names": quotedTexts,
                    "param_values": params,
                }),
            });

            const responseData = await response.json();
            console.log(responseData)
            if (responseData.message == "failed") {
                console.log("executeSQL failed");
                toast.error("Execute SQL failed");
            } else {
                setExecutionResult(JSON.stringify(responseData, null, 2));
                setTableHead(responseData.column_names);
                setTableData(responseData.records);
                setLoading(false);
            }
        }
    };

    const getButtonClass = (buttonType: string, buttonColor?: string) => {
        const defaultColor = buttonColor || 'white';

        if (isMetric(currentItem)) {
            if (currentItem.metric_status !== 'Verified') {
                // If button is enabled
                switch (buttonColor) {
                    case 'teal':
                        return "px-4 mx-1 py-2 bg-teal-500 text-white text-sm rounded-md shadow-button hover:bg-teal-900 focus:outline-none focus:ring-2 focus:ring-teal-900 focus:ring-opacity-50";
    
                    //By default - white color
                    default:
                        return "px-4 mx-1 py-2 bg-white text-slate-900 border-1 border-slate-300 text-sm rounded-md shadow-button hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-teal-900 focus:ring-opacity-50";
                }
            } else {
                //If button is disabled
                switch (buttonColor) {
                    case 'teal':
                        return "px-4 mx-1 py-2 bg-teal-100 text-white text-sm rounded-md ";
    
                    default:
                        return "px-4 mx-1 py-2 bg-gray-200 text-white text-sm rounded-md ";
                }
            }
        }
    };

    // useEffect(() => {
    //     if (currentMetric !== null) {
    //         updateMetric(currentMetric);
    //     }
    //     // const updatedModItems = items.map((it) => {
    //     //     if (it.id === item.id && it.type === item.type) {
    //     //         return { ...it, ...item };
    //     //     }
    //     //     return it;
    //     // });
    //     // setItems(updatedModItems);
    // }, [currentMetric]);

    useEffect(() => {
        setQuotedTexts([]);
        setParams([]);
        setTableHead([]);
        setTableData([]);
        setLoading(false);
        setShowTable(false);
    }, [currentItem]);

    useEffect(() => {
        if (!savingMetric) {
            if (wasSavingMetric) {
                setMetricSaved(true);
                setTimeout(() => {
                    setMetricSaved(false);
                }, 1000);
            }
            setWasSavingMetric(false);
        } else {
            setWasSavingMetric(true);
            setMetricSaved(false);
        }
    }, [savingMetric]);

    if (!isMetric(currentItem)) {
        return (
            <div className="pt-[56px] space-y-4 grow flex flex-col items-center justify-center w-100">
                <div className='max-w-3xl text-center '>
                    <center><StartIcon className="w-150 h-150" /></center>
                    <div className='my-2 font-semibold text-2xl text-center'>To get started, create a new concept or new metric,
                        or select an existing metric or concept to view and edit it</div>
                    <div className='my-2 text-center'>Concepts are low level building blocks connecting directly to your data.</div>
                    <div className='my-2 text-center'>Metrics string together Concepts and other Metrics describing more complex ideas that you want to measure.</div>
                    <button className="px-4 mx-1 py-2 bg-teal-500 text-white text-sm rounded-md shadow-button hover:bg-teal-900 focus:outline-none focus:ring-2 focus:ring-teal-900 focus:ring-opacity-50" onClick={() => {
                        if (!checkEditPrivilege('MetricCentral', library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        if (!(focusedItem in tree)) {
                            addNewMetric(0, editor);
                        } else {
                            const item = tree[focusedItem].data;
                            if (isMetric(item) && !item.all_users && item.domain_id != null)
                                addNewMetric(item.domain_id, editor);
                            else if (isDomain(item) && !item.all_users)
                                addNewMetric(item.id, editor);
                            else
                                addNewMetric(0, editor);
                        }
                    }}>
                        + New Metric
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="pt-[56px] text-left space-y-2 px-4 max-w-4xl relative pb-40 mt-[10px] w-full">
            {/* <div className='absolute top-[56px] left-[0px] w-full h-fit flex flex-row items-center justify-center'>
                <div className='bg-blue-300 w-[50px] h-[50px]'>

                </div>
            </div> */}
            <div className="w-full h-fit justify-between items-center inline-flex">
                <div className="justify-start items-center gap-2 flex">
                    <div className="justify-center items-center gap-2.5 flex">
                        <div className="text-black text-[21px] font-bold font-Inter">Edit Metric</div>
                    </div>
                    {
                        metricSaved && 
                        <div className='font-Inter text-black text-[15px]'>
                            saved!
                        </div>
                    }
                    {
                        savingMetric && 
                        <div className='font-Inter text-black text-[15px]'>
                            saving metric ...
                        </div>
                    }
                </div>
                <div className="justify-end items-center gap-2 flex">
                    <button className={`flex flex-row items-center p-[9px] bg-gray-50 rounded-lg shadow border border-neutral-300 justify-center items-center gap-1.5 flex ${!canUndo() && "opacity-[0.5]"}`}
                        onClick={() => {
                            if (!checkEditPrivilege(page, library?.id || 0)) {
                                toast.warning("You do not have edit access to this page");
                                return;
                            }
                            undo(editor);
                        }}
                        disabled={!canUndo()}
                        data-tooltip-content={"Undo"}
                        data-tooltip-id={SimpleToolTipId}
                    >
                        {/* <DuplicateIcon/> */}
                        <ArrowUturnLeftIcon className='h-[20px] w-[20px]'/>
                        {/* <div className="text-slate-950 text-sm font-medium font-Inter">Undo</div> */}
                    </button>
                    <button className={`flex flex-row items-center p-[9px] bg-gray-50 rounded-lg shadow border border-neutral-300 justify-center items-center gap-1.5 flex ${!canRedo() && "opacity-[0.5]"}`}
                        onClick={() => {
                            if (!checkEditPrivilege(page, library?.id || 0)) {
                                toast.warning("You do not have edit access to this page");
                                return;
                            }
                            redo(editor);
                        }}
                        disabled={!canRedo()}
                        data-tooltip-content={"Redo"}
                        data-tooltip-id={SimpleToolTipId}
                    >
                        <ArrowUturnRightIcon className='h-[20px] w-[20px]'/>
                        {/* <div className="text-slate-950 text-sm font-medium font-Inter">Redo</div> */}
                    </button>
                    <button className="px-[21px] py-[9px] bg-gray-50 rounded-lg shadow border border-neutral-300 justify-center items-center gap-1.5 flex"
                        onClick={() => {
                            if (!checkEditPrivilege(page, library?.id || 0)) {
                                toast.warning("You do not have edit access to this page");
                                return;
                            }
                            if (isMetric(currentItem)) {
                                const item = tree[focusedItem].data;
                                if (isMetric(item) && !item.all_users && item.domain_id !== null) {
                                    duplicateMetric(currentItem.metric_id, item.domain_id, editor);
                                } else if (isDomain(item) && !item.all_users) {
                                    duplicateMetric(currentItem.metric_id, item.id, editor);
                                } else {
                                    duplicateMetric(currentItem.metric_id, 0, editor);
                                }
                                setPrivateMetricExpanded(true);
                            }
                        }}
                    >
                        <DuplicateIcon/>
                        <div className="text-slate-950 text-sm font-medium font-Inter">Duplicate</div>
                    </button>
                    {
                        <Transition appear show={openWarning} as={Fragment}>
                            <Dialog as="div" className="relative z-100" onClose={() => setOpenWarning(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto z-[10]">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="mt-2">
                                        <p className="font-Inter text-sm text-gray-500">
                                        Are you sure you want to delete this metric? You cannot revert this operation.
                                        </p>
                                    </div>

                                    <div className="mt-4 flex flex-row gap-[10px]">
                                        <button
                                        type="button"
                                        className="font-Inter inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => {setOpenWarning(false)}}
                                        >
                                        No
                                        </button>
                                        <button
                                        type="button"
                                        className="font-Inter inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => {
                                            setOpenWarning(false);
                                            if (!checkEditPrivilege(page, library?.id || 0)) {
                                                toast.warning("You do not have edit access to this page");
                                                return;
                                            }
                                            if (isMetric(currentItem)) {
                                                deleteMetric(currentItem.metric_id);
                                            }
                                        }}
                                        >
                                        Yes
                                        </button>
                                    </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                                </div>
                            </div>
                            </Dialog>
                        </Transition>
                    }
                    <button className={`px-[21px] py-[9px] bg-gray-50 rounded-lg shadow border border-neutral-300 justify-center items-center gap-1.5 flex ${isMetric(currentItem) && currentItem.metric_status === 'Verified' && 'opacity-[0.5]'}`}
                        onClick={() => {
                            if (!checkEditPrivilege(page, library?.id || 0)) {
                                toast.warning("You do not have edit access to this page");
                                return;
                            }
                            if (isMetric(currentItem)) {
                                setOpenWarning(true);
                                // deleteMetric(currentItem.metric_id);
                            }
                        }}
                        disabled={isMetric(currentItem) && currentItem.metric_status === 'Verified'}
                    >
                        <CloseIcon/>
                        <div className="text-[#BB0000] text-sm font-medium font-Inter">Delete</div>
                    </button>
                </div>
            </div>
            <div className='grow bg-white shadow rounded-md'>
                <div className="w-full h-fit px-[15px] py-2.5 justify-start items-start gap-[23px] inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="self-stretch py-0.5 justify-start items-center gap-2 inline-flex">
                            <div className="w-[90px] text-slate-950 text-[13px] font-bold font-Inter">
                                Metric Name
                            </div>
                            <input className="text-slate-950 text-[13px] font-medium font-Inter grow shrink basis-0 h-[34px] px-2.5 py-[9px] bg-white rounded-lg shadow border border-neutral-300 justify-start items-center gap-[7px] flex"
                                type="text" 
                                placeholder="Enter name" 
                                value={currentItem.metric_name !== `Untitled Metric` ? currentItem.metric_name : ''}
                                disabled={currentItem.metric_status === 'Verified'}
                                onChange={(e) => { setCurrentItem({ ...currentItem, metric_name: e.target.value }) }}
                            />
                        </div>
                    </div>
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="self-stretch py-0.5 justify-start items-center gap-2 inline-flex">
                            <div className="w-[90px] text-slate-950 text-[13px] font-bold font-Inter">
                                Description
                            </div>
                            <input className="text-slate-950 text-[13px] font-medium font-Inter grow shrink basis-0 h-[34px] px-2.5 py-[9px] bg-white rounded-lg shadow border border-neutral-300 justify-start items-center gap-[7px] flex"
                                type="text" 
                                placeholder="Enter description..."
                                value={currentItem.metric_desc || ''}
                                disabled={isMetric(currentItem) && currentItem.metric_status === 'Verified'}
                                onChange={(e) => { setCurrentItem({ ...currentItem, metric_desc: e.target.value }) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full h-fit px-[15px] py-2.5 flex-col justify-start items-start gap-[5px] inline-flex">
                    <div className="h-[18px] text-slate-950 text-[13px] font-bold font-Inter">
                        Business Logic
                    </div>
                    <div className="w-full h-[180px] bg-white rounded-lg shadow border border-neutral-300 justify-start items-center gap-[7px] inline-flex">
                        <TextEditor currentMetric={currentItem} setCurrentMetric={setCurrentItem}/>
                    </div>
                    <div className="px-[5px] py-[3px] bg-gray-50 rounded-[40px] justify-start items-center gap-2 inline-flex cursor-pointer"
                        onClick={() => {setShowSQL(!showSQL)}}
                    >
                        {showSQL ? <HideSQLIcon/> : <ShowSQLIcon/> }
                        <div className="text-gray-500 text-xs font-medium font-Inter select-none">
                            {showSQL ? "Hide SQL" : "Show SQL" }
                        </div>
                    </div>
                    {
                        showSQL && 
                        <div className="w-full h-fit flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="self-stretch py-0.5 justify-start items-start gap-12 inline-flex select-none">
                                <div className="justify-start items-center gap-2 flex cursor-pointer"                                      
                                    onClick={() => setCurrentItem({...currentItem, modified_sql: 'N'})}
                                >
                                    <input type="radio" className='w-4 h-4 shadow border-indigo-900 border-1 checked:border-4 focus:border-indigo-900 checked:border-indigo-900 focus:ring-indigo-900'
                                        checked={ currentItem.modified_sql === 'N' }
                                    />
                                    <div className={`text-black text-sm ${currentItem.modified_sql === 'N' ? 'font-bold' : 'font-medium'} font-Inter`}>Use AI Generated SQL</div>
                                </div>
                                <div className="justify-start items-center gap-2 flex cursor-pointer"                                        
                                    onClick={() => setCurrentItem({...currentItem, modified_sql: 'Y'})}
                                >
                                    <input type="radio" className='w-4 h-4 shadow border-indigo-900 border-1 checked:border-4 focus:border-indigo-900 checked:border-indigo-900 focus:ring-indigo-900'
                                        checked={ currentItem.modified_sql === 'Y' }
                                    />
                                    <div className={`text-black text-sm ${currentItem.modified_sql === 'Y' ? 'font-bold' : 'font-medium'} font-Inter`}>Use Custom SQL Query</div>
                                </div>
                            </div>
                            <div className="flex-1 h-[200px] w-full">
                                <CodeContainer code={currentItem.sql_statement || ""} status={status} isUserModified={currentItem.modified_sql || ""}
                                    handleValueChange={(newSql: string) => { 
                                        setCurrentItem({ ...currentItem, sql_statement: newSql, modified_sql: "Y" });
                                    }}
                                    disabled={currentItem.metric_status === "Verified" || currentItem.modified_sql === 'N'} 
                                />
                            </div>
                            
                        </div>
                    }
                    <div className="flex gap-2">
                        <button
                            className={`flex ${getButtonClass('Execute')} bg-gradient-light-violet text-white`}
                            // disabled={item.status === 'Verified'}
                            onClick={() => {
                                if (!checkEditPrivilege(page, library?.id || 0)) {
                                    toast.warning("You do not have edit access to this page");
                                    return;
                                }
                                if (currentItem.modified_sql === 'Y') {
                                    findParams();
                                } else {
                                    generateSQL(currentItem.metric_id).then(() => {
                                        findParams();
                                    });
                                }
                                // if (SQLType === "generated") {
                                //     setStatus('loading');
                                //     handleGenerateSQL().then((item) => {
                                //         handleButtonClick('Draft', item);
                                //         findParams(item);
                                //     });
                                // } else {
                                //     handleButtonClick('Draft');
                                //     findParams();
                                // }
                            }}
                        >
                            <SparklesIcon className='h-5 w-5 mr-1' />
                            Get Data
                        </button>
                    </div>
                </div>

                {showTable && (<>
                        <hr className='-mx-4' />
                        <div className="w-full border-gray-300 p-2">
                            <Parameters quotedTexts={quotedTexts} params={params} setParams={setParams} executeSQL={executeSQL} />
                            <div className='pt-2'>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <TableContainer input='' sql='' head={tableHead} data={tableData} id='' />
                                )}
                            </div>
                        </div>
                    </>)}
            </div>
        </div>
    )
};

export default MetricPanel;
