import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import MainPanel from "../components/MainPanel-3";
import DebugPanel from "../components/DebugPanel";
import { ChartProvider } from "../hooks/useChart";
import { useLibrary } from "../hooks/useLibrary";
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg"
import LibraryDropdown from "../components/LibraryDropdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { MetricNode } from "../lexical/MetricNode";
import QATopBar from "../components/TopBar/QATopBar";
import TopBar from "../components/TopBar/TopBar";
import SharedPanel from "../components/SharedPanel";
import SharedTopBar from "../components/TopBar/SharedTopBar";

const SharedSessionPage: React.FC = () => {
  const { library, setLibrary, libraries } = useLibrary();
  const [sidePanelName, setSidePanelName] = useState<string>("none");
  const [isLibrarySelected, setLibrarySelected] = useState<boolean>(library !== null);

  const handleLibrarySelection = (selectedOption: { value: number, label: string } | null) => {
    if (selectedOption) {
      const selectedLibrary = libraries?.find(lib => lib.id === selectedOption.value);
      setLibrary(selectedLibrary || null);
      setLibrarySelected(true);
    }
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const prepopulatedRichText = () => {
  }

  const editorConfig = {
    namespace: "metrics-editor",
    onError: onError,
    nodes: [MetricNode],
    editorState: prepopulatedRichText,
  };

  return (
    <ChartProvider>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="App pl-[56px] w-full h-screen relative flex">
            <SharedTopBar/>
            <Sidebar />
            <SharedPanel/>
            <DebugPanel />
        </div>
      </LexicalComposer>
    </ChartProvider>
  );

};

export default SharedSessionPage;
