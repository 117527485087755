import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./hooks/useAuth";
import ToastProvider from "./hooks/useToast";
import { DebugProvider } from "./hooks/useDebug";
import { HistoryProvider } from "./hooks/useHistory";
import { ChatboxProvider } from "./hooks/useChatbox";
import { LibraryProvider } from "./hooks/useLibrary";
import { MetricsProvider } from "./hooks/useMetrics";
import { SampleProvider } from "./hooks/useSample";
import { PinProvider } from "./hooks/usePin";
import { PinResultProvider } from "./hooks/usePinResult";
import { MetricDomainProvider } from "./hooks/useMetricDomain";
import { SessionProvider } from "./hooks/useSession";
import { MessageProvider } from "./hooks/useMessage";
import { CohortProvider } from "./hooks/useCohorts";
import { ConceptProvider } from "./hooks/useConcepts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ToastProvider>
    <AuthProvider>
      <DebugProvider>
        <LibraryProvider>
          <HistoryProvider>
            <SampleProvider>
              <PinProvider>
                <PinResultProvider>
                  <ChatboxProvider>
                    <MetricsProvider>
                      <MetricDomainProvider>
                        <SessionProvider>
                          <MessageProvider>
                            <CohortProvider>
                              <ConceptProvider>
                                <React.StrictMode>
                                  <App />
                                </React.StrictMode>
                              </ConceptProvider>
                            </CohortProvider>
                          </MessageProvider>
                        </SessionProvider>
                      </MetricDomainProvider>
                    </MetricsProvider>
                  </ChatboxProvider>
                </PinResultProvider>
              </PinProvider>
            </SampleProvider>
          </HistoryProvider>
        </LibraryProvider>
      </DebugProvider>
    </AuthProvider>
  </ToastProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
