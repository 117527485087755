import React, { useState } from "react";
import { Toast } from "flowbite-react";
import {
  CheckIcon,
  InformationCircleIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

type ToastType = "info" | "success" | "warning" | "error";

type ToastComponetProps = {
  bg: string;
  textColor: string;
  icon: React.ReactNode;
};

type ToastProps = {
  message: string;
  duration: number;
  id: number;
  type: ToastType;
} & ToastComponetProps;

interface ToastObject {
  info: (message: string, duration?: number) => void;
  success: (message: string, duration?: number) => void;
  error: (message: string, duration?: number) => void;
  warning: (message: string, duration?: number) => void;
}

const ToastMap: Record<ToastType, ToastComponetProps> = {
  info: {
    bg: "bg-blue-100",
    textColor: "text-blue-500",
    icon: <InformationCircleIcon className="h-6 w-6" aria-hidden="true" />,
  },
  success: {
    bg: "bg-green-100",
    textColor: "text-green-500",
    icon: <CheckIcon className="h-6 w-6" aria-hidden="true" />,
  },
  error: {
    bg: "bg-red-100",
    textColor: "text-red-500",
    icon: <XMarkIcon className="h-6 w-6" aria-hidden="true" />,
  },
  warning: {
    bg: "bg-orange-100",
    textColor: "text-orange-500",
    icon: <ExclamationTriangleIcon className="h-6 w-6" aria-hidden="true" />,
  },
};

const ToastContext = React.createContext<ToastObject | null>(null);

let id = 0;

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = (message: string, type: ToastType, duration: number) => {
    if (toasts.length >= 4) {
      setToasts((prevToasts) => prevToasts.slice(1));
    }

    const toast = { message, type, duration, id: id++, ...ToastMap[type] };
    setToasts((prevToasts) => [...prevToasts, toast]);

    setTimeout(() => {
      removeToast(toast);
    }, 3000);
  };

  const removeToast = (toast: ToastProps) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id));
  };

  const toast = {
    info: (message: string, duration?: number) => {
      addToast(message, "info", duration || 3000);
    },
    success: (message: string, duration?: number) => {
      addToast(message, "success", duration || 3000);
    },
    warning: (message: string, duration?: number) => {
      addToast(message, "warning", duration || 3000);
    },
    error: (message: string, duration?: number) => {
      addToast(message, "error", duration || 3000);
    },
  };

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <div className="fixed top-5 flex flex-col gap-4 items-center justify-center w-full z-50">
        {toasts.map((toast) => (
          <Toast key={toast.id}>
            <div
              className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${toast.bg} ${toast.textColor}`}
            >
              {toast.icon}
            </div>
            <div className="ml-3 text-sm font-normal">{toast.message}</div>
            <Toast.Toggle
              onClick={() => {
                removeToast(toast);
              }}
            />
          </Toast>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export { useToast };
export default ToastProvider;
