import CloseButton from "../CloseButton";
import { useEffect, useRef, useState } from "react";
import React from "react";
import MetricBottomPanel from "./MetricBottomPanel";
import CohortBottomPanel from "./CohortBottomPanel";
import ConceptBottomPanel from "./ConceptBottomPanel";

import { $getSelection, COMMAND_PRIORITY_HIGH, SELECTION_CHANGE_COMMAND } from "lexical";
import { $isConceptNode, parseConceptNode } from "../../lexical/ConceptNode";
import { useConcept } from "../../hooks/useConcepts";
import { Attribute, Concept, Metric } from "../../@types/common";
import * as api from "../../api";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLibrary } from "../../hooks/useLibrary";
import { $isCohortNode, CohortNode } from "../../lexical/CohortNode";
import { Cohort, useCohort } from "../../hooks/useCohorts";
import { $isMetricNode, MetricNode } from "../../lexical/MetricNode";
import { useMetrics } from "../../hooks/useMetrics";

interface ChatBottomPanelProps {
    parentRef: React.RefObject<HTMLFormElement>;
    panelName: string;
    setPanelName: (s: string) => void;
}

// TODO: this is so hacky
const sorted = (items: any[]) => {
    const sorted = items.map(item => item);
    sorted.sort((a, b) => {
        return "name" in a ? a.name.localeCompare(b.name) : a.metric_name.localeCompare(b.metric_name);
    });
    return sorted;
}

export const groupByLetter = (items: any[]) => {
    const grouped : {[key: string]: any[]} = {};
    items.forEach(item => {
        if ("name" in item && item.name.length > 0) {
            const key : string = item.name[0].toUpperCase();
            if (key in grouped) {
                grouped[key] = [...grouped[key], item];
            } else {
                grouped[key] = [item];
            }
        } else {
            const key : string = item.metric_name[0].toUpperCase();
            if (key in grouped) {
                grouped[key] = [...grouped[key], item];
            } else {
                grouped[key] = [item];
            }
        }
    });
    const _sorted : {[key: string]: any[]} = {};
    Object.keys(grouped).sort((a, b) => {
        return a.localeCompare(b);
    }).forEach(key => {
        _sorted[key] = sorted(grouped[key]);
    });
    return _sorted;
}

const ChatBottomPanel: React.FC<ChatBottomPanelProps> = ({parentRef, panelName, setPanelName}) => {
    const panelRef = useRef<HTMLDivElement>(null);
    const [panelRect, setPanelRect] = useState<DOMRect | null>(null);
    const [panelType, setPanelType] = useState("Metrics");

    // states used by concept panel
    const {concepts} = useConcept();
    const [editor] = useLexicalComposerContext();
    const {library} = useLibrary();

    const [selectedConcept, setSelectedConcept] = useState<Concept | null>(null);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute | null>(null);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const [attributes, setAttributes] = useState<Attribute[]>([]);
    const [values, setValues] = useState<string[]>([]);
    const [conceptLevel, setConceptLevel] = useState(0);

    // states used by group panel
    const {cohorts} = useCohort();
    const [selectedCohort, setSelectedCohort] = useState<Cohort | null> (null);

    // state used by metrics panel
    const {metrics} = useMetrics();
    const [selectedMetric, setSelectedMetric] = useState<Metric | null>(null);

    useEffect(() => {
        if (panelRef.current !== null) {
            setPanelRect(panelRef.current.getBoundingClientRect());
        } else {
            setPanelRect(null);
        }
    }, [panelName, panelRef]);

    const resetConceptLocalValues = () => {
        setSelectedConcept(null);
        setAttributes([]);
        setSelectedAttribute(null);
        setValues([]);
        setSelectedValues([]);
        setConceptLevel(0);
    }

    useEffect(() => {
        resetConceptLocalValues();
    }, [library]);

    useEffect(() => {
        const updateSelection = () => {
            const selection = $getSelection();
            const nodes = selection?.getNodes();

            if (nodes !== undefined && nodes.length === 1 && $isConceptNode(nodes[0])) {
                setPanelType("Concepts")
                const mouseConceptProps = parseConceptNode(nodes[0]);
                if (mouseConceptProps === undefined) {
                    resetConceptLocalValues();
                    return;
                }
                const newConcept = concepts.find(concept => concept.id === mouseConceptProps.concept.id);
                if (newConcept === undefined) {
                    resetConceptLocalValues();
                    return;
                }
                setSelectedConcept(newConcept);
                setAttributes(newConcept.attributes);
                if (mouseConceptProps.conceptLevel === 0) {
                    setSelectedAttribute(null);
                    setConceptLevel(1);
                    setValues([]);
                    setSelectedValues([]);
                    return;
                }
                const newAttribute = newConcept.attributes.find(attribute => attribute.id === mouseConceptProps.attribute.id);
                if (newAttribute === undefined) {
                    setSelectedAttribute(null);
                    setConceptLevel(1);
                    setValues([]);
                    setSelectedValues([]);
                    return;
                }
                if (mouseConceptProps.conceptLevel === 1) {
                    setSelectedAttribute(newAttribute);
                    setConceptLevel(2);
                    setValues([]);
                    setSelectedValues([]);
                    api.fetchAttributeValues(newAttribute.id).then(values => {
                        setValues(values);
                    });
                    return;
                }
                setSelectedAttribute(oldAttribute => {
                    if (oldAttribute === null || oldAttribute.id !== newAttribute.id) {
                        setValues([]);
                        setSelectedValues([]);
                        setConceptLevel(2);
                        api.fetchAttributeValues(newAttribute.id).then(values => {
                            setValues(values);
                            setSelectedValues(mouseConceptProps.values);
                        });
                    } else {
                        setSelectedValues(mouseConceptProps.values);
                        setConceptLevel(2);
                    }
                    return newAttribute;
                })
            } else {
                resetConceptLocalValues();
            }
            if (nodes !== undefined && nodes.length === 1 && $isCohortNode(nodes[0])){
                const cohortNode = nodes[0] as CohortNode;
                setPanelType("My Groups");
                setSelectedCohort(cohorts.find(cohort => cohort.id === cohortNode.__cohort.id) || null);
            } else {
                setSelectedCohort(null);
            }
            if (nodes !== undefined && nodes.length === 1 && $isMetricNode(nodes[0])){
                const metricNode = nodes[0] as MetricNode;
                setPanelType("Metrics")
                setSelectedMetric(metrics.find(metric => metric.metric_name === metricNode.__metric) || null);
            } else {
                setSelectedMetric(null);
            }
        }
        editor.update(updateSelection);
        return editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            updateSelection();
            return false;
        }, COMMAND_PRIORITY_HIGH);
    // eslint-disable-next-line
    }, []);

    return (
        <div 
            style={{
                bottom: `${(parentRef.current?.offsetHeight || 0)}px`
            }}
            className=" absolute left-0 w-full min-h-[310px] h-2/5 p-[20px] rounded-[15px] gap-[20px] mb-[8px] bg-white shadow-bottomPanel flex flex-row items-start overflow-visible"
            ref={panelRef}
        >
            {            
                panelRect !== null && <CloseButton onClose={() => setPanelName("none")} className="cursor-pointer absolute top-[-33px] right-[-33px]"/>
            }
            <div className="w-[100px] h-full flex flex-col bg-violet-50 rounded-l-[10px]">
                <div className={`flex-1 font-Inter ${panelType === "Metrics" ? "font-[700] bg-white border-l-2 border-violet-600 pr-[2px]" : "font-[500] px-[2px]"} text-[13px] text-violet-600 flex items-center justify-center cursor-pointer rounded-tl-[10px]`}
                    onClick={() => {
                        if (panelType === "Metrics") {
                            setPanelType("none");
                        } else {
                            setPanelType("Metrics")
                        }
                    }}
                >
                    <span>
                        {"Metrics"}
                    </span>
                </div>
                <div className={`flex-1 font-Inter ${panelType === "Concepts" ? "font-[700] bg-white border-l-2 border-violet-600 pr-[2px]" : "font-[500] px-[2px]"} text-[13px] text-violet-600 flex items-center justify-center cursor-pointer`}
                    onClick={() => {
                        if (panelType === "Concepts") {
                            setPanelType("none");
                        } else {
                            setPanelType("Concepts")
                        }
                    }}
                >
                    <span>
                        {"Concepts"}
                    </span>
                </div>
                <div className={`flex-1 font-Inter ${panelType === "My Groups" ? "font-[700] bg-white border-l-2 border-violet-600 pr-[2px]" : "font-[500] px-[2px]"} text-[13px] text-violet-600 flex items-center justify-center cursor-pointer rounded-bl-[10px]`}
                    onClick={() => {
                        if (panelType === "My Groups") {
                            setPanelType("none");
                        } else {
                            setPanelType("My Groups")
                        }
                    }}
                >
                    <span>
                        {"My Groups"}
                    </span>
                </div>
            </div>
            <div className="flex-1 flex flex-row h-full gap-[20px]">
                {panelType === "My Groups" && <CohortBottomPanel selectedCohort={selectedCohort} setSelectedCohort={setSelectedCohort}/>}
                {panelType === "Concepts" && <ConceptBottomPanel 
                    attributes={attributes} setAttributes={setAttributes} values={values} setValues={setValues}
                    selectedConcept={selectedConcept} setSelectedConcept={setSelectedConcept}
                    selectedAttribute={selectedAttribute} setSelectedAttribute={setSelectedAttribute}
                    selectedValues={selectedValues} setSelectedValues={setSelectedValues}
                    conceptLevel={conceptLevel} setConceptLevel={setConceptLevel}
                />}
                {panelType === "Metrics" && <MetricBottomPanel selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric}/>}
            </div>
        </div>
    );
};

export default ChatBottomPanel;