import React, { createContext, useState, useContext, useEffect } from "react";
import { StreamMessageStep } from "../@types/common";
import { format } from "date-fns";
import { strToDate } from "../helpers/datetimehelper";
import { usePin } from "./usePin";
import { useToast } from "./useToast";
import { useAuth } from "./useAuth";
import { useLibrary } from "./useLibrary";
import * as api from "../api";

export interface PinResultEntry {
  id: string;
  answer_text: string;
  query_text: string;
  response: StreamMessageStep;
  run_date: string;
  status: string;
}

type PinResultType = {[key: string]: PinResultEntry};

interface PinResultContextData {
  pinResult: PinResultType;
  addPinResult: (pinResult: PinResultEntry) => void;
  setPinResult: (results: PinResultType) => void;
}

const PinResultContext = createContext<PinResultContextData | undefined>(undefined);

const usePinResult = () => {
  const context = useContext(PinResultContext);
  if (!context) {
    throw new Error("useFavorite must be used within an FavoriteProvider");
  }
  return context;
};

const PinResultProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pinResult, setPinResult] = useState<PinResultType>({});
  const {pin} = usePin();
  const toast = useToast();
  const {userProfile, isAuthenticated} = useAuth();
  const {library} = useLibrary();


  const allPinHasResult = () => {
    // reduce<U>(callbackfn: (previousValue: U, currentValue: PinEntry, currentIndex: number, array: PinEntry[]) => U, initialValue: U): U
    return pin.reduce((prevHasResult, entry) => {
      return prevHasResult && (entry.id in pinResult);
    }, true);
  }

  useEffect(() => {
    const fetchData = async () => {
      let date = new Date();
      const fetchData = async () => {
        const responseResult = await api.fetchUserPinResult(userProfile!.email, library?.id || 0, date);
        console.log("fetching for date");
        console.log(date);
        console.log(responseResult);
        responseResult.map((entry: PinResultEntry) => addPinResult(entry));
      }
      let i = 0;
      while (!allPinHasResult() && i < 3) {
        await fetchData().catch((error) => {
          toast.error(error.message);
        });
        date.setDate(date.getDate() - 1);
        i = i + 1;
      }
    }

    if (isAuthenticated) {
      fetchData();
    }
  }, [pin]);

  const addPinResult = (newPinResult: PinResultEntry) => {
    setPinResult((pins) => {
        let newPins: PinResultType = {}
        Object.keys(pins).map((id) => {
            if (id === newPinResult.id) {
                newPins[id] = strToDate(pins[id].run_date) > strToDate(newPinResult.run_date) ? pins[id] : newPinResult;
            } else {
                newPins[id] = pins[id];
            }
        })
        if (! (newPinResult.id in pins)) {
            newPins[newPinResult.id] = newPinResult;
        }
        return newPins;
    });
  };

  return (
    <PinResultContext.Provider value={{ pinResult, addPinResult, setPinResult }}>
      {children}
    </PinResultContext.Provider>
  );
};

export { PinResultProvider, usePinResult };
