import React, { useEffect, useRef } from "react";
import { useHistory } from "../../hooks/useHistory";
import { SessionEntry } from "../../hooks/useSession"
import { ReactComponent as ExpandDown } from "../../svg/session_panel/expand_button_down.svg";
import { ReactComponent as ExpandUp } from "../../svg/session_panel/expand_button_up.svg";
import { ReactComponent as ThreeDots } from "../../svg/session_panel/three_dots.svg";
import { ReactComponent as OptionShare } from "../../svg/session_panel/option_share.svg";
import { ReactComponent as OptionDelete } from "../../svg/session_panel/option_delete.svg";
import { ReactComponent as OptionRename } from "../../svg/session_panel/option_rename.svg";
import HistoryEntryDiv from "./HistoryEntry";
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';
import { useChatbox } from "../../hooks/useChatbox";
import { useAuth } from "../../hooks/useAuth";
import { useSession } from "../../hooks/useSession";
import { createPortal } from "react-dom";
import { SimpleToolTipId } from "../ToolTip/SimpleToolTip";
import { useLibrary } from "../../hooks/useLibrary";
import { useToast } from "../../hooks/useToast";

interface SessionEntryDivProps {
    session: SessionEntry;
    onShare: () => void;
}

interface SessionOptionProps {
    dotsRef: React.RefObject<SVGSVGElement>;
    onShare: () => void;
    onRename: () => void;
    onDelete: () => void;
    closeOption: () => void;
}

const SessionOptionMenu: React.FC<SessionOptionProps> = ({dotsRef, onShare, onRename, onDelete, closeOption}) => {
    const leftPos = dotsRef.current?.getBoundingClientRect().right || 0;
    const topPos = dotsRef.current?.getBoundingClientRect().top || 0;
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeOption();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <div className="flex flex-col px-[10px] py-[8px] gap-[12px] w-fit h-fit bg-[#4D3890] rounded-[5px] fixed z-[6] select-none"
            style={{
                top: `${topPos}px`,
                left: `${leftPos}px`,
            }}
            onClick={(e) => {closeOption(); e.stopPropagation();}}
            ref={menuRef}
        >
            <div className="flex flex-row w-fit h-fit gap-[10px] justify-start items-center cursor-pointer"
                onClick={onShare}
            >
                <OptionShare/>
                <span className="font-Inter font-[400] text-white text-[12px]">
                    Share
                </span>
            </div>
            <div className="flex flex-row w-fit h-fit gap-[10px] justify-start items-center cursor-pointer"
                onClick={onRename}
            >
                <OptionRename/>
                <span className="font-Inter font-[400] text-white text-[12px]">
                    Rename
                </span>
            </div>
            <div className="flex flex-row w-fit h-fit gap-[10px] justify-start items-center cursor-pointer"
                onClick={onDelete}
            >
                <OptionDelete/>
                <span className="font-Inter font-[400] text-white text-[12px]">
                    Delete Session
                </span>
            </div>
        </div>
    );
}

const SessionEntryDiv: React.FC<SessionEntryDivProps> = ({session, onShare}) => {
    const { history, removeFavoriteId, addFavorite } = useHistory();
    const [expanded, setExpanded] = React.useState(false);
    const { setInput, inputRef } = useChatbox();
    const { userProfile, checkEditPrivilege } = useAuth();
    const [ editingName, setEditingName ] = React.useState(session.newly_created === true);
    const [ inputValue, setInputValue ] = React.useState(session.name);
    const { currentSessionId, setCurrentSessionId, updateSessionName, deleteSession } = useSession();
    const [ showMenu, setShowMenu ] = React.useState(false);
    const dotsRef = useRef<SVGSVGElement>(null);
    const historyBodyRef = useRef<HTMLDivElement>(null);
    const sessionTitleRef = useRef<HTMLInputElement>(null);
    const {library} = useLibrary();
    const toast = useToast();

    React.useEffect(() => {
        if (currentSessionId !== session.id) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    }, [currentSessionId]);

    const setActiveSession = () => {
        setCurrentSessionId(session.id);
    };

    const rootElement = document.getElementById("root");

    // if (session.newly_created === true) {
    //     setInputValue(session.name);
    //     setEditingName(true);
    // }

    // React.useEffect(() => {
    //     if (session.newly_created === true) {
    //         sessionTitleRef.current?.select();
    //     }
    // }, [sessionTitleRef])

    return (
        <>
            <div className={`h-[40px] pl-[18px] pr-[15px] py-[8px] flex flex-row items-center justify-between cursor-pointer sticky top-[0px] z-[2] bg-white border-t-[1px] ${currentSessionId === session.id && expanded ? 'border-[#6E43F8] border-t-[2px]' : 'border-[#DBDCDE]'}`}
                onClick = {
                    () => {
                        setActiveSession();
                        setExpanded(!expanded);
                    }}
            >
                {editingName ? 
                    <input 
                        className={`font-Inter text-[12px] font-[500] text-[#412B88] text-left outline-none w-full`} 
                        autoFocus={true} 
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onFocus={e => {
                            e.target.select();
                        }}
                        onBlur={e => {
                            setEditingName(false);
                            updateSessionName(session.id, inputValue);
                        }}
                        onKeyDown={(keyEvent) => {
                            if (keyEvent.key === "Enter") {
                                setEditingName(false);
                                updateSessionName(session.id, inputValue);
                            } else if (keyEvent.key === "Escape") {
                                setEditingName(false);
                            }
                        }}
                        onClick = {(e) => {
                            e.stopPropagation();
                        }}
                        ref = {sessionTitleRef}
                    /> : 
                    <span className={`font-Inter text-[12px] font-[500] ${currentSessionId === session.id && expanded && "cursor-text"} ${currentSessionId === session.id && "font-[700]"} text-[#412B88] text-left whitespace-nowrap text-ellipsis overflow-hidden`}
                        onClick={(e) => {
                            if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                                toast.warning("You do not have edit access to this page");
                                return;
                            }
                            if (expanded) {
                                setInputValue(session.name);
                                setEditingName(true);
                                e.stopPropagation();
                            }
                        }}
                    >
                        {session.name}
                    </span>
                }
                <div className={`cursor-pointer h-fit min-h-[19px] min-w-[50px] items-center justify-start flex`}>
                    {
                        rootElement && showMenu && createPortal(
                            <SessionOptionMenu 
                                dotsRef={dotsRef} 
                                onShare={() => {
                                    if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                                        toast.warning("You do not have edit access to this page");
                                        return;
                                    }
                                    onShare();
                                }}
                                onRename={() => {
                                    if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                                        toast.warning("You do not have edit access to this page");
                                        return;
                                    }
                                    setInputValue(session.name);
                                    setEditingName(true);
                                }}
                                onDelete={() => {
                                    if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                                        toast.warning("You do not have edit access to this page");
                                        return;
                                    }
                                    deleteSession(session.id);
                                }}
                                closeOption={() => setShowMenu(false)
                            }/>, rootElement
                        )
                    }
                    {currentSessionId === session.id && expanded ? 
                        <>
                            <ExpandUp className="outline-none"/>
                            <ThreeDots ref={dotsRef} 
                                onClick={(e) => {
                                    setShowMenu(!showMenu);
                                    e.stopPropagation();
                                }}
                                className="outline-none"
                            />
                        </> : 
                        <ExpandDown className={`outline-none ${currentSessionId !== session.id && "opacity-[0.5]"}`}/>
                    }
                </div>
            </div>
            {/* ${(currentSessionId === session.id && expanded) ? "h-fit" : "h-0"}  */}
            <div className={`transition-all ease-in-out duration-300 overflow-hidden ${expanded && 'mt-[10px]'}`}
                style={{
                    height: (currentSessionId === session.id && expanded) ? `${historyBodyRef.current?.clientHeight}px` : `0px`
                }}
            >
                <div className="w-full h-fit flex flex-col border-b-[#DBDCDE] border-b-[1px] min-h-[50px]" ref={historyBodyRef}>
                    {history.filter((historyEntry) => {return historyEntry.session_id === session.id}).map((historyEntry, index) => {
                        return <div key={historyEntry.historyid}>
                            <HistoryEntryDiv 
                                historyEntry={historyEntry}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setInput(historyEntry.message);
                                    inputRef.current?.focus();
                                    ReactGA.event({ // track retry click
                                    category: 'User',
                                    action: 'Clicked on retry',
                                    label: historyEntry.message,
                                    });
                                    mixpanel.track('Retry Clicked', {
                                    'entry': historyEntry.message,
                                    'status': historyEntry.status,
                                    'username': userProfile!.email,
                                    });
                                }}
                                onFavorite={() => {
                                    if (historyEntry.favorite === "Y") {
                                        removeFavoriteId(historyEntry?.historyid || "");
                                    } else {
                                        addFavorite({
                                            id: historyEntry.id,
                                            message: historyEntry.message,
                                            favorite: "Y",
                                            historyid: historyEntry.historyid,
                                        });
                                    }
                                }}
                            />
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}
export default SessionEntryDiv;