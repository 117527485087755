import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "./useAuth";
import { useToast } from "./useToast";
import { useLibrary } from "./useLibrary";
import * as api from "../api";

export interface PinEntry {
  id: string;
  name: string;
  query_text: string;
  create_date: string;
  response?: string;
  status?: string;
}

interface PinContextData {
  pin: PinEntry[];
  addPin: (pin: PinEntry) => void;
  removePinId: (id: string) => void;
}

const PinContext = createContext<PinContextData | undefined>(undefined);

const usePin = () => {
  const context = useContext(PinContext);
  if (!context) {
    throw new Error("useFavorite must be used within an FavoriteProvider");
  }
  return context;
};

const PinProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pin, setPin] = useState<PinEntry[]>([]);
  const {userProfile, isAuthenticated} = useAuth();
  const toast = useToast();
  const {library} = useLibrary();


  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching pin");
      const responsePin = await api.fetchUserPin(userProfile!.email, library?.id || 0);
      console.log(responsePin);
      setPin(responsePin.reverse());
    }
    if (isAuthenticated) {
      fetchData()
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setPin([]);
    }
  }, [library]);

  const addPin = (newPin: PinEntry) => {
    const oldPin = pin.map(p => p);
    setPin(pins => [...pins, newPin]);
    api.addPin(newPin.query_text, library!.id, userProfile!.email).then((pin_id) => {
      setPin(pin.map(p => p.id === "" ? {...p, pin_id} : p));
    }).catch(e => {
      console.error(e);
      toast.error(e.message);
      setPin(oldPin);
    });
  };

  const removePinId = (id: string) => {
    const oldPin = pin.map(p => p);
    setPin(pinList => {
        const index = pinList.findIndex((entry) => {return entry.id === id});
        if (index !== -1) {
            return [...pinList.slice(0, index), ...pinList.slice(index + 1)];
        }
        return [...pinList];
    });
    api.removePin(id).catch((error) => {
      toast.error(error.message);
      console.error(error);
      setPin(oldPin);
    })
  }

  return (
    <PinContext.Provider value={{ pin, addPin, removePinId }}>
      {children}
    </PinContext.Provider>
  );
};

export { PinProvider, usePin };
