import React from "react";
import { Cohort, useCohort } from "../../hooks/useCohorts";
import { useConcept } from "../../hooks/useConcepts";
import { closeTooltip } from "../ToolTip/ToolTipHelpers";
import { spawnCohortTooltip } from "../ToolTip/CohortToolTip";
import { parseFilter } from "../SearchBar";
import * as api from "../../api";

interface CohortEntryProps {
    isSelected: boolean;
    cohort: Cohort;
    index?: number;
    filter?: string;
    onClick: () => void;
}

export const CohortEntry: React.FC<CohortEntryProps> = ({isSelected, cohort, index, filter="", onClick}) => {
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
    const {concepts} = useConcept();

    const handleLeave = (e: any) => {
        if (tooltipRef.current !== null && !tooltipRef.current.contains(e.relatedTarget)) {
            closeTooltip(tooltipRef.current);
            tooltipRef.current = null;
        }
    };

    const handleOver = (e: any, _cohort: string, concept: string, attribute: string, values: string[]) => {
        if (tooltipRef.current === null) {
            tooltipRef.current = spawnCohortTooltip(e, cohort, concept, attribute, values, true, arrowRef);
            e.stopPropagation();
        }
    }

    return (
        <div 
            className={`flex flex-row items-center h-[22px] w-fit rounded-[10px] border-[1px] ${isSelected ? "border-[#6E43F8]" : "border-[#E5DDFD]"} px-[8px] py-[3px] ${isSelected ? "bg-[#6E43F8]" : "bg-[#F3F4F6] hover:bg-[#EEE8FF]"} cursor-pointer whitespace-pre select-none`}
            onClick={(e) => {
                onClick();
            }}
            // onMouseEnter={(e) => {
            //     setTimeoutId(setTimeout(() => {
            //         const concept = concepts.find(concept => concept.attributes.find(attribute => attribute.id === cohort.attribute_id) !== undefined);
            //         if (concept !== undefined) {
            //             const attribute = concept.attributes.find(attribute => attribute.id === cohort.attribute_id);
            //             handleOver(e, cohort.name, concept.name, attribute?.name || "", cohort.attribute_values);
            //         }
            //     }, 500));
            // }}
            // onMouseLeave={(e) => {
            //     if (timeoutId !== null) {
            //         clearTimeout(timeoutId);
            //         setTimeoutId(null);
            //     }
            //     handleLeave(e);
            // }}
            key={`_cohort_${index}_${cohort.id}`}
            id={`_cohort_${index}`}
        >
            {parseFilter(cohort.name, filter).map((chunk, index) => {
                return chunk.type === "text" ? 
                <span 
                    className={`font-Inter text-[13px] font-[500] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_cohort_${index}_${cohort.id}_text`}
                    >
                    {chunk.content}
                </span> : 
                <span className={`font-Inter text-[13px] font-[700] text-left align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`} key={`_cohort_${index}_${cohort.id}_text`}>
                    {chunk.content}
                </span>
            })}
            
        </div>
    )
}
