import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";
import { spawnMetricTooltip } from "../components/ToolTip/MetricToolTip";
import { closeTooltip } from "../components/ToolTip/ToolTipHelpers";

export type SerializedMetricNode = Spread<
  {
    metric: string;
    status: string;
    item: string;
    description: string;
    bl: string;
  },
  SerializedTextNode
>;

class MetricNode extends TextNode {
  __metric: string;
  __status: string;
  __item: string;
  __description: string;
  __bl: string;
  __popup: {
    current: HTMLDivElement | null;
  };
  __popupArrow: {
    current: SVGSVGElement | null;
  }

  static getType(): string {
    return "metric";
  }

  static clone(node: MetricNode): MetricNode {
    return new MetricNode(node.__metric, node.__status, node.__type, node.__description, node.__bl, node.__text, node.__key);
  }

  constructor(metric: string, status: string, item: string, description: string, bl: string, text?: string, key?: string) {
    const assembledText = `[${metric}]`
    super(text ?? assembledText, key);
    this.__metric = metric;
    this.__status = status;
    this.__item = item;
    this.__description = description;
    this.__bl = bl;
    this.__popup = {current: null};
    this.__popupArrow = {current: null};
  }

  handleHover = (e : any) => {
    if (this.__popup.current === null) {
      this.__popup.current = spawnMetricTooltip(e, this.__metric, this.__description, this.__bl, this.__popupArrow);
      e.stopPropagation();
    }
  }

  handleLeave = () => {
    if (this.__popup.current !== null) {
      closeTooltip(this.__popup.current);
      this.__popup.current = null;
    }
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.addEventListener("mouseleave", this.handleLeave);
    dom.addEventListener("mouseenter", this.handleHover);

    dom.style.font = "Inter";
    dom.style.fontStyle = "normal";
    dom.style.fontWeight = "500";
    dom.style.fontSize = "13px";
    dom.style.lineHeight = "16px";
    dom.style.color = "#6E43F8";
    dom.style.background = "#F3F4F6";
    dom.style.border = "1px solid #E5DDFD";
    dom.style.borderRadius = "10px";
    dom.style.height = "fit";
    dom.style.width = "fit";
    dom.style.padding = "3px 8px"

    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-metric", "true");
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-metric")) {
          return null;
        }
        return {
          conversion: convertMetricElement,
          priority: 1,
        };
      },
    };
  }

  static importJSON(serializedNode: SerializedMetricNode): MetricNode {
    const node = $createMetricNode(serializedNode.metric, serializedNode.status, serializedNode.item, serializedNode.description, serializedNode.bl);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedMetricNode {
    return {
      ...super.exportJSON(),
      metric: this.__metric,
      status: this.__status,
      item: this.__item,
      description: this.__description,
      bl: this.__bl,
      type: MetricNode.getType(),
      version: 1,
    };
  }

  isTextEntity(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

function convertMetricElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  if (textContent !== null) {
    return {
      node: $createMetricNode(textContent, "", "", "", ""),
    };
  }
  return null;
}

function $createMetricNode(metric: string, status: string, type: string, description: string, bl: string): MetricNode {
  const metricNode = new MetricNode(metric, status, type, description, bl);
  metricNode.setMode("token").toggleDirectionless();
  return $applyNodeReplacement(metricNode);
}

function $isMetricNode(
  node: LexicalNode | null | undefined
): node is MetricNode {
  return node instanceof MetricNode;
}

export {
  MetricNode,
  $createMetricNode,
  $isMetricNode,
};