import React, { createContext, useState, useContext, useEffect } from "react";
import { useLibrary } from "./useLibrary";
import * as api from "../api";
import { useAuth } from "./useAuth";
import { useToast } from "./useToast";

export interface Cohort {
  id: number;
  name: string;
  library_id: number;
  attribute_id: number;
  user_email: string;
  attribute_values: string[];
}

interface CohortContextData {
  cohorts: Cohort[];
  setCohorts: (pin: Cohort[]) => void;
  addCohort: (cohort: Cohort) => Promise<void>;
  removeCohortId: (id: number) => Promise<void>;
}

const CohortContext = createContext<CohortContextData | undefined>(undefined);

const useCohort = () => {
  const context = useContext(CohortContext);
  if (!context) {
    throw new Error("useCohort must be used within an CohortProvider");
  }
  return context;
};

const CohortProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cohorts, setCohorts] = useState<Cohort[]>([]);

  const toast = useToast();
  const {library} = useLibrary();
  const {userProfile, isAuthenticated} = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching cohorts");
      const responseCohort = await api.fetchUserCohort(userProfile!.email, library?.id || 0);
      setCohorts(responseCohort.map(cohort => {
        return {
          ...cohort,
          name: cohort.name.replace("@", ""),
        }
      }));
    }
    if (isAuthenticated && library !== null) {
      fetchData()
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    } else {
      setCohorts([]);
    }
  }, [library]);

  const addCohort = (newCohort: Cohort) => {
    return new Promise<void>((res, rej) => {
      const oldCohorts = cohorts.map(c => c);
      // first check newCohort name against existing ones
      const hasUniqueName = cohorts.every((cohort)=>{
        return (cohort.name !== newCohort.name || cohort.id === newCohort.id);
      });
      if (!hasUniqueName) {
        rej("duplicate_name");
      };

      if (newCohort.id < 0) {
        // this is a brandnew cohort
        setCohorts([...cohorts, newCohort]);
        api.saveUserCohort(newCohort.name, newCohort.library_id, newCohort.user_email, newCohort.attribute_id, newCohort.attribute_values).then(id => {
          newCohort.id = id;
          setCohorts(cohorts => {
            const newCohorts:Cohort[] = [];
            cohorts.forEach(cohort => {
              newCohorts.push(cohort.id === id ? newCohort : cohort);
            });
            if (newCohorts.find(cohort => cohort.id === newCohort.id) === undefined) {
              newCohorts.push(newCohort);
            }
            return newCohorts;
          });
          res();
        }).catch(error => {
          console.error(error);
          toast.error(error.message);
          setCohorts(oldCohorts);
          rej(error);
        });
      } else {
        setCohorts(cohorts => {
          return cohorts.map(cohort => cohort.id === newCohort.id ? newCohort : cohort);
        });
        api.updateUserCohort(newCohort.id, newCohort.name, newCohort.attribute_id, newCohort.attribute_values).catch((error) => {
          console.error(error);
          toast.error(error.message);
          setCohorts(oldCohorts);
          rej(error);
        });
        res();
      }
    })
  };

  const removeCohortId = (id: number) => {
    return new Promise<void>((res, rej) => {
      const oldCohorts = cohorts.map(c => c);
      setCohorts(cohortList => {
          return cohortList.filter(cohort => cohort.id !== id);
      });
      api.deleteUserCohort(id).then(() => res()).catch(error => {
        console.error(error);
        toast.error(error.message);
        setCohorts(oldCohorts);
        rej(error);
      });
    })
  }

  return (
    <CohortContext.Provider value={{ cohorts, setCohorts, addCohort, removeCohortId }}>
      {children}
    </CohortContext.Provider>
  );
};

export { CohortProvider, useCohort };
