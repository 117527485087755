import { useAuth } from "../../hooks/useAuth";
import { FavoriteEntry } from "../../hooks/useHistory"
import { useLibrary } from "../../hooks/useLibrary";
import { useToast } from "../../hooks/useToast";
import FavoriteButton from "../FavoriteButton";
import PinButton from "../PinButton";


interface FavoriteEntryDivProps {
    favoriteEntry: FavoriteEntry;
    favoriteIndex?: number;
    onClick?: (e: React.MouseEvent) => void;
    onFavorite?: () => void;
    isPinned?: () => boolean;
    onPin?: () => void;
}

const FavoriteEntryDiv: React.FC<FavoriteEntryDivProps> = ({favoriteEntry, favoriteIndex, onClick, onFavorite=() => {}, isPinned=() => false, onPin=() => {}}) => {

    const {checkEditPrivilege} = useAuth();
    const {library} = useLibrary();
    const toast = useToast();

    return (
        <div className="flex flex-row pl-[10px] pr-[2px] py-[2px]"
            id={`_favorite_${favoriteIndex}`}
        >
            <div className={`h-full w-[100px] flex-1 border-[1px] px-[8px] py-[6px] rounded-[11px] 
                border-[#6E43F8] bg-white hover:bg-[#6E43F8]
                cursor-pointer font-Inter text-[12px] font-[500] text-left text-[#010616] hover:text-white 
                break-words`} 
                onClick={onClick}
            >
                {favoriteEntry.message}
            </div>
            <div className="h-fit w-fit flex flex-col gap-[4px]">
                <FavoriteButton className="cursor-pointer" isFav={() => {return favoriteEntry.favorite === "Y"}} onClick={() => {
                    if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                        toast.warning("You do not have edit access to this page");
                        return;
                    }
                    onFavorite();
                }}/>
            </div>
        </div>
    )
}


export default FavoriteEntryDiv;