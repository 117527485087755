import React, { createContext, useState, useContext, useRef } from "react";

interface ChatboxContextData {
  inputText: string;
  setInput: (input: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const ChatboxContext = createContext<ChatboxContextData | undefined>(undefined);

const useChatbox = () => {
  const context = useContext(ChatboxContext);
  if (!context) {
    throw new Error("useChatbox must be used within an ChatboxProvider");
  }
  return context;
};

const ChatboxProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [inputText, setInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <ChatboxContext.Provider value={{ inputText, setInput, inputRef }}>
      {children}
    </ChatboxContext.Provider>
  );
};

export { ChatboxProvider, useChatbox };
