import React, { useState, useEffect } from 'react';
import { useConcept } from '../hooks/useConcepts';
import { Page, useAuth } from '../hooks/useAuth';
import { useLibrary } from '../hooks/useLibrary';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import Buttons from "./Buttons";
import CodeContainer from './EditableCodeContainer';
import TableContainer from "./TableContainer";
import Loader from './Loader';
import AttributeList from './AttributeList';
import { Attribute, Concept, Item } from "../@types/common";
import { useToast } from "../hooks/useToast"
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg"
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";

interface ConceptPanelProps {
    readOnly?: boolean;
    page: Page;
    currentConcept: Concept | null;
    setCurrentConcept: (concept: Concept | null) => void;
}

const ConceptPanel: React.FC<ConceptPanelProps> = ({ readOnly = false, page, currentConcept, setCurrentConcept }) => {
    const {concepts, addConcept} = useConcept();
    const [status, setStatus] = useState<string>("empty");
    const [id, setId] = useState<number>(-1);
    const [sql, setSQL] = useState<string>("");
    const [editor] = useLexicalComposerContext();
    const [executionResult, setExecutionResult] = useState<string | null>(null);
    const [tableHead, setTableHead] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [quotedTexts, setQuotedTexts] = useState<string[]>([]);
    const [params, setParams] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute | null>(null);
    const toast = useToast();
    const { userProfile, checkEditPrivilege } = useAuth();
    const { library } = useLibrary();

    const isDisabled = (verified: boolean) => readOnly || verified;

    const handleAdd = (type: string) => {
        if (currentConcept) {
            let newItem : Concept = { ...currentConcept };
            if (type === "attribute") {
                const newAttribute: Attribute = { id: id, name: 'New attribute', desc: '', mappers: [], linker: '', data_connection: '' };
                newItem.attributes = [...(newItem.attributes || []), newAttribute];
                setSelectedAttribute(newAttribute);
                setId(id - 1);
            }
            setCurrentConcept(newItem);
        }
    };

    const executeSQL = async () => {
        // setLoading(true);
        const response = await fetch(`/api/execute_sql?email=${userProfile?.email}&library_id=${library?.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "sql_statement": currentConcept?.sql || "",
            }),
        });

        const responseData = await response.json();
        console.log(responseData)
        if (responseData.message == "failed") {
            console.log("executeSQL failed");
            toast.error("Execute SQL Failed");
        } else {
            // setExecutionResult(JSON.stringify(responseData, null, 2));
            setTableHead(responseData.column_names);
            setTableData(responseData.records);
            setLoading(false);
        }
        console.log("Hello???")
    };

    const getButtonClass = (buttonType: string) => {
        return buttonState(buttonType)
            ? "rounded-md border border-black px-2 w-[120px] h-[50px] bg-gray-100"
            : "rounded-md border border-gray-300 px-2 w-[120px] h-[50px] text-gray-300";
    };

    const buttonState = (buttonType: string) => {
        if (buttonType === 'Delete') return true;
        if (buttonType === 'VerifiedState') return true;
        if (buttonType === 'Verified') return currentConcept?.status !== 'Verified';

        const curItem = concepts.filter(it => it.id === currentConcept?.id && it.type === currentConcept.type)[0];

        if (buttonType === 'Generate') return curItem.logic !== currentConcept?.logic;
        if (buttonType === 'Execute') return currentConcept?.sql !== "";
        if (buttonType === 'Duplicate') return currentConcept?.id !== -1;
        return curItem !== currentConcept;
    };

    const handleButtonClick = async (buttonType: string) => {
        if (buttonType === "Dismiss") {
            const orig = concepts.filter(it => it.id === currentConcept?.id && it.type === currentConcept.type)[0];
            setCurrentConcept(orig);
            toast.success("Dismissed edits!");
            return;
        }

        if (buttonType === "Duplicate") {
            if (currentConcept) {
                const copyItem = { ...currentConcept, name: "Copy of " + currentConcept.name };
                addConcept(copyItem);
            }
            return;
        }

        let status = "";
        if (buttonType === "Delete") status = "Deprecated";
        if (buttonType === "Draft") status = "Draft";
        if (buttonType === "Verified") {
            status = currentConcept?.status !== "Verified" ? "Verified" : "Draft";
        }

        let newConcept = {
            ...currentConcept,
            logic: editor.getRootElement()?.textContent || "",
            status,
        };

        const typeName = 'concept';
        const data = {
            [`${typeName}_id`]: newConcept.id,
            [`${typeName}_name`]: newConcept.name,
            "category_name": newConcept.category || "",
            [`${typeName}_desc`]: newConcept.desc || "",
            "sql_statement": newConcept.sql || "",
            "modified_sql": newConcept.modified_sql || "N",
            "logic": newConcept.logic || "",
            "input_fields": newConcept.input_fields || "",
            "output_fields": newConcept.output_fields || "",
            "formula": newConcept.formular || "",
            "status": newConcept.status,
            "deprecated": status === "Deprecated" ? "D" : (newConcept.deprecated || ""),
            "attributes": newConcept.attributes || [],
        };
        console.log(newConcept)

        const response = await fetch(`/api/save_${typeName}?email=${userProfile?.email}&library_id=${library?.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();
        console.log(responseData);
        if (responseData.id) {
            newConcept.id = responseData.id;
        };

        // if (currentConcept) {
        //     if (buttonType === "Delete") {
        //         const updatedItems = concepts.filter((currentItem) => currentItem.id !== currentConcept.id || currentItem.type !== currentConcept.type);
        //         setItems(updatedItems);
        //         setCurrentConcept(null);
        //         toast.success("Concept deleted successfully!");
        //     } else {
        //         setCurrentConcept(newConcept);
        //         const updatedItems = items.map((currentItem) => (currentItem.id === item.id && currentItem.type === item.type) ? newItem : currentItem);
        //         setItems(updatedItems);
        //         toast.success("Saved successfully!");
        //     }
        // }
    };

    const parseSQL = async (sqlStatement: string) => {
        try {
            const response = await fetch(`/api/parse_sql?library_id=${library?.id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sql_statement: sqlStatement }),
            });

            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.message === "success" && currentConcept) {
                let idCounter = -1;
                const addId = (item: any) => {
                    item.id = idCounter;
                    idCounter--;
                    return item;
                };

                const newItem : Concept = {
                    ...currentConcept,
                    attributes: responseData.attributes.map(addId),
                    // filters: responseData.filters.map(addId),
                    // dimensions: responseData.dimensions.map(addId),
                };
                setCurrentConcept(newItem);
                toast.success("SQL parsed successfully!");
            } else {
                toast.error("Failed to parse SQL.");
            }
        } catch (error) {
            toast.error("Error occurred: " + error);
        }
    };

    if (!currentConcept) {
        return (
            <div className="pt-[56px] space-y-4 grow max-w-3xl flex flex-col items-center justify-center">
                <StartIcon className="w-150 h-150" />
                <div className='font-semibold text-center'>To get started, create a new concept or new metric,
                    or select an existing metric or concept to view and edit it</div>
                <div className='text-center'>Concepts are low level building blocks connecting directly to your data.</div>
                <div className='text-center'>Metrics string together Concepts and other Metrics describing more complex ideas that you want to measure.</div>
            </div>
        );
    }

    return (
        <div className="pt-[56px] space-y-4 grow max-w-3xl pt-10 text-left">
            <Buttons type={"Concept"} handleButtonClick={(buttontype) => {
                if (!checkEditPrivilege(page, library?.id || 0)) {
                    toast.warning("You do not have edit access to this page");
                    return;
                }
                handleButtonClick(buttontype);
            }} buttonState={buttonState} />
            <div className="flex items-center border border-black p-2">
                <label className="w-1/4">Concept Name:</label>
                <input className={`w-3/4 p-2 ${currentConcept.status === "Verified" && 'opacity-50'}`} type="text" placeholder="Enter name" value={currentConcept.name !== `Untitled ${currentConcept}` ? currentConcept.name : ''}
                    disabled={isDisabled(currentConcept.status === "Verified")}
                    onChange={(e) => { setCurrentConcept({ ...currentConcept, name: e.target.value }) }} />
            </div>
            <div className="flex items-center border border-black p-2">
                <label className="w-1/4">Summary Description:</label>
                <input className={`w-3/4 p-2 ${currentConcept.status === "Verified" && 'opacity-50'}`} type="text" value={currentConcept.desc || ''} placeholder="Enter description..."
                    disabled={isDisabled(currentConcept.status === "Verified")}
                    onChange={(e) => { setCurrentConcept({ ...currentConcept, desc: e.target.value }) }} />
            </div>
            <div className="border border-black p-4 mb-4">
                <div className="flex">
                    <div className="font-semibold mr-2">SQL:</div>
                    <div className="text-gray-600">Enter the SQL to retrieve the data related to this concept</div>
                </div>
                <CodeContainer code={currentConcept.sql || ""} status={status} isUserModified={currentConcept.modified_sql || ""}
                    handleValueChange={(newSql: string) => { setCurrentConcept({ ...currentConcept, sql: newSql, modified_sql: "Y" }) }}
                    disabled={isDisabled(currentConcept.status === "Verified")} />
                <button
                    onClick={e => {
                        if (!checkEditPrivilege(page, library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        parseSQL(currentConcept.sql || "");
                    }}
                    disabled={isDisabled(currentConcept.status === "Verified")}
                    className="border border-black p-2 rounded mr-2 mt-2">
                    Parse SQL
                </button>
                <button
                    onClick={() => {
                        if (!checkEditPrivilege(page, library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        executeSQL();
                    }}
                    disabled={isDisabled(currentConcept.status === "Verified")}
                    className="border border-black p-2 rounded mt-2">
                    Execute SQL
                </button>
            </div>
            {tableHead.length > 0 && <div className="w-full border-2 border-gray-300 p-2">
                {loading ? (
                    <Loader />
                ) : (
                    <TableContainer input='' sql='' head={tableHead} data={tableData} id='' />
                )}
            </div>}
            <div className="flex mb-4">
                <div className="border border-black p-4 w-full">
                    <div className="flex justify-between items-center mb-2">
                        <div className="font-semibold mr-2">Attributes:</div>
                        <div className="text-gray-600 text-sm">Identify the objects making up the concept and map them to their respective data fields</div>
                    </div>
                    <AttributeList items={currentConcept.attributes || []} item={currentConcept} setItem={(concept: Item) => {setCurrentConcept({... concept, type: 'Concept', attributes: currentConcept.attributes})}} disabled={isDisabled(currentConcept.status === "Verified")} />
                    <button
                        className="text-sm font-semibold"
                        onClick={() => {
                            if (!checkEditPrivilege(page, library?.id || 0)) {
                                toast.warning("You do not have edit access to this page");
                                return;
                            }
                            handleAdd("attribute");
                        }}
                        disabled={isDisabled(currentConcept.status === "Verified")}
                    >
                        + Add Attribute
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ConceptPanel;
