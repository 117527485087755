
import { ReactComponent as FilterIcon } from "../svg/chat_box/filter_icon.svg";
import { ReactComponent as FilterIconActve } from "../svg/chat_box/filter_icon_active.svg";
import { ReactComponent as FilterClear } from "../svg/chat_box/filter_clear_button.svg";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ReactComponent as SearchIcon } from "../svg/search_icon.svg";

interface SearchBarProps {
    isSearching: boolean;
    setIsSearching: (b: boolean) => void;
    filterValue: string;
    setFilterValue: (s: string) => void;
}

const escapeRegex = (s: string) => {
    return s.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const applyFilterValue = (filterValue: string) => (item: {name: string}) => {
    const regex = new RegExp(`.*${escapeRegex(filterValue)}.*`, "i");
    return regex.test(item.name);
}

export const applyFilterValueRaw = (filterValue: string) => (s: string) => {
    const regex = new RegExp(`.*${escapeRegex(filterValue)}.*`, "i");
    return regex.test(s);
}

export const parseFilter = (content: string, filter: string) => {
    if (filter === "") {
        return [{type: "text", content: content}];
    }
    const regex = new RegExp(escapeRegex(filter), "gi");
    const chunks: any[] = [];
    let match;
    let lastEnd = 0;

    while ((match = regex.exec(content)) !== null) {
        if (match.index > lastEnd) {
            chunks.push({ type: "text", content: content.substring(lastEnd, match.index) });
        }
        chunks.push({ type: "link", content: match[0] });
        lastEnd = match.index + match[0].length;
    }

    if (lastEnd < content.length) {
        chunks.push({ type: "text", content: content.substring(lastEnd) });
    }

    return chunks;
};

const SearchBar: React.FC<SearchBarProps> = ({isSearching, setIsSearching, filterValue, setFilterValue}) => {
    const [editor] = useLexicalComposerContext();
    return (
        <div className="h-fit w-fit bg-white border-violet-600 border-b-[1px] flex flex-row gap-[6px] px-[3px] items-center">
            <SearchIcon/>
            <input 
                className="font-Inter font-[400] text-[13px] text-[#010616] bg-white w-full border-0 outline-0" 
                defaultValue={filterValue}
                // autoFocus={true}
                onFocus={(e) => e.target.select()}
                onBlur={() => editor.focus()}
                onChange={(e) => setFilterValue(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        editor.focus();
                    }
                }}
            />
        </div>
    )
    return (
        isSearching ?
        <div className="w-full max-w-[417px] h-[35px] rounded-[8px] bg-white border-1 border-[#6E43F8] flex flex-row items-center justify-between py-[9px] px-[10px] gap-[10px] shadow-customSmall">
            <FilterIconActve/>
            <input 
                className="font-Inter font-[400] text-[13px] text-[#010616] bg-white w-full border-0 outline-0" 
                defaultValue={filterValue}
                autoFocus={true}
                onFocus={(e) => e.target.select()}
                onBlur={() => editor.focus()}
                onChange={(e) => setFilterValue(e.target.value)}
            />
            <FilterClear className="cursor-pointer" onClick={() => {
                setFilterValue("");
                setIsSearching(false);
            }}/>
        </div> :
        <div 
            className="w-fit h-[35px] rounded-[8px] bg-white border-1 border-[#CACCD2] py-[9px] px-[19px] gap-[6px] cursor-pointer flex flex-row items-center"
            onClick={() => {
                setIsSearching(true);
            }}
        >
            <FilterIcon/>
        </div>
    )
}

export default SearchBar