import React, { createContext, useState, useContext, useEffect } from "react";
import { Domain, Item, Metric, isDomain, isMetric } from "../@types/common"

import { useMetrics } from "./useMetrics";
import { useMetricDomain } from "./useMetricDomain";

type TreeItem = {
    data: Domain | Metric | "public_root" | "private_root";
    children: string[];
    parent: string | null;
    can_move: boolean;
    can_move_on: boolean;
}

type TreeItems = {
    public_root: TreeItem;
    private_root: TreeItem;
    [key: string]: TreeItem;
}

interface CustomTreeContextData {
    tree: TreeItems;
    focusedItem: string;
    setFocusedItem: (tree_id: string) => void;
    canMove: (tree_id: string, target_id: string) => boolean;
    moveTreeItem: (tree_id: string, target_id: string, sub_target_id?: string) => void;
    metricToTreeID: (metric_id: number) => string;
    domainToTreeID: (domain_id: number) => string;
    treeToMetricID: (tree_id: string) => number;
    treeToDomainID: (tree_id: string) => number;
    treeIDIsDomain: (tree_id: string) => boolean;
    isParent: (tree_id: string, target_id: string) => boolean;
    expandedTreeItem: {[key: string] : boolean};
    setExpandedTreeItem: (key: string, b: boolean) => void;
    calculateHeight: (tree_id: string) => number;
    publicMetricExpanded: boolean;
    setPublicMetricExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    privateMetricExpanded: boolean;
    setPrivateMetricExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    conceptExpanded: boolean;
    setConcpetExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomTreeContext = createContext<CustomTreeContextData | undefined>(undefined);

const useCustomTree = () => {
    const context = useContext(CustomTreeContext);
    if (!context) {
        throw new Error("useCustomTree must be used within a CustomTreeProvider");
    }
    return context;
};

const CustomTreeProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    // work flow: update metrics and domains triggers tree update
    // make everything into 1 unit, metric + domain + tree?
    const {metrics, currentItem} = useMetrics();
    const {domains} = useMetricDomain();
    const [tree, setTree] = useState<TreeItems>({
        public_root: {
            data: "public_root", 
            children: [],
            parent: null,
            can_move: false,
            can_move_on: false,
        } as TreeItem, 
        private_root: {
            data: "private_root", 
            children: [],
            parent: null,
            can_move: false,
            can_move_on: true,
        } as TreeItem,
    } as TreeItems);
    const [focusedItem, setFocusedItem] = useState<string>("private_root");
    const [expandedTreeItem, _setExpandedTreeItem] = useState<{[key: string] : boolean}>({});

    const [publicMetricExpanded, setPublicMetricExpanded] = useState(false);
    const [privateMetricExpanded, setPrivateMetricExpanded] = useState(false);
    const [conceptExpanded, setConcpetExpanded] = useState(false);

    useEffect(() => {
        if (isMetric(currentItem)) {
            setFocusedItem(metricToTreeID(currentItem.metric_id));
        } else {
            setFocusedItem("private_root");
        }
    }, [currentItem]);

    useEffect(() => {
        // expand all parent of focusedItem
    }, [focusedItem]);

    useEffect(() => {
        setTree(() => {
            const tree : TreeItems = {
                public_root: {
                    data: "public_root", 
                    children: [],
                    parent: null,
                    can_move: false,
                    can_move_on: false,
                } as TreeItem, 
                private_root: {
                    data: "private_root", 
                    children: [],
                    parent: null,
                    can_move: false,
                    can_move_on: true,
                } as TreeItem,
            };
            domains.forEach((domain) => {
                tree[domainToTreeID(domain.id)] = {
                    data: domain,
                    children: [],
                    parent: null,
                    can_move: !domain.all_users,
                    can_move_on: !domain.all_users,
                }
            });
            domains.forEach((domain) => {
                const treeKey = domainToTreeID(domain.parent_id || 0);
                let parentKey = null;
                if (treeKey in tree) {
                    tree[treeKey] = {
                        ...tree[treeKey],
                        children: [... tree[treeKey].children, domainToTreeID(domain.id)],
                    }
                    parentKey = treeKey;
                } else if (domain.all_users) {
                    tree["public_root"] = {
                        ...tree["public_root"],
                        children: [... tree["public_root"].children, domainToTreeID(domain.id)],
                    }
                    parentKey = "public_root";
                } else {
                    tree["private_root"] = {
                        ...tree["private_root"],
                        children: [... tree["private_root"].children, domainToTreeID(domain.id)],
                    }
                    parentKey = "private_root";
                }
                tree[domainToTreeID(domain.id)] = {
                    ...tree[domainToTreeID(domain.id)],
                    parent: parentKey,
                }
            });
            metrics.forEach((metric) => {
                const treeKey = domainToTreeID(metric.domain_id || 0);
                let parentKey = null;
                if (treeKey in tree) {
                    tree[treeKey] = {
                        ...tree[treeKey],
                        children: [... tree[treeKey].children, metricToTreeID(metric.metric_id)],
                    }
                    parentKey = treeKey;
                } else if (metric.all_users) {
                    tree["public_root"] = {
                        ...tree["public_root"],
                        children: [... tree["public_root"].children, metricToTreeID(metric.metric_id)],
                    }
                    parentKey = "public_root";
                } else {
                    tree["private_root"] = {
                        ...tree["private_root"],
                        children: [... tree["private_root"].children, metricToTreeID(metric.metric_id)],
                        parent: null,
                    }
                    parentKey = "private_root";
                }
                tree[metricToTreeID(metric.metric_id)] = {
                    data: metric,
                    children: [],
                    parent: parentKey,
                    can_move: !metric.all_users,
                    can_move_on: false,
                }
            });
            return tree;
        })
        domains.forEach((domain) => {
            const key : string = domainToTreeID(domain.id)
            if(!(key in expandedTreeItem)) {
                expandedTreeItem[key] = false;
                _setExpandedTreeItem({...expandedTreeItem});
            }
        })
    }, [metrics, domains]);

    const setExpandedTreeItem = (key: string, b: boolean) => {
        // if (key in expandedTreeItem) {
            expandedTreeItem[key] = b;
            _setExpandedTreeItem({...expandedTreeItem});
        // _setExpandedTreeItem({...expandedTreeItem, (key): b});
        // } else {
        //     console.log("wrong expand key", key);
        // }
    }

    const metricToTreeID = (metric_id: number) => {
        return `Metric_${metric_id}`;
    }
    
    const domainToTreeID = (domain_id: number) => {
        return `Domain_${domain_id}`;
    }

    const treeToMetricID = (tree_id: string) => {
        return Number(tree_id.split("_")[1]);
    }

    const treeToDomainID = (tree_id: string) => {
        return Number(tree_id.split("_")[1]);
    }

    const treeIDIsDomain = (tree_id: string) => {
        return (tree_id.split("_")[0] === "Domain");
    }

    const treeIDIsMetric = (tree_id: string) => {
        return (tree_id.split("_")[0] === "Metric");
    }

    const calculateHeight = (tree_id: string) : number => {
        if (! (tree_id in tree)) {
            return 0;
        }
        if (treeIDIsMetric(tree_id)) {
            return 29;
        }
        if (treeIDIsDomain(tree_id) && (!(tree_id in expandedTreeItem) || !(expandedTreeItem[tree_id]))) {
            return 29;
        }
        return tree[tree_id].children.reduce((prev: number, curr: string) => {
            return prev + calculateHeight(curr);
        }, 0) + 29;
    }

    // return tree if and only if tree_id is parent of target_id
    const isParent = (tree_id: string, target_id: string) => {
        if (!(target_id in tree)) {
            return false;
        }
        let treePointer : string | null = tree[target_id].parent;
        while (treePointer !== null) {
            if (treePointer === tree_id) {
                return true;
            }
            if (!(treePointer in tree)) {
                console.log("tree pointer not in tree");
                return false;
            }
            treePointer = tree[treePointer].parent;
        }
        return false;
    }

    const canMove = (tree_id: string, target_id: string) => {
        // console.log(tree_id, target_id);
        return tree_id in tree && tree[tree_id].can_move && target_id in tree && tree[target_id].can_move_on && !(treeIDIsDomain(tree_id) && isParent(tree_id, target_id));
    }

    // move tree_id to under target_id, and fine positioned under sub_target_id if possible
    const moveTreeItem = (tree_id: string, target_id: string, sub_target_id?: string) => {
        if (canMove(tree_id, target_id)) {
            // perform move
            const oldParent = tree[tree_id].parent;
            if (oldParent !== null) {
                tree[oldParent] = {
                    ...tree[oldParent],
                    children: tree[oldParent].children.filter((child) => child !== tree_id),
                }
            }
            tree[tree_id].parent = target_id;
            let sub_target_index = 0;
            if (sub_target_id) {
                sub_target_index = Math.max(tree[target_id].children.findIndex((child) => sub_target_id), 0);
            }

            tree[target_id] = {
                ...tree[target_id],
                children: [...tree[target_id].children.slice(0, sub_target_index), tree_id, ...tree[target_id].children.slice(sub_target_index, undefined)],
            }
            setFocusedItem(tree_id);
        } else {
            console.log("cannot move ", tree_id, " to ", target_id);
        }
    }

    return (
        <CustomTreeContext.Provider value={{ tree, canMove, moveTreeItem, isParent, focusedItem, setFocusedItem, metricToTreeID, domainToTreeID, treeToDomainID, treeToMetricID, expandedTreeItem, setExpandedTreeItem, treeIDIsDomain, calculateHeight, publicMetricExpanded, setPublicMetricExpanded, privateMetricExpanded, setPrivateMetricExpanded, conceptExpanded, setConcpetExpanded }}>
        {children}
        </CustomTreeContext.Provider>
    );
};

export { CustomTreeProvider, useCustomTree };
