import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ExcelIcon } from "../svg/icon_export_excel.svg";
import { ChatMessage } from "../@types/common";
import { useToast } from "../hooks/useToast";
import { useDebug } from "../hooks/useDebug";
import * as XLSX from "xlsx";
import { ReactComponent as Up } from "../svg/up_arrow.svg";
import { ReactComponent as Down } from "../svg/down_arrow.svg";
import { ReactComponent as SaveInactive } from "../svg/save_inactive.svg";
import { ReactComponent as SaveActive } from "../svg/save_active.svg";
import { ReactComponent as Like } from "../svg/like_button.svg";
import { ReactComponent as Dislike } from "../svg/dislike_button.svg";
import mixpanel from 'mixpanel-browser';
import SimpleToolTip, { SimpleToolTipId } from "./ToolTip/SimpleToolTip";
import { Page, useAuth } from "../hooks/useAuth";
import { useLibrary } from "../hooks/useLibrary";

interface MoreButtonProps {
  message: ChatMessage;
  showDetails: number;
  setShowDetails: (value: number) => void;
  mouseOver: boolean;
  page: string;
}

const MoreButton: React.FC<MoreButtonProps> = ({
  message,
  showDetails,
  setShowDetails,
  mouseOver,
  page,
}) => {
  const toast = useToast();
  const { open, setOpen, setMessage: setDebugMessage } = useDebug();
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [feedbackType, setFeedbackType] = useState(message.feedback ? message.feedback : '');
  const [feedbackText, setFeedbackText] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {checkEditPrivilege} = useAuth();
  const {library} = useLibrary();

  const handleButtonClick = (type: string) => {
    if (feedbackType === type) {
      setFeedbackType('');
      handleFeedback('', '');
      setShowInput(false);
    } else {
      setFeedbackType(type);
      handleFeedback(type, '');
      setShowInput(true);
    }
  };

  useEffect(() => {
    if (showInput && inputRef.current !== null) {
      inputRef.current.scrollIntoView();
      inputRef.current.focus();
    }
  }, [showInput]);

  const handleInputSubmit = () => {
    handleFeedback(feedbackType, inputValue);
    setInputValue('');
    setShowInput(false);
    setFeedbackText(true);
    setTimeout(() => {
      setFeedbackText(false);
    }, 2000);
  };

  const handleFeedback = async (type: string, comment: string) => {
    const requestBody: any = {
      historyid: message.query_id,
    };
    requestBody.feedback = type;
    if (comment) requestBody.comment = comment;

    const response = await fetch("/api/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
  };

  const handleExport = async () => {
    const response = await fetch("/api/export", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tableData: message.tableData,
        headData: message.tableHead,
      }),
    });
    if (response.status !== 200) {
      toast.error("Unable to export table, please try again");
      return;
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onDebugClick = () => {
    setDebugMessage(message);
    setOpen(!open);
  };

  const exportToExcel = () => {
    if (message.steps !== undefined &&  message.steps.length > 0) {
      const lastStep = message.steps[message.steps.length - 1];
      const head = lastStep.tableHead;
      const data = lastStep.tableData;
      const input = message.input;
      const sql = message.sql;
      if (head && data) {
        const dataWithHeaders = data.map((row) => {
          return head?.reduce((obj, header, index) => {
            return { ...obj, [header]: row[index] };
          }, {});
        });

        if (dataWithHeaders) {
          const ws = XLSX.utils.json_to_sheet(dataWithHeaders);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Query Data");

          const messagesToExport = [[input], [sql]];
          const ws2 = XLSX.utils.aoa_to_sheet(messagesToExport);
          XLSX.utils.book_append_sheet(wb, ws2, "SQL");

          XLSX.writeFile(wb, "table_data.xlsx");
        }
      }
    }
  };

  const formatDateToTime = (date: Date): string => {
    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const minutes = date.getMinutes();
    const amPm = hours24 < 12 ? "am" : "pm";

    const hoursString = hours12.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");

    return `${hoursString}:${minutesString}${amPm}`;
  };

  return (
    <>
      <div className="w-full h-fit justify-between py-[4px] pl-[15px] flex flex-row">
        <div className="w-fit h-[35px] flex flex-row gap-[10px]">
          <div className="w-fit h-fit">
            <div className="font-Inter text-[12px] font-[500] opacity-[0.40] text-[#6B7280] text-right">
              {formatDateToTime(message.date)}
            </div>
          </div>
          {message.steps && message.steps.length > 1 && showDetails === 1 && mouseOver && (
            <button className="flex flex-row items-center gap-[2px] w-fit h-fit" onClick={() => {
              setShowDetails(2);
              mixpanel.track('Show Details', {
                'message': message,
              });
            }}>
              <div className="font-Inter text-[12px] font-[500] opacity-[0.40] text-[#6B7280] text-right">
                Show details
              </div>
              <Down className="h-[15px] w-[15px]" />
            </button>
          )}
          {message.steps && message.steps.length > 1 && showDetails === 2 && mouseOver && (
            <button className="flex flex-row items-center gap-[2px] w-fit h-fit" onClick={() => {
              setShowDetails(1);
              mixpanel.track('Hide Details', {
                'message': message,
              });
            }}>
              <div className="font-Inter text-[12px] font-[500] opacity-[0.40] text-[#6B7280] text-right">
                Hide details
              </div>
              <Up className="h-[15px] w-[15px]" />
            </button>
          )}
        </div>
        <div className={`flex flex-row h-fit w-fit px-[10px] ${mouseOver ? "opacity-1" : "opacity-0"}`}>
          {/* {feedbackText && (
            <div className="flex items-center mr-2">
              Feedback received
            </div>
          )} */}
          <button className="outline-none"
            onClick={() => {
              if (!checkEditPrivilege(page as Page, library?.id || 0)) {
                toast.warning("You do not have edit access to this page");
                return;
              }
              handleButtonClick('like');
            }}
            data-tooltip-id={SimpleToolTipId} data-tooltip-content="Looks good">
            <Like className={`h-6 w-6 hover:text-blue-700 ${feedbackType === 'like' ? 'text-blue-700' : ''}`} />
          </button>
          <button className="outline-none"
            onClick={() => {
              if (!checkEditPrivilege(page as Page, library?.id || 0)) {
                toast.warning("You do not have edit access to this page");
                return;
              }
              handleButtonClick('dislike');
            }}
            data-tooltip-id={SimpleToolTipId} data-tooltip-content="Incorrect">
            <Dislike className={`h-6 w-6 hover:text-blue-700 ${feedbackType === 'dislike' ? 'text-blue-700' : ''}`} />
          </button>
          <button
            className={`flex items-center rounded-md px-2 py-2 text-sm cursor-pointer outline-none`}
            data-tooltip-id={SimpleToolTipId} data-tooltip-content="Save to Excel"
            onClick={() => {
              if (!checkEditPrivilege(page as Page, library?.id || 0)) {
                toast.warning("You do not have edit access to this page");
                return;
              }
              exportToExcel();
            }}
          >
            <ExcelIcon className="h-4.5 w-4.5 hover:text-blue-700" />
          </button>
        </div>
      </div>
      {showInput && (
        <div className="flex flex-col mt-2 w-2/3 h-fit">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="border-2 border-gray-200 rounded-md p-2 w-full flex"
            placeholder="Please provide any other feedback or enter corrected SQL"
            ref={inputRef}
          />
          <div className="flex justify-end">
            {inputValue ? (
              <SaveActive
                onClick={handleInputSubmit}
                className="h-10 w-40 mt-2 cursor-pointer justify-end"
              />
            ) : (
              <SaveInactive
                className="h-10 w-40 mt-2 cursor-not-allowed"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MoreButton;
