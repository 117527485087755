import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Item, Metric } from "../../@types/common";
import { useMetrics } from "../../hooks/useMetrics";
import SearchBar, { applyFilterValue, applyFilterValueRaw } from "../SearchBar";
import { groupByLetter } from "./ChatBottomPanel";
import MetricEntry from "../Entry/MetricEntry";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, COMMAND_PRIORITY_HIGH, SELECTION_CHANGE_COMMAND } from "lexical";
import { $createMetricNode, $isMetricNode } from "../../lexical/MetricNode";

interface MetricBottomPanelProps {
    selectedMetric: Metric | null;
    setSelectedMetric: Dispatch<SetStateAction<Metric | null>>;
}

const MetricBottomPanel : React.FC<MetricBottomPanelProps> = ({selectedMetric, setSelectedMetric}) => {
    const {metrics} = useMetrics();
    // const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [filterValue, setFilterValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    const groupedMetrics : {[key: string]: Metric[]} = groupByLetter(metrics.filter((metric) => applyFilterValueRaw(filterValue)(metric.metric_name)));
    const groupedMetricsNum = metrics.filter((metric) => applyFilterValueRaw(filterValue)(metric.metric_name)).length;

    const [editor] = useLexicalComposerContext();

    const handleClick = (metric: Metric) => {
        let isRemoving = false;
        if (filterValue === "" && selectedMetric !== metric) {
            setSelectedMetric(metric);
            isRemoving = false;
        } else {
            setSelectedMetric(null);
            isRemoving = true;
        }
        editor.update(() => {
            const selection = $getSelection();
            const nodes = selection?.getNodes();
            const metricNode = $createMetricNode(metric.metric_name, "", "Metric", metric.metric_desc || "", metric.metric_logic || "");
            if (nodes !== undefined && nodes.length > 0 && $isMetricNode(nodes[0])) {
                if (nodes[0].__metric === metricNode.__metric && isRemoving) {
                nodes[0].remove();
                } else {
                selection?.insertNodes([metricNode]);
                }
            } else {
                selection?.insertNodes([metricNode]);
            }
        });
    }

    return (
        <div className="flex flex-1 flex-col items-start h-full">
            <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                <div className="font-Inter font-[700] text-[16px]">
                    Metrics
                </div>
                {
                    groupedMetricsNum > 0 && 
                    <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                        ({groupedMetricsNum})
                    </div>
                }
                <SearchBar isSearching={isSearching} setIsSearching={setIsSearching} filterValue={filterValue} setFilterValue={setFilterValue} />
            </div>
            <div className="scrollbar w-full h-full pt-[10px] gap-[8px] flex flex-col overflow-y-auto items-start justify-start">
                {
                    Object.keys(groupedMetrics).map((key, index) => {
                        return (
                            <div className="w-full h-fit gap-[8px] flex flex-row items-start justify-start" key={`_groupByLetterParent_${key}`}>
                                <span className="font-Inter font-[700] w-[16px] h-[20px] text-[14px] text-black opacity-[0.3] align-middle" key={`_groupByLetterParent_KeyName_${key}`}>
                                    {key}
                                </span>
                                <div className="w-full h-fit pt-10px gap-[8px] flex flex-row flex-wrap">
                                    {groupedMetrics[key].map((item, index) => {
                                        return (
                                        <div key={item.metric_id}>
                                            <MetricEntry metric={item} isSelected={selectedMetric !== null && selectedMetric.metric_id === item.metric_id} filter={filterValue} 
                                                onClick={() => {
                                                    handleClick(item);
                                                }}
                                            />
                                        </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MetricBottomPanel;