import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const AuthedRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to={
    location.pathname !== "/" ?
    `/login?redirect=${location.pathname}${location.search}` :
    "/login"
  } />;
};

export default AuthedRoute;
