import { ReactComponent as CloseButtonPassive } from "../svg/close_button/close_button.svg"
import { ReactComponent as CloseButtonHover } from "../svg/close_button/close_button_hover.svg"
import { ReactComponent as CloseButtonDown } from "../svg/close_button/close_button_down.svg"
import React, { CSSProperties } from "react";

interface CloseButtonProps {
    onClose: () => void;
    className?: string;
    style?: CSSProperties;
}

const CloseButton: React.FC<CloseButtonProps> = ({onClose, className="cursor-pointer bg-[#E5DDFD]", style={}}) => {
    const [mouseOver, setMouseOver] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);
    return (
        <div 
            className={className}
            style={style}
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => {if (mouseDown) {onClose();} setMouseDown(false);}}
        >
            {mouseDown ? <CloseButtonDown/> : 
            mouseOver ? <CloseButtonHover/> :
            <CloseButtonPassive/>}
        </div>
    )
}

export default CloseButton;