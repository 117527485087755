import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipboardIcon, CheckIcon } from "@heroicons/react/24/outline";
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import '../css/code-syntax.css';

interface CodeContainerProps {
    code: string;
    isUserModified: string;
    handleValueChange: (code: string) => void;
    status: string;
    disabled: boolean;
}

const CodeContainer: React.FC<CodeContainerProps> = ({ code, isUserModified, handleValueChange, status, disabled }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [dots, setDots] = useState('');

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    };

    // const handleValueChange = (newCode: string) => {
    //   console.log(isUserModified);
    //   setIsUserModified("Y");
    //   setCode(newCode);
    // };

    useEffect(() => {
        if (status === 'loading') {
            const intervalId = setInterval(() => {
                setDots(dots => dots.length < 3 ? dots + '.' : '');
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [status]);

    const displayText = () => {
        switch (status) {
            case 'loading':
                return `Generating SQL${dots}`;
            case 'completed':
                return code;
            case 'empty':
                return code;
            default:
                return '';
        }
    };

    return (
        <div className="relative text-left rounded-md ">
            <CopyToClipboard text={code} onCopy={handleCopy}>
                <div className="flex items-center relative text-gray-200 bg-gray-600 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
                    <span>sql</span>
                    <button className="flex ml-auto gap-2">
                        {isCopied ? (
                            <>
                                <CheckIcon className="h-4 w-4" />
                                Copied!
                            </>
                        ) : (
                            <>
                                <ClipboardIcon className="h-4 w-4" />
                                Copy Code
                            </>
                        )}
                    </button>
                </div>
            </CopyToClipboard>
            <div className="overflow-auto max-h-[165px]">
                <Editor
                    value={displayText()}
                    onValueChange={handleValueChange}
                    disabled={disabled}
                    highlight={code => Prism.highlight(code, Prism.languages.sql, 'sql')}
                    padding={10}
                    placeholder="Click Generate SQL to let Xolved’s AI translate your business logic into SQL, or enter SQL directly yourself. You can also modify the AI-generated SQL."
                    className={`rounded-b-md bg-black text-white min-h-[165px] ${disabled && "opacity-70"}`}
                />
                {isUserModified === "Y" && (
                    <div className="absolute right-0 bottom-0 text-xs text-white bg-purple-500 px-2 py-1 border border-gray-400 rounded">User modified SQL</div>
                )}
            </div>
        </div>
    );
};

export default CodeContainer;
