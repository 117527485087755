import type { LexicalEditor } from 'lexical';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TextNode } from 'lexical';
import { useEffect } from 'react';

import { $createMetricNode, MetricNode } from "../lexical/MetricNode";
import { $createBraceNode } from '../lexical/BraceNode';

function findAndTransformBraceText(node: TextNode): null | TextNode {
    const text = node.getTextContent();
    const regex = /\{(.*?)\}/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
        const braceText = match[0];
        const innerText = match[1];
        const start = match.index;
        const end = regex.lastIndex;
        let targetNode;

        if (start === 0) {
            [targetNode] = node.splitText(end);
        } else {
            [, targetNode] = node.splitText(start, end);
        }

        // const braceNode = $createMetricNode(innerText, "", "", "", "");
        const braceNode = $createBraceNode(innerText);
        targetNode.replace(braceNode);
        return braceNode;
    }

    return null;
}

function textNodeTransform(node: TextNode): void {
    let targetNode: TextNode | null = node;

    while (targetNode !== null) {
        if (!targetNode.isSimpleText()) {
            return;
        }

        targetNode = findAndTransformBraceText(targetNode);
    }
}

function useBraceText(editor: LexicalEditor): void {
    useEffect(() => {
        if (!editor.hasNodes([MetricNode])) {
            throw new Error('BraceTextPlugin: BraceTextNode not registered on editor');
        }

        return editor.registerNodeTransform(TextNode, textNodeTransform);
    }, [editor]);
}

export default function BraceTextPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    useBraceText(editor);
    return null;
}
