import React, { createContext, useState, useContext, useEffect } from "react";
import {
  ChatMessage,
} from "../@types/common";
import { dateToDateStr, strToDate } from "../helpers/datetimehelper";
import * as api from "../api";
import { useAuth } from "./useAuth";
import { useLibrary } from "./useLibrary";
import { useToast } from "./useToast";

export interface SessionEntry {
  id: number;
  name: string;
  create_time: Date;
  status: string;
  newly_created?: boolean;
}

type SortedSessionsType = {[key: string]: SessionEntry[]};

interface SessionContextData {
  sessions: SessionEntry[];
  sortedSessions: SortedSessionsType;
  currentSessionId: number;
  setCurrentSessionId: (n: number) => void;
  setSessions: (s: SessionEntry[]) => void;
  addSession: (s: SessionEntry) => void;
  deleteSession: (id: number) => void;
  updateSessionName: (id: number, name: string) => void;
  fetchHistoryBySession: (id: number) => Promise<ChatMessage[]>;
}

const SessionContext = createContext<SessionContextData | undefined>(undefined);

const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within an SessionProvider");
  }
  return context;
};

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sessions, _setSessions] = useState<SessionEntry[]>([]);
  const [sortedSessions, setSortedSessions] = useState<SortedSessionsType>({});
  const [currentSessionId, setCurrentSessionId] = useState<number>(-1);
  const {userProfile, isAuthenticated} = useAuth();
  const {library} = useLibrary();
  const toast = useToast();
  const [addingSession, setAddingSession] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching session");
      const responseSession = await api.fetchUserSession(userProfile!.email, library?.id || 0);
      console.log(responseSession);
      setSessions(responseSession);
    }
    if (isAuthenticated) {
      fetchData()
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setSessions([]);
    }
  }, [library]);

  const sortSessions = () => {
    const aggregatedSessions: SortedSessionsType = {};
    sessions.forEach((session) => {
      const create_date_str = dateToDateStr(session.create_time);
      if (create_date_str in (aggregatedSessions)) {
        aggregatedSessions[create_date_str] = [...aggregatedSessions[create_date_str], session];
      } else {
        aggregatedSessions[create_date_str] = [session];
      }
    });
    const sortedSessions: SortedSessionsType = {};
    Object.keys(aggregatedSessions).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA < dateB ? 1 : dateA === dateB ? 0 : -1;
    }).forEach((key) => {
      sortedSessions[key] = aggregatedSessions[key].sort((a, b) => a.create_time < b.create_time ? 1 : a.create_time === b.create_time ? 0 : -1);
    })
    setSortedSessions(sortedSessions);
  }

  React.useEffect(() => {
    sortSessions();
  }, [sessions]);

  const setSessions = (sessions: SessionEntry[]) => {
    _setSessions(sessions);
    let newestSessionId = -1;
    let newestSessionTime: Date;
    sessions.forEach((session) => {
      if (newestSessionTime === undefined || session.create_time > newestSessionTime) {
        newestSessionTime = session.create_time;
        newestSessionId = session.id;
      }
    })
    setCurrentSessionId(newestSessionId);
  }

  const addSession = (newSession: SessionEntry) => {
    if (!addingSession) {
      setAddingSession(true);
      const oldSessions = sessions.map(s => s);
      _setSessions(sessions => [...sessions, newSession]);
      api.createUserSession(userProfile?.email || "", library?.id || 0, newSession.name).then((id) => {
        // update session with new id
        _setSessions(sessions => sessions.map(s => s.id === -1 ? {...s, id: id} : s));
        setCurrentSessionId(id);
        setAddingSession(false);
      }).catch(e => {
        console.error(e);
        toast.error(e.message);
        _setSessions(oldSessions);
        setAddingSession(false);
      })
    }
  };

  const updateSessionName = (id: number, name: string) => {
    const oldSession = sessions.find(s => s.id === id);
    if (oldSession) {
      _setSessions(sessions.map((session) => session.id === id ? {...session, name: name, newly_created: false} : session));
      api.updateUserSession(id, name, oldSession.status).catch((e) => {
        _setSessions(sessions.map((session) => session.id === id ? oldSession : session));
      });
    }
  }

  const deleteSession = (id: number) => {
    const oldSession = sessions.find(s => s.id === id);
    if (oldSession) {
      _setSessions(sessions.filter((session) => session.id !== id));
      api.updateUserSession(id, oldSession.name, "Inactive").catch((e) => {
        _setSessions([...sessions, oldSession]);
      })
    }
  }

  const fetchHistoryBySession : (n: number) => Promise<ChatMessage[]> = (id) => {
    return new Promise((res, rej) => {
      api.fetchUserHistoryBySession(userProfile?.email || "", library?.id || 0, id)
      .then(data => {
        const newMessages : ChatMessage[] = [];
        data.reverse().forEach(history => {
            const lastStep : any = history.response;
            newMessages.push({
                id: `${history.historyid}_user`,
                date: strToDate(history.timestamp),
                sender: "user",
                content: history.question,
                query_id: history.historyid,
            });
            newMessages.push({
                id: `${history.historyid}_server`,
                date: strToDate(history.timestamp),
                sender: "server",
                conversationId: "conversationId" in lastStep ? lastStep.conversationId : "",
                input: history.question,
                currentStep: 1,
                steps: [{
                    ...lastStep,
                    tableData: lastStep.table_data,
                    tableHead: lastStep.table_head,
                    status: "success",
                }],
                query_id: history.historyid,
            })
        });
        res(newMessages);
      })
      .catch(rej);
    })
  }

  return (
    <SessionContext.Provider value={{ sessions, sortedSessions, currentSessionId, setCurrentSessionId, setSessions, addSession, deleteSession, updateSessionName, fetchHistoryBySession }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, useSession };
