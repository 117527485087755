import { Item, Metric } from "../../@types/common"
import React from "react";

import { spawnMetricTooltip } from "../ToolTip/MetricToolTip";
import { Cohort } from "../../hooks/useCohorts";
import { spawnCohortTooltip } from "../ToolTip/CohortToolTip";
import { useConcept } from "../../hooks/useConcepts";
import { closeTooltip } from "../ToolTip/ToolTipHelpers";
import { parseFilter } from "../SearchBar";

import {ReactComponent as VerifiedIcon} from "../../svg/metric_tree/verified.svg";


interface MetricEntryProps {
    isSelected?: boolean;
    isVerified?: boolean;
    metric: Metric;
    filter?: string;
    onClick?: () => void;
    showTooltip? : boolean;
}

const MetricEntry: React.FC<MetricEntryProps> = ({isSelected=false, isVerified=false, showTooltip=true, metric, filter = "", onClick=() => {}}) => {
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    const panelRef = React.useRef<HTMLDivElement>(null);
    
    let panelRect: DOMRect | null;
    if (panelRef.current !== null) {
        panelRect = (panelRef.current.getBoundingClientRect());
    } else {
        panelRect = (null);
    }

    const handleOver = (e: any, metric: string, desc: string, logic: string) => {
        if (tooltipRef.current === null) {
            tooltipRef.current = spawnMetricTooltip(e, metric, desc, logic, arrowRef);
            e.stopPropagation()
        }
    }

    const handleLeave = (e: any) => {
        if (tooltipRef.current !== null) {
            closeTooltip(tooltipRef.current);
            tooltipRef.current = null;
        }
    }

    return (
        <div 
            className={`flex flex-row items-center h-[22px] w-fit rounded-[10px] border-[1px] ${isSelected ? "border-[#6E43F8]" : "border-[#E5DDFD]"} px-[8px] py-[3px] ${isSelected ? "bg-[#6E43F8]" : "bg-[#F3F4F6] hover:bg-[#EEE8FF]"} cursor-pointer whitespace-pre select-none`}
            style={{
                maxWidth: '100%'
            }}
            onMouseEnter={(e) => {
                if (showTooltip) {
                    const id = setTimeout(() => {
                        handleOver(e, metric.metric_name, metric.metric_desc || "", metric.metric_logic || "");
                    }, 500);
                    setTimeoutId(id);
                }
            }} 
            onMouseLeave={(e) => {
                if (timeoutId !== null) {
                    clearTimeout(timeoutId);
                    setTimeoutId(null);
                }
                handleLeave(e);
            }}
            onMouseDown={(e) => {
                if (timeoutId !== null) {
                    clearTimeout(timeoutId);
                    setTimeoutId(null);
                }
                handleLeave(e);
            }}
            onClick={(e) => {
                onClick();
            }}
            id={`_metric_${metric.metric_id}`}
            ref={panelRef}
        >
            {/* {panelRect != null && mouseOver && spawnTooltipPortal(metric.name, metric.desc || "", metric.logic || "", panelRect)} */}
            {parseFilter(metric.metric_name, filter).map((chunk, index) => {
                return chunk.type === "text" ? 
                <span 
                    className={`font-Inter text-[13px] font-[500] text-left truncate align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`}>
                    {chunk.content}
                </span> : 
                <span className={`font-Inter text-[13px] font-[700] text-left truncate align-middle ${isSelected ? "text-white" : "text-[#6E43F8]"}`}>
                    {chunk.content}
                </span>
            })}
            {isVerified && <VerifiedIcon className="min-w-[18px] min-h-[18px]"/>}
        </div>
    )
}


export default MetricEntry;