import React, { createContext, useState, useContext, useEffect } from "react";
import { Attribute, Concept, Item } from "../@types/common";
import * as api from "../api";
import { useLibrary } from "./useLibrary";
import { useToast } from "./useToast";
import { useAuth } from "./useAuth";

interface ConceptContextData {
    concepts: Concept[];
    addConcept: (concept: Concept) => Promise<Concept>;
    // removeConcept: (id: number) => void;
    // setConcepts: (pin: Concept[]) => void;
}

const ConceptContext = createContext<ConceptContextData | undefined>(undefined);

const useConcept = () => {
    const context = useContext(ConceptContext);
    if (!context) {
        throw new Error("useConcept must be used within an ConceptProvider");
    }
    return context;
};

const ConceptProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [concepts, setConcepts] = useState<Concept[]>([]);
    const {library} = useLibrary();
    const {isAuthenticated, userProfile} = useAuth();
    const toast = useToast();

    const [addingConcept, setAddingConcept] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
        console.log("fetching concepts");
        const responseConcepts = await api.fetchUserConceptWithAttributes(library?.id || 0);
        console.log(responseConcepts);
        setConcepts(responseConcepts.map(
            concept => {return {...concept.concept, type: "Concept", attributes: concept.attributes}}));
        }
        if (isAuthenticated) {
        setConcepts([]);
        fetchData()
            .catch((error) => {
            toast.error(error.message);
            });
        } else {
        setConcepts([]);
        }
    }, [library]);
  
    const addConcept = (concept: Concept) => {
        return new Promise<Concept>((res, rej) => {
            if (addingConcept) {
                toast.warning("please wait while other concept is added");
                rej("other concept is adding");
            }

            setAddingConcept(true);
            const oldConcepts = [...concepts];
            const newConcept: Concept = {
                ... concept,
                id: -1,
            }
            setConcepts([newConcept, ...concepts]);
            api.createConcept(library?.id || 0, userProfile?.email || '', newConcept.domain_id || 0, newConcept.name, newConcept.category || '', newConcept.desc || '', newConcept.sql || '', newConcept.modified_sql || 'N', newConcept.logic || '', newConcept.formular || '', newConcept.input_fields || '', newConcept.output_fields || '', newConcept.status || '', newConcept.deprecated || '', newConcept.attributes).then((id) => {
                newConcept.id = id;
                setConcepts(concepts.map((old_concept) => old_concept.id === -1 ? newConcept : old_concept));
                setAddingConcept(false);
                res(newConcept);
            }).catch((e) => {
                toast.warning("create concept failed!");
                setConcepts(oldConcepts);
                setAddingConcept(false);
                rej("create concept failed");
            });
        });
    }

    // const removeConcept = (id: number) => {
    //   setConcepts(conceptList => conceptList.filter(concept => concept.id !== id));
    // }

    return (
        <ConceptContext.Provider value={{ concepts, addConcept }}>
        {children}
        </ConceptContext.Provider>
    );
};

export { ConceptProvider, useConcept };
