import React from "react";
import { useLibrary } from "../../hooks/useLibrary";
import LibraryDropdown from "../LibraryDropdown";


interface TopBarProps {
    title: string;
}

const TopBar: React.FC<TopBarProps> = ({ title }) => {
    const { library, libraries, setLibrary } = useLibrary();

    const handleLibrarySelection = (selectedOption: { value: number, label: string } | null) => {
        if (selectedOption) {
          const selectedLibrary = libraries?.find(lib => lib.id === selectedOption.value);
          setLibrary(selectedLibrary || null);
        }
    };

    return (
        <div className="absolute h-[56px] z-[5] top-0 left-0 w-full z-5 h-10 bg-white shadow flex items-center justify-between px-8">
            <span className="text-lg font-bold ml-10">{title}</span>
            <div className="min-w-[350px] w-[350px]">
                <LibraryDropdown
                libraries={libraries || []}
                selectedLibrary={library}
                onLibrarySelect={handleLibrarySelection}
                customStyles={{
                    control: (base) => ({
                        ...base,
                        border: 0,
                        boxShadow: "none",
                    }),
                    singleValue: (base) => ({
                        ...base,
                        fontWeight: 'bold',
                        fontSize: 'large'
                    })
                }}
                customComponents={{
                    IndicatorSeparator: () => null
                }}
                />
            </div>
            <span className="text-lg font-bold">ABC Healthcare</span>
        </div>
    );
};

export default TopBar;
