import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  MenuOption,
  LexicalTypeaheadMenuPlugin,
  MenuTextMatch,
  SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { KEY_ARROW_DOWN_COMMAND, KEY_ARROW_UP_COMMAND, TextNode } from "lexical";
import { $createMetricNode } from "../lexical/MetricNode";
import ReactDOM from "react-dom";
import { useConcept } from "../hooks/useConcepts";
import { Cohort, useCohort } from "../hooks/useCohorts";
import { $createCohortNode } from "../lexical/CohortNode";

const cohortsCache = new Map();

const cohortsSearch = (
  query: string,
  cohorts: Cohort [],
  callback: (results: Array<Cohort>) => void,
) => {
  setTimeout(() => {
    const results = cohorts.filter((cohort) =>
      cohort.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results);
  }, 500);
};

const useCohortLookupService = (queryString: string | null, cohorts: Cohort[]) => {
  const [results, setResults] = useState<Array<Cohort>>([]);

  useEffect(() => {
    if (queryString === null) {
      setResults([]);
      return;
    }

    const cachedResults = cohortsCache.get(queryString);
    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    cohortsCache.set(queryString, null);
    cohortsSearch(queryString, cohorts, (results) => {
      cohortsCache.set(queryString, results);
      setResults(results);
    });
  }, [queryString]);

  return results;
};

const MetricRegex = /(^|\s)(@((?:[a-zA-z0-9\s]){0,20}))$/;

const getPossibleQueryMatch = (text: string): MenuTextMatch | null => {
  let match = MetricRegex.exec(text);
  if (match) {
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    return {
      leadOffset: match.index + maybeLeadingWhitespace.length,
      matchingString,
      replaceableString: match[2],
    }
  }

  return null;
}

class CohortTypeaheadOption extends MenuOption {
  // name: string;
  cohort: Cohort;
  concept: string;
  attribute: string;
  values: string[];
  allowEdit: boolean;

  constructor(cohort: Cohort, concept: string, attribute: string, values: string[], allowEdit: boolean) {
    super(cohort.name);
    this.cohort = cohort;
    this.concept = concept;
    this.attribute = attribute;
    this.values = values;
    this.allowEdit = allowEdit;
  }
}

// class MetricTypeaheadOption extends MenuOption {
//   name: string;
//   status: string;
//   type: string;
//   description: string;
//   bl: string;
//   concept: Concept | null;

//   constructor(name: string, status: string, type: string, description: string, bl: string, concept: Concept | null = null) {
//     super(name);
//     this.name = name;
//     this.status = status;
//     this.type = type;
//     this.description = description;
//     this.bl = bl;
//     this.concept = concept;
//   }
// }

interface CohortsAutoCompletePluginProps {
  cohorts: Cohort[],
}

const CohortsAutoCompletePlugin: React.FC<CohortsAutoCompletePluginProps> = ({ cohorts }) => {
  const [editor] = useLexicalComposerContext();
  const editorRef = useRef<any>(null);

  const [queryString, setQueryString] = React.useState<string | null>(null);

  const { concepts } = useConcept();

  // const cohortNames = cohorts.map(cohort => cohort.name);

  const results = useCohortLookupService(queryString, cohorts);

  const options = useMemo(() => {
    let filteredResults = results;

    return filteredResults.map((result) => {
      const concept = concepts.find(concept => concept.attributes.find(attribute => attribute.id === result.attribute_id) !== undefined);
      if (concept !== undefined) {
        const attribute = concept.attributes.find(attribute => attribute.id === result.attribute_id);
        if (attribute !== undefined) {
          return new CohortTypeaheadOption(result, concept.name, attribute.name, result.attribute_values, false);
        }
      }
    }).filter(a => a !== undefined) as CohortTypeaheadOption[];
  }, [results]);


  const onOptionSelected = useCallback(
    (selectedOption: CohortTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        // const isMetric = selectedOption.type === "Metric";
        // const isConcept = selectedOption.type === "Concept";
        const cohortNode = $createCohortNode(selectedOption.cohort, selectedOption.concept, selectedOption.attribute, selectedOption.values, selectedOption.allowEdit);
        if (nodeToReplace) {
          nodeToReplace.replace(cohortNode);
        }
        cohortNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMatch = useCallback(
    (text: string) => {
      return getPossibleQueryMatch(text);
    }, [editor]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        editorRef.current?.focus();
      }
    }

    const targetElement = editorRef.current || document;
    targetElement.addEventListener('keydown', handleKeyDown);

    return () => {
      targetElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [options, editorRef]);

  const CohortDisplay: React.FC<{
    selectedIndex: number | null;
    selectOptionAndCleanUp: (option: CohortTypeaheadOption) => void;
    setHighlightedIndex: (index: number) => void;
  }> = ({selectedIndex, selectOptionAndCleanUp, setHighlightedIndex}) => {
    const elementsRef = useRef<(HTMLDivElement|null)[]>(new Array<HTMLDivElement|null>());
    const [myOwnNumber, _setSelectedIndex] = React.useState(0);

    useEffect(() => {
      setHighlightedIndex(0);
    }, []);

    const scrollIntoViewIfNeeded = (target:HTMLDivElement) => {
      console.log(target.getBoundingClientRect());
      // Target is outside the viewport from the bottom
      if (target.getBoundingClientRect().bottom > window.innerHeight) {
          //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
          target.scrollIntoView(false);
      }
  
      // Target is outside the view from the top
      if (target.getBoundingClientRect().top < 0) {
          // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
          target.scrollIntoView();
      }
  };

    useEffect(() => {
      return editor.registerCommand(KEY_ARROW_DOWN_COMMAND, (payload) => {
        _setSelectedIndex((selected) => { 
          let newNum = selected + 1; 
          if (newNum >= options.length) {
            newNum = options.length - 1;
          }
          // console.log(newNum); 
          setHighlightedIndex(newNum);
          // scrollIntoViewIfNeeded(elementsRef.current[newNum]!);
          elementsRef.current[newNum]!.scrollIntoView();
          return newNum; 
        });
        payload.preventDefault();
        payload.stopImmediatePropagation();
        return true;
      }, 4);
    }, []);

    useEffect(() => {
      return editor.registerCommand(KEY_ARROW_UP_COMMAND, (payload) => {
        _setSelectedIndex((selected) => { 
          let newNum = selected - 1; 
          if (newNum < 0) {
            newNum = 0;
          }
          // console.log(newNum); 
          setHighlightedIndex(newNum);
          // scrollIntoViewIfNeeded(elementsRef.current[newNum]!);
          elementsRef.current[newNum]!.scrollIntoView();
          return newNum; 
        });
        payload.preventDefault();
        payload.stopImmediatePropagation();
        return true;
      }, 4);
    }, []);

    return (
      <div
        className="absolute bottom-full bg-white shadow-metricTooltip rounded-md flex flex-col w-[300px] max-h-[200px] overflow-y-auto"
        onLoad={() => console.log("loaded")}
        onKeyDown={() => console.log("keydown")}
      >
        <div className="flex">
          <span className="flex-1">
            My Groups
          </span>
        </div>
        {options.map((option, index) => (
          <div
            ref={(element) => elementsRef.current[index] = element}
            key={option.key}
            className={`px-[4px] py-[2px] w-full ${index === selectedIndex ? "bg-gray-100" : ""}`}
            onClick={() => {
              _setSelectedIndex(index);
              setHighlightedIndex(index);
              selectOptionAndCleanUp(option)
            }}
            onMouseMove={() => {
              _setSelectedIndex(index);
              setHighlightedIndex(index)}}
          >
            <div className="h-fit w-fit rounded-[10px] border-[1px] border-[#E5DDFD] px-[8px] py-[3px] bg-metric-entry cursor-pointer" 
                // key={index}
                // id={`_metric_${index}`}
            >
                <div className="font-Inter text-[13px] font-[500] tracking-normal text-left leading-[16px] text-[#412B88]">
                    {option.cohort.name}
                </div>
            </div>
            {/* {option.name} */}
            {/* {option.status === "Verified" && <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />} */}
          </div>
        ))}
      </div>
    )
  }

  return (
    <LexicalTypeaheadMenuPlugin<CohortTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onOptionSelected}
      triggerFn={checkForMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        anchorElementRef.current && ReactDOM.createPortal(
          <CohortDisplay selectedIndex={selectedIndex} selectOptionAndCleanUp={selectOptionAndCleanUp} setHighlightedIndex={setHighlightedIndex}/>
          ,
          anchorElementRef.current)
      }
    />
  );
};

export default CohortsAutoCompletePlugin;
