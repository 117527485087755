

export const strToDate = (s: string) => {
    const parts = s.split(' ');
    const datePart = parts[0];
    const timePart = parts[1];
    const date = new Date(`${datePart}T${timePart}Z`);
    return date;
}

export const dateToStr = (date: Date) => {
    // 2023-10-26 00:12:44.830883
    return `${dateToDateStr(date)} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
}

export const dateToUTCStr = (date: Date) => {
    return `${dateToUTCDateStr(date)} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}.${date.getUTCMilliseconds()}`;
}

export const dateToDateStr = (date: Date) => {
    return `${date.getFullYear().toString().padStart(4, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}

export const dateToUTCDateStr = (date: Date) => {
    return `${date.getUTCFullYear().toString().padStart(4, "0")}-${(date.getUTCMonth() + 1).toString().padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
}