import {
    $applyNodeReplacement,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalCommand,
    LexicalEditor,
    LexicalNode,
    SerializedTextNode,
    Spread,
    TextNode,
    createCommand,
} from "lexical";
import { Attribute, Concept, Item } from "../@types/common";
import { spawnConceptTooltip } from "../components/ToolTip/ConceptToolTip";
import { closeTooltip } from "../components/ToolTip/ToolTipHelpers";

export type SerializedConceptNode = Spread<
    {
        concept: Concept;
        attribute: Attribute | null;
        values: string[];
        conceptLevel: number;
    },
    SerializedTextNode
>;
  
class ConceptNode extends TextNode {
    __concept: Concept;
    __attribute: Attribute | null;
    __values: string[];
    __conceptLevel: number;
    __popup: {
        current: HTMLDivElement | null;
    };
    __popupArrow: {
        current: SVGSVGElement | null;
    };
  
    static getType(): string {
        return "concept";
    }
  
    static clone(node: ConceptNode): ConceptNode {
        return new ConceptNode(node.__concept, node.__attribute, node.__values, node.__conceptLevel, node.__text, node.__key);
    }

    constructor(concept: Concept, attribute: Attribute | null, values: string[], conceptLevel: number, text?: string, key?: string) {
        let assembledText = "";
        if (conceptLevel >= 2 && values.length > 0) {
            if (values.length > 2) {
                assembledText = `{${concept.name}.${attribute?.name} = ${values[0]},${values[1]}...+${values.length - 2}}`;
            } else {
                assembledText = `{${concept.name}.${attribute?.name} = ${values}}`;
            }
        } else if (conceptLevel >= 1) {
            assembledText = `{${concept.name}.${attribute?.name}}`;
        } else {
            assembledText = `{${concept.name}}`;
        }
        super(text ?? assembledText, key);
        this.__concept = concept;
        this.__attribute = attribute;
        this.__values = values;
        this.__conceptLevel = conceptLevel;
        this.__popup = {current: null};
        this.__popupArrow = {current: null};
    }
  
    handleHover = (e : any) => {
        if (this.__popup.current === null) {
            const concept = this.__concept;
            const attribute = this.__attribute;
            const values = this.__values;
            // const editor = this.__editor;
            const arrowRef = this.__popupArrow;
            let popup: HTMLDivElement | null = null;
            if (this.__conceptLevel === 0) {
                popup = spawnConceptTooltip({e, concept, conceptLevel: 0, arrowRef})
            } else if (this.__conceptLevel === 1 && attribute !== null) {
                popup = spawnConceptTooltip({e, concept, attribute, conceptLevel: 1, arrowRef})
            } else if (this.__conceptLevel === 2 && attribute !== null) {
                popup = spawnConceptTooltip({e, concept, attribute, values, conceptLevel: 2, arrowRef, allowCohort: true});
            }
            this.__popup.current = popup;
        
            e.stopPropagation();
        }
    }
  
    handleLeave = (e: any) => {
        if (this.__popup.current !== null && !this.__popup.current.contains(e.relatedTarget)) {
            closeTooltip(this.__popup.current);
            this.__popup.current = null;
        }
    }
  
    createDOM(config: EditorConfig): HTMLElement {
        const dom = super.createDOM(config);
        dom.addEventListener("mouseleave", this.handleLeave);
        dom.addEventListener("mouseenter", this.handleHover);
    
        dom.style.font = "Inter";
        dom.style.fontStyle = "normal";
        dom.style.fontWeight = "500";
        dom.style.fontSize = "13px";
        dom.style.lineHeight = "16px";
        dom.style.color = "#6E43F8";
        dom.style.background = "#F3F4F6";
        dom.style.border = "1px solid #E5DDFD";
        dom.style.borderRadius = "10px";
        dom.style.height = "fit";
        dom.style.width = "fit";
        dom.style.padding = "3px 8px"
    
        return dom;
    }
  
    exportDOM(): DOMExportOutput {
        const element = document.createElement("span");
        element.setAttribute("data-lexical-concept", "true");
        element.textContent = this.__text;
        return { element };
    }
  
    static importDOM(): DOMConversionMap | null {
        return {
            span: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute("data-lexical-concept")) {
                    return null;
                }
                return {
                    conversion: convertConceptElement,
                    priority: 1,
                };
            },
        };
    }
  
    static importJSON(serializedNode: SerializedConceptNode): ConceptNode {
        const node = $createConceptNode(serializedNode.concept, serializedNode.attribute, serializedNode.values, serializedNode.conceptLevel);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
  
    exportJSON(): SerializedConceptNode {
        return {
            ...super.exportJSON(),
            concept: this.__concept,
            attribute: this.__attribute,
            values: this.__values,
            conceptLevel: this.__conceptLevel,
            // bl: this.__bl,
            type: ConceptNode.getType(),
            version: 1,
        };
    }
  
    isTextEntity(): boolean {
      return true;
    }
  
    canInsertTextBefore(): boolean {
      return false;
    }
  
    canInsertTextAfter(): boolean {
      return false;
    }
}
  
function convertConceptElement(
    domNode: HTMLElement,
    // concepts: Concept[]
): DOMConversionOutput | null {
    // const textContent = domNode.textContent;
    // if (textContent !== null) {
    //     const concept = concepts.find(concept => concept.name === textContent)
    //     if (concept)
    //         return {
    //             node: $createConceptNode(concept, concept.attributes[0], [], 0),
    //             // this is broken right now
    //         };
    //     return null
    // }
    return null;
}
  
function $createConceptNode(concept: Concept, attribute: Attribute | null, values: string[], conceptLevel: number): ConceptNode {
    const metricNode = new ConceptNode(concept, attribute, values, conceptLevel);
    metricNode.setMode("token").toggleDirectionless();
    return $applyNodeReplacement(metricNode);
}
  
function $isConceptNode(
    node: LexicalNode | null | undefined
): node is ConceptNode {
    return node instanceof ConceptNode;
}


type ConceptNodeProps = {
    conceptLevel: 0;
    concept: Concept;
  } | {
    conceptLevel: 1;
    concept: Concept;
    attribute: Attribute;
  } | {
    conceptLevel: 2;
    concept: Concept;
    attribute: Attribute;
    values: string[];
  }

const parseConceptNode = (node : ConceptNode) : ConceptNodeProps | undefined => {
    if (node.__conceptLevel === 0) {
        return {
            conceptLevel: 0,
            concept: node.__concept,
        }
    } else if (node.__conceptLevel === 1 && node.__attribute !== null) {
        return {
            conceptLevel: 1,
            concept: node.__concept,
            attribute: node.__attribute,
        }
    } else if (node.__conceptLevel === 2 && node.__attribute !== null) {
        return {
            conceptLevel: 2,
            concept: node.__concept,
            attribute: node.__attribute,
            values: node.__values,
        }
    }
    return undefined;
}

const conceptMatch = (props: ConceptNodeProps, concept: Concept) => {
    return props.concept.id === concept.id;
}

const attributeMatch = (props: ConceptNodeProps, attribute: Attribute) => {
    return ("attribute" in props) && (props.attribute.id === attribute.id);
}

const valuesMatch = (props: ConceptNodeProps, values: string[]) => {
    return ("values" in props) && (props.values.length === values.length && props.values.every((v, i) => v === values[i]));
}
  
export {
    ConceptNode,
    $createConceptNode,
    $isConceptNode,
    parseConceptNode,
    conceptMatch,
    attributeMatch,
    valuesMatch,
};