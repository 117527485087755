import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Cohort, useCohort } from "../../hooks/useCohorts";
import SearchBar, { applyFilterValue, applyFilterValueRaw } from "../SearchBar";
import { CohortEntry } from "../Entry/CohortEntry";
import { useConcept } from "../../hooks/useConcepts";
import * as api from "../../api";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { $createCohortNode, $isCohortNode } from "../../lexical/CohortNode";
import { Attribute, Concept } from "../../@types/common";
import { AttributeEntry, ConceptEntry, ValueEntryNew } from "../Entry/ConceptEntry";
import { useToast } from "../../hooks/useToast";
import { useAuth } from "../../hooks/useAuth";
import { useLibrary } from "../../hooks/useLibrary";

interface GroupButtonsProp {
    onSave: (name: string) => void;
    onCancel: () => void;
    cohort: Cohort;
    selectedValues: string[];
}

const GroupButtons : React.FC<GroupButtonsProp> = ({cohort, onSave, onCancel, selectedValues}) => {
    const [editor] = useLexicalComposerContext();
    
    const [groupName, setGroupName] = useState(cohort.name);
    
    return (
        <div className="flex flex-row h-fit w-fit gap-[7px]">
            <div className="h-fit w-fit border-1 border-[#6E43F8] bg-white gap-[3px] rounded-[8px] py-[6px] px-[10px] select-none cursor-pointer flex flex-row items-center">
                <span className="font-Inter font-[500] text-[13px] text-[#6E43F8]">@</span>
                <input className="font-Inter font-[500] text-[13px] text-[#6E43F8] outline-0"
                    value={groupName}
                    autoFocus={true}
                    onChange={(e) => setGroupName(e.target.value)}
                    onBlur={() => editor.focus()}
                />
            </div>
            <div className={`h-fit w-fit border-1 ${(groupName !== "" && selectedValues.length > 0) ? "border-[#6E43F8] bg-[#6E43F8] cursor-pointer" : "border-[#010616] bg-[#010616] opacity-[0.5]"} text-white rounded-[8px] py-[6px] px-[10px] select-none font-Inter font-[500] text-[13px]`}
                onClick={() => selectedValues.length > 0 ? onSave(groupName) : undefined}
            >
                Save
            </div>
            <div className="h-fit w-fit border-1 border-[#010616] bg-white rounded-[8px] py-[6px] px-[10px] select-none cursor-pointer font-Inter font-[500] text-[13px] text-[#010616] opacity-[0.5]"
                onClick={onCancel}
            >
                Cancel
            </div>
        </div>
    )
}

interface CohortBottomPanelProps {
    selectedCohort: Cohort | null;
    setSelectedCohort: Dispatch<SetStateAction<Cohort | null>>
}

const CohortBottomPanel: React.FC<CohortBottomPanelProps> = ({selectedCohort, setSelectedCohort}) => {
    const {cohorts, addCohort, removeCohortId} = useCohort();
    const {concepts} = useConcept();
    const [filterValue, setFilterValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [editor] = useLexicalComposerContext();

    const groupedCohortsNum = cohorts.filter(applyFilterValue(filterValue)).length;

    const [values, setValues] = useState<string[]>([]);
    const [isSearchingVal, setIsSearchingVal] = useState(false);
    const [filterValueVal, setFilterValueVal] = useState("");
    const filteredValues = values.filter((value) => applyFilterValueRaw(filterValueVal)(value) || selectedValues.find((val) => val === value) !== undefined).sort((a, b) => a.localeCompare(b))

    const [selectedConcept, setSelectedConcept] = useState<Concept | null>(null);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute | null>(null);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const valuesRef = useRef<HTMLDivElement>(null);
    const [valuesWidth, setValuesWidth] = useState(200);
    const [editing, setEditing] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const scrollHeight = 30 * selectedValues.length - 8;

    const toast = useToast();
    const {checkEditPrivilege} = useAuth();
    const {library} = useLibrary();

    useEffect(() => {
        if (showMore) {
            setValuesWidth(200);
        } else {
            if (valuesRef.current && scrollHeight > valuesRef.current.getBoundingClientRect().height) {
                setValuesWidth(100);
            } else {
                setValuesWidth(200);
            }
        }
    }, [selectedValues, valuesRef, showMore, scrollHeight]);

    const updateLocalValues = () => {
        if (selectedCohort === null) {
            setSelectedConcept(null);
            setSelectedAttribute(null);
            setSelectedValues([]);
            setValues([]);
        } else {
            const concept = concepts.find(concept => concept.attributes.find(attribute => attribute.id === selectedCohort.attribute_id) !== undefined);
            setSelectedConcept(concept ? concept : null);
            if (concept) {
                const attribute = concept.attributes.find(attribute => attribute.id === selectedCohort.attribute_id);
                setSelectedAttribute(attribute ? attribute : null);
                if (attribute !== undefined) {
                    api.fetchAttributeValues(attribute.id).then((vals) => {
                        setValues(vals);
                    });
                }
            } else {
                setSelectedAttribute(null);
            }
            setSelectedValues(selectedCohort.attribute_values);
        }
    }

    useEffect(() => {
        updateLocalValues();
    // eslint-disable-next-line
    }, [selectedCohort]);
    
    const handleClick = (value: Cohort) => {
        setEditing(false);
        let isRemoving = true;
        if (filterValue === "" && selectedCohort !== value) {
            setSelectedCohort(value);
            isRemoving = false;
        } else {
            setSelectedCohort(null);
            isRemoving = true;
        }
        
        const concept = concepts.find(concept => concept.attributes.find(attribute => attribute.id === value.attribute_id) !== undefined);
        if (concept !== undefined) {
            const attribute = concept.attributes.find(attribute => attribute.id === value.attribute_id);
            editor.update(() => {
                const selection = $getSelection();
                const nodes = selection?.getNodes();
                const cohortNode = $createCohortNode(value, concept.name, attribute?.name || "", value.attribute_values, false);
                
                if (nodes !== undefined && nodes.length > 0 && $isCohortNode(nodes[0])) {
                    if (nodes[0].__cohort.id === value.id && isRemoving) {
                        nodes[0].remove();
                    } else {
                        selection?.insertNodes([cohortNode]);
                    }
                } else {
                    selection?.insertNodes([cohortNode]);
                }
            })
        }
    }

    const replaceCurrentCohort = (newCohort: Cohort) => {
        editor.update(() => {
            const selection = $getSelection();
            const nodes = selection?.getNodes();
            if (nodes !== undefined && nodes.length > 0 && $isCohortNode(nodes[0])) {
                const oldCohort = nodes[0].__cohort;
                if (oldCohort.id === newCohort.id && selectedConcept && selectedAttribute) {
                    const cohortNode = $createCohortNode(newCohort, selectedConcept.name, selectedAttribute.name, selectedValues, false)
                    nodes[0].remove();
                    selection?.insertNodes([cohortNode]);
                }
            }
        })
    }

    return (
        <div className="flex flex-row flex-1 gap-[20px]">
            {
                (editing && selectedCohort) ? 
                <>
                    <div className={`flex ${showMore ? "w-[450px]" : "w-[350px]"} flex-col gap-[12px] items-start h-full border-[#CACCD2] border-r-[1px] select-none`}>
                        {selectedConcept && 
                            <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                    Concept:
                                </div>
                                <div className="absolute left-[100px] h-fit w-fit">
                                    <ConceptEntry concept={selectedConcept} index={0} filter={""} isSelected={true} 
                                        onClick={() => {}}/>
                                </div>
                                {/* <div className="select-none cursor-pointer font-Inter font-[500] text-[13px] text-black underline" onClick={() => {
                                    resetLocalValues();
                                    spawnConceptNode(selectedConcept, null, [], 0, true);
                                }}>
                                    See all
                                </div> */}
                            </div>
                        }
                        {selectedAttribute && 
                            <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                    Attribute:
                                </div>
                                <div className="absolute left-[100px] h-fit w-fit">
                                    <AttributeEntry attribute={selectedAttribute} concept={selectedConcept} index={0} isSelected={true} filter={""} onClick={() => {}}/>
                                </div>
                                {/* <div className="select-none cursor-pointer font-Inter font-[500] text-[13px] text-black underline" onClick={() => {
                                    setSelectedAttribute(null);
                                    setValues([]);
                                    setSelectedValues([]);
                                    setConceptLevel(1);
                                    if (selectedConcept)
                                        spawnConceptNode(selectedConcept, selectedAttribute, [], 1, true);
                                }}>
                                    See all
                                </div> */}
                            </div>
                        }
                        {selectedValues.length > 0 ?
                            <>
                                <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                    <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                        Values:
                                    </div>
                                    {
                                        (showMore || valuesWidth < 200) && 
                                        <div className="select-none cursor-pointer font-Inter font-[500] text-[13px] text-black underline" onClick={() => {
                                            setShowMore(!showMore);
                                        }}>
                                            {`Show ${showMore ? "less" : "more"}`}
                                        </div>
                                    }
                                </div>
                                <div className={`scrollbar flex flex-row flex-wrap h-full w-full overflow-y-auto items-start content-start gap-[8px]`}
                                    ref={valuesRef}
                                >
                                    {selectedValues.map((value, index) =>
                                    <div key={value}>
                                    <ValueEntryNew concept={selectedConcept} attribute={selectedAttribute} values={selectedValues} setValues={setSelectedValues} value={value} isSelected={true} index={index} filter={""} onClick={() => {
                                        let newVals = [];
                                        if (selectedValues.find((val) => val === value) !== undefined) {
                                            newVals = (selectedValues.filter((val) => val !== value));
                                        } else {
                                            newVals = ([...selectedValues, value]);
                                        }
                                        // if (selectedConcept)
                                        //     spawnConceptNode(selectedConcept, selectedAttribute, [...newVals], 2, false);
                                        setSelectedValues(newVals);}
                                    } length={valuesWidth}/>
                                    </div>)}
                                </div>
                            </> :
                            <div className="flex-1"></div>
                        }
                        <GroupButtons cohort={selectedCohort} selectedValues={selectedValues}
                            onSave={(name) => {
                                if (selectedAttribute) {    
                                    const newCohort: Cohort = {
                                        id: selectedCohort.id,
                                        name: name,
                                        library_id: selectedCohort.library_id,
                                        attribute_id: selectedAttribute.id,
                                        user_email: selectedCohort.user_email,
                                        attribute_values: selectedValues,
                                    };
                                    addCohort(newCohort).then(() => {
                                        replaceCurrentCohort(newCohort);
                                        updateLocalValues();
                                        setEditing(false);
                                    }).catch((e: any) => {
                                        if (e === "duplicate_name") {
                                            toast.error("duplicate group name");
                                        } else {
                                            toast.error("unable to save group, please try again later");
                                        }
                                    })
                                }
                            }} 
                            onCancel={() => {
                                updateLocalValues();
                                setEditing(false);
                            }}
                        />
                    </div>
                    <div className="flex flex-1 flex-col items-start h-full select-none">
                        <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                            <div className="font-Inter font-[700] text-[16px]">
                                Values
                            </div>
                            {
                                filteredValues.length > 0 && 
                                <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                    ({filteredValues.length})
                                </div>
                            }
                            <SearchBar isSearching={isSearchingVal} setIsSearching={setIsSearchingVal} filterValue={filterValueVal} setFilterValue={setFilterValueVal} />
                        </div>
                        
                        <div className="scrollbar w-full h-full pt-[10px] gap-[8px] flex flex-row flex-wrap overflow-y-auto items-start content-start justify-start">
                            {
                                filteredValues.filter((value) => selectedValues.every((value2) => value2 !== value)).map((value, index) => 
                                    <div key={value}>
                                        <ValueEntryNew concept={selectedConcept} attribute={selectedAttribute} values={selectedValues} setValues={setSelectedValues} value={value} isSelected={selectedValues.find((val) => val === value) !== undefined} index={index} filter={filterValueVal} onClick={() => {
                                            let newVals = [];
                                            if (selectedValues.find((val) => val === value) !== undefined) {
                                                newVals = (selectedValues.filter((val) => val !== value));
                                            } else {
                                                newVals = ([...selectedValues, value]);
                                            }
                                            // if (selectedConcept)
                                            //     spawnConceptNode(selectedConcept, selectedAttribute, [...newVals], 2, false);
                                            setSelectedValues(newVals);
                                        }}
                                            length={200}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </>
                : 
                <>
                    <div className="flex flex-col items-start h-full border-[#CACCD2] border-r-[1px] pr-[10px]">
                        <div className="w-full h-fit flex flex-row gap-[10px] items-center justify-start">
                            <div className="font-Inter font-[700] text-[16px]">
                                My Groups
                            </div>
                            {
                                groupedCohortsNum > 0 && 
                                <div className="font-Inter font-[700] text-[13px] text-[#6B7280]">
                                    ({groupedCohortsNum})
                                </div>
                            }
                            <SearchBar isSearching={isSearching} setIsSearching={setIsSearching} filterValue={filterValue} setFilterValue={setFilterValue} />
                        </div>
                        
                        <div className="scrollbar w-full h-full pt-[10px] gap-[8px] flex flex-col overflow-y-auto items-start justify-start">
                            {
                                cohorts.filter(applyFilterValue(filterValue)).map((item, index) =>
                                    <div key={item.id}>
                                    <CohortEntry cohort={item} isSelected={selectedCohort !== null && selectedCohort.id === item.id} index={index} filter={filterValue} 
                                    onClick={() => {
                                        handleClick(item);
                                    }}/>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        selectedCohort && 
                        <div className={`flex flex-1 flex-col gap-[12px] items-start h-full select-none`}>
                            {selectedConcept && 
                                <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                    <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                        Concept:
                                    </div>
                                    <div className="absolute left-[100px] h-fit w-fit">
                                        <ConceptEntry concept={selectedConcept} index={0} filter={""} isSelected={true} 
                                            onClick={() => {}}/>
                                    </div>
                                </div>
                            }
                            {selectedAttribute && 
                                <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                    <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                        Attribute:
                                    </div>
                                    <div className="absolute left-[100px] h-fit w-fit">
                                        <AttributeEntry attribute={selectedAttribute} concept={selectedConcept} index={0} isSelected={true} filter={""} onClick={() => {}}/>
                                    </div>
                                </div>
                            }
                            {selectedValues.length > 0 &&
                                <>
                                    <div className="flex flex-row h-fit w-full justify-between items-center relative pr-[20px]">
                                        <div className="select-none font-Inter font-[700] text-[16px] text-black">
                                            Values:
                                        </div>
                                    </div>
                                    <div className={`scrollbar flex flex-row flex-wrap h-full w-full overflow-y-auto items-start content-start gap-[8px]`}>
                                        {selectedValues.map((value, index) => <div key={value}>
                                            <ValueEntryNew concept={selectedConcept} attribute={selectedAttribute} values={selectedValues} setValues={setSelectedValues} value={value} isSelected={true} index={index} filter={""} onClick={() => {}} length={200}/>
                                            </div>)}
                                    </div>
                                </>
                            }
                            <div className="flex flex-row h-fit w-full justify-start gap-[15px]">
                                <div className="bg-white border-[#6E43F8] border-1 rounded-[8px] px-[10px] py-[6px] font-Inter font-[500] text-[13px] text-[#6E43F8] select-none cursor-pointer"
                                    onClick={() => {
                                        if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                                            toast.warning("You do not have edit access to this page");
                                            return;
                                        }
                                        setEditing(true);
                                    }}
                                >
                                    Edit
                                </div>
                                <div className="bg-white border-[#BB0000] border-1 rounded-[8px] px-[10px] py-[6px] font-Inter font-[500] text-[13px] text-[#BB0000] select-none cursor-pointer"
                                    onClick={() => {
                                        if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                                            toast.warning("You do not have edit access to this page");
                                            return;
                                        }
                                        removeCohortId(selectedCohort.id);
                                        setSelectedCohort(null);
                                    }}
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default CohortBottomPanel;