import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import MainPanel from "../components/MainPanel-3";
import DebugPanel from "../components/DebugPanel";
import { ChartProvider } from "../hooks/useChart";
import { useLibrary } from "../hooks/useLibrary";
import { ReactComponent as StartIcon } from "../svg/Start_icon.svg"
import LibraryDropdown from "../components/LibraryDropdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { MetricNode } from "../lexical/MetricNode";
import QATopBar from "../components/TopBar/QATopBar";
import { ConceptNode } from "../lexical/ConceptNode";
import { CohortNode } from "../lexical/CohortNode";
import SimpleToolTip from "../components/ToolTip/SimpleToolTip";

const HomePage: React.FC = () => {
  const { library, setLibrary, libraries } = useLibrary();
  const [sidePanelName, setSidePanelName] = useState<string>("none");
  const [isLibrarySelected, setLibrarySelected] = useState<boolean>(library !== null);

  const handleLibrarySelection = (selectedOption: { value: number, label: string } | null) => {
    if (selectedOption) {
      const selectedLibrary = libraries?.find(lib => lib.id === selectedOption.value);
      setLibrary(selectedLibrary || null);
      setLibrarySelected(true);
    }
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const prepopulatedRichText = () => {
  }

  const editorConfig = {
    namespace: "metrics-editor",
    onError: onError,
    nodes: [MetricNode, ConceptNode, CohortNode],
    editorState: prepopulatedRichText,
  };

  if (!isLibrarySelected) {
    return (
      <div className="App w-full h-screen relative flex">
        <Sidebar />
        <div className="flex-1 flex flex-col justify-center items-center">
          <StartIcon className="h-150 w-150 " />
          <div className="font-semibold">Unlock the information you need with Xolved</div>
          <label className="mb-2" htmlFor="librarySelector">To get started select your area of focus:</label>
          <div className="w-1/2">
            <LibraryDropdown
              libraries={libraries || []}
              selectedLibrary={library}
              onLibrarySelect={handleLibrarySelection}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ChartProvider>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="App pl-[56px] w-full h-screen relative flex">
          <SimpleToolTip/>
          <QATopBar title="Q&A" panelName={sidePanelName} setPanelName={setSidePanelName} />
          <Sidebar />
          <MainPanel panelName={sidePanelName} setPanelName={setSidePanelName} />
          <DebugPanel />
        </div>
      </LexicalComposer>
    </ChartProvider>
  );

};

export default HomePage;
