import React from "react";
import ReactMarkdown from "react-markdown";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { useDebug } from "../hooks/useDebug";

const DebugPanel: React.FC = () => {
  const { open, message, setOpen } = useDebug();

  const renderDebugMessage = () => {
    if (message?.steps && message.steps.length > 0) {
      return (
        <>
          {message.steps.map(
            (step, index) =>
              step.debug && (
                <ReactMarkdown key={index}>{step.debug}</ReactMarkdown>
              )
          )}
        </>
      );
    }
    return <ReactMarkdown>{message?.debug || ""}</ReactMarkdown>;
  };

  return (
    <div
      className={`fixed overflow-y-auto top-0 right-0 h-full w-1/3 bg-gray-100 text-white z-30 shadow-lg p-4 transition-all duration-300 ease-in-out ${
        open ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <button
        className="text-black hover:text-red-600 absolute top-2 left-2"
        onClick={() => setOpen(!open)}
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
      <div className="prose mt-4 mx-auto overflow-hidden max-w-5xl break-words text-left">
        {renderDebugMessage()}
      </div>
    </div>
  );
};

export default DebugPanel;
