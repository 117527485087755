import React, { createContext, useState, useContext, useEffect } from "react";
import { Library } from "../@types/common"
import { PrivilegeKey, useAuth } from "../hooks/useAuth"

interface LibraryContextData {
    library: Library | null;
    setLibrary: (library: Library | null) => void;
    libraries: Library[];
    setLibraries: (libraries: Library[]) => void;
}

const LibraryContext = createContext<LibraryContextData | undefined>(undefined);

const useLibrary = () => {
    const context = useContext(LibraryContext);
    if (!context) {
        throw new Error("useLibrary must be used within an LibraryProvider");
    }
    return context;
};

const LibraryProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { isAuthenticated, userProfile } = useAuth();
    const [library, setLibrary] = useState<Library | null>(() => {
        const storedLibString = localStorage.getItem("library");
        if (!storedLibString) {
            return null;
        }
        if (!isAuthenticated) {
            localStorage.removeItem("library");
            return null;
        }
        const storedLib = JSON.parse(storedLibString) as Library;
        return storedLib;
    });
    const [_libraries, _setLibraries] = useState<Library[]>([]);
    const [libraries, setLibraries] = useState<Library[]>([]);

    const fetchLibraries = async () => {
        const response = await fetch("/api/get_libraries");
        const data = await response.json();
        _setLibraries(data.libraries);
        console.log(data)
    };

    useEffect(() => {
        fetchLibraries();
    }, []);

    useEffect(() => {
        localStorage.setItem("library", JSON.stringify(library));
    }, [library]);

    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.removeItem("library");
            setLibrary(null);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setLibraries(_libraries?.filter((library) => {
            const key = `library_${library.id}`;
            return userProfile !== null && userProfile.privilege !== undefined && key in userProfile.privilege && userProfile.privilege[key as PrivilegeKey] >= 1;
        }));
    }, [_libraries, userProfile]);

    return (
        <LibraryContext.Provider value={{ library, setLibrary, libraries, setLibraries }}>
            {children}
        </LibraryContext.Provider>
    );
};

export { LibraryProvider, useLibrary };
