import { Attribute, Domain, Item, Metric } from "../@types/common";
import { dateToUTCDateStr, strToDate } from "../helpers/datetimehelper";
import { Page, Privilege, UserProfile } from "../hooks/useAuth";
import { Cohort } from "../hooks/useCohorts";
import { SessionEntry } from "../hooks/useSession";

export const googleOAuth = async (credential: string) => {
  const response = await fetch("/api/oauth/google", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: credential }),
  });

  const responseData = await response.json();
  const info = responseData.idinfo;
  return {
    id: info.id as string,
    email: info.email as string,
    name: info.name as string,
    picture: info.picture as string,
    expiresAt: info.exp as number,
  } as UserProfile;
};

export const fetchUserHistoryBySession = async (email: string, libraryId: number, sessionId: number) => {
  const response = await (fetch(`/api/history?email=${email}&library_id=${libraryId}&session_id=${sessionId}`));
    if (!response.ok) {
      throw new Error("Failed to fetch user history with session");
    }
    const responseData = await response.json();
    console.log(responseData.data);
    return responseData.data as [{ 
      question: string, 
      timestamp: string, 
      response: object,
      status: string, 
      feedback: string,
      comment: string,
      historyid: string, 
      favorite: string, 
      session_id: number
    }];
}

export const fetchUserHistory = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/history?email=${email}&library_id=${libraryId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user history");
  }
  const responseData = await response.json();
  console.log(responseData.data);
  return responseData.data as [{ message: string, timestamp: string, status: string, historyid: string, favorite: string, session_id: number}];
};

export const updateUserHistorySession = async (historyId: string, sessionId: number) => {
  console.log(`${historyId}, ${sessionId}`);
  const response = await fetch(`/api/update_user_history_session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      historyid: historyId,
      session_id: sessionId,
    }),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to update user history session");
  }
}

export const deleteUserHistory = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/history?email=${email}&library_id=${libraryId}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error("Failed to delete user history");
  }
};


export const fetchUserFavorite = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/history?email=${email}&library_id=${libraryId}&favorite=${"Y"}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user favorite");
  }
  const responseData = await response.json();
  return responseData.data as [{ message: string, historyid: string }];
}

export const addFavorite = async (historyid: string, favorite: string) => {
  const response = await fetch(`/api/add_favorite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      historyid: historyid,
      favorite: favorite,
    }),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to update user favorite");
  }
}

export const fetchUserSample = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/sample?email=${email}&library_id=${libraryId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user samples");
      }
      const responseData = await response.json();
      return responseData.data as {[s: string]: string[]};
}

export const fetchUserPin = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/get_pinned_queries?email=${email}&library_id=${libraryId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user pin");
  }
  const responseData = await response.json();
  return responseData.data as [{ id: string, name: string, query_text: string, create_date: string, user_name: string }]
}

export const addPin = async (query_text: string, library_id: number, user_email: string) => {
  const response = await fetch(`/api/add_pinned_query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query_text: query_text,
      library_id: library_id,
      user_email: user_email
    }),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to add user pin");
  }
  const responseData = await response.json();
  return responseData.data as string;
}


export const removePin = async (query_id: string) => {
  const response = await fetch(`/api/remove_pinned_query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pinned_query_id: query_id
    }),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to remove user pin");
  }
}


export const fetchUserPinResult = async (email: string, libraryId: number, date: Date) => {
  const response = await fetch(`/api/get_pinned_query_result?email=${email}&library_id=${libraryId}&run_date=${dateToUTCDateStr(date)}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user pin");
  }
  const responseData = await response.json();
  return responseData.data;
}

const formatDeprecated = (deprecated: string) => {
  if (deprecated === 'D' || deprecated === 'N') {
    return deprecated;
  }
  return 'N';
}

const formatYN = (YorN: string, def: 'Y' | 'N' = 'N') => {
  if (YorN === 'Y' || YorN === 'N') {
    return YorN;
  }
  return def;
}

export const fetchMetric = async (email: string, libraryId: number, domainId?: number) => {
  let response:Response;
  if (domainId === undefined) {
    response = await fetch(`/api/get_metric_list?library_id=${libraryId}&email=${email}`);
  } else {
    response = await fetch(`/api/get_metric_list?library_id=${libraryId}&email=${email}&domain_id=${domainId}`);
  }
  if (!response.ok) {
    throw new Error("Failed to fetch metric");
  }
  const responseData = await response.json();
  const metrics = responseData.metrics;
  return metrics.map((item: any[]): Item => ({
    type: "Metric",
    id: item[0],
    category: item[1],
    name: item[2],
    desc: item[3],
    sql: item[4],
    modified_sql: item[5],
    logic: item[6],
    // formular: item[4],
    input_fields: item[7],
    output_fields: item[8],
    status: item[9],
    deprecated: item[10],
    domain_id: item[11],
  })) as [Item];
}

export const fetchMetricNew = async (email: string, libraryId: number, domainId?: number) : Promise<Metric[]> => {
  let response:Response;
  if (domainId === undefined) {
    response = await fetch(`/api/get_metric_list?library_id=${libraryId}&email=${email}`);
  } else {
    response = await fetch(`/api/get_metric_list?library_id=${libraryId}&email=${email}&domain_id=${domainId}`);
  }
  if (!response.ok) {
    throw new Error("Failed to fetch metric");
  }

  const responseData = await response.json();
  const metrics = responseData.metrics;
  const parsedMetrics = metrics.map((metric: any[]): Metric => ({
    metric_id: metric[0],
    metric_name: metric[2],
    domain_id: metric[11],
    domain_name: metric[1],
    metric_desc: metric[3],
    metric_logic: metric[6],
    input_fields: metric[7],
    output_fields: metric[8],
    deprecated: formatDeprecated(metric[10]),
    metric_status: metric[9],
    sql_statement: metric[4],
    modified_sql: formatYN(metric[5]),
    all_users: Boolean(metric[12]),
  })) as Metric[];
  return parsedMetrics;
}

export const fetchConcept = async (email: string, libraryId: number, domainId?: number) => {
  let response:Response;
  if (domainId === undefined) {
    response = await fetch(`/api/get_concept_list?library_id=${libraryId}&email=${email}`);
  } else {
    response = await fetch(`/api/get_concept_list?library_id=${libraryId}&email=${email}&domain_id=${domainId}`);
  }
  if (!response.ok) {
    throw new Error("Failed to fetch concept");
  }
  const responseData = await response.json();
  const concepts = responseData.concepts;
  return concepts.map((item: any[]): Item => ({
    type: "Metric",
    id: item[0],
    category: item[1],
    name: item[2],
    desc: item[3],
    sql: item[4],
    modified_sql: item[5],
    logic: item[6],
    // formular: item[4],
    input_fields: item[7],
    output_fields: item[8],
    status: item[9],
    deprecated: item[10]
  })) as [Item];
}

export const fetchDomainList = async (libraryId: number, email: string, parentDomainId?: number) => {
  let response:Response;
  if (parentDomainId === undefined) {
    response = await fetch(`/api/get_domain_list?library_id=${libraryId}&email=${email}`);
  } else {
    response = await fetch(`/api/get_domain_list?library_id=${libraryId}&email=${email}&parent_domain_id=${parentDomainId}`);
  }
  if (!response.ok) {
    throw new Error("Failed to fetch domain list");
  }
  const responseData = await response.json();
  return responseData.domains as [Domain];
}

export const createDomain = async (libraryId: number, email: string, domainName: string, canMove = 'Y', canRename = 'Y', levelNo = 0, parentDomainId?:number) => {
  let postBody : {[key: string]: any} = {
    domain_name: domainName,
    can_move: canMove,
    can_rename: canRename,
    level_no: levelNo,
    parent_domain_id: parentDomainId,
  };
  console.log("creating domain: ", postBody);
  const response = await fetch(`/api/save_domain?library_id=${libraryId}&email=${email}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to create domain");
  }
  const responseData = await response.json();
  console.log("creating domain got", responseData);
  return Number( responseData.id );
}

export const updateDomain = async (libraryId: number, email: string, domainId: number, domainName: string, canMove = 'Y', canRename = 'Y', levelNo = 0, parentDomainId?:number) => {
  let postBody : {[key: string]: any} = {
    domain_name: domainName,
    can_move: canMove,
    can_rename: canRename,
    level_no: levelNo,
    parent_domain_id: parentDomainId,
  };
  const response = await fetch(`/api/save_domain?library_id=${libraryId}&email=${email}&domain_id=${domainId}&domain_name=${domainName}&can_move=${canMove}&can_rename=${canRename}&level_no=${levelNo}&parent_domain_id=${parentDomainId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to update domain");
  }
}

export const deleteDomain = async (libraryId: number, email: string, domainId: number) => {
  const response = await fetch(`/api/delete_domain?library_id=${libraryId}&email=${email}&domain_id=${domainId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify([]),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to delete domain");
  } 
}

export const createMetric = async (libraryId: number, email: string, domainId: number, metricName: string, metricDesc: string, sqlStatement: string, modifiedSql: string, logic: string, formula: string, inputFields: string, outputFields: string, status: string, deprecated: string) => {
  let postBody : {[key: string] : any} = {
    "metric_id": -1,
    "metric_name": metricName,
    "domain_id": domainId,
    "metric_desc": metricDesc,
    "sql_statement": sqlStatement,
    "modified_sql": modifiedSql,
    "logic": logic,
    "input_fields": inputFields,
    "output_fields": outputFields,
    "formula": formula,
    "status": status,
    "deprecated": deprecated,
  };
  console.log("calling save metric", postBody);
  const response = await fetch(`/api/save_metric?email=${email}&library_id=${libraryId}`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(postBody),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to create metric");
  }
  const responseData = await response.json();
  console.log("save metric response", responseData);
  if (responseData.message !== "success") {
    throw new Error("Failed to create metric");
  }
  return Number( responseData.id );
}

export const updateMetric = async (libraryId: number, email: string, metricId: number, domainId: number, metricName: string, metricDesc: string, sqlStatement: string, modifiedSql: string, logic: string, formula: string, inputFields: string, outputFields: string, status: string, deprecated: string) => {
  let postBody : {[key: string]: any} = {
    metric_id: metricId,
    domain_id: domainId,
    metric_name: metricName,
    metric_desc: metricDesc,
    sql_statement: sqlStatement,
    modified_sql: modifiedSql,
    logic: logic,
    formula: formula,
    input_fields: inputFields,
    output_fields: outputFields,
    status: status,
    deprecated: deprecated,
  }
  console.log("updating metric", postBody);
  const response = await fetch(`/api/save_metric?email=${email}&library_id=${libraryId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to update metric");
  }
}

export const metricGenerateSQL = async (libraryId: number, email: string, metric_name: string, metric_description: string, metric_logic: string) => {
  const postBody = {
    "name": metric_name,
    "desc": metric_description,
    "type": "Metric",
    "biz_logic": metric_logic,
    "logic_history": "",
    "answer_history": "",
  };
  const response = await fetch(`/api/create_sql?email=${email}&library_id=${libraryId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postBody),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to generate metric sql");
  }

  const responseData = await response.json();
  return responseData.data as string;
  // setCurrentMetric({ ...currentMetric, sql: responseData.data });
  // updateMetric(currentMetric);
  // setStatus("completed");
  // return { ...currentMetric, sql: responseData.data } as Item;
}

export const createConcept = async (libraryId: number, email: string, domainId: number, conceptName: string, categoryName: string, conceptDesc: string, sqlStatement: string, modifiedSql: string, logic: string, formula: string, inputFields: string, outputFields: string, status: string, deprecated: string, attributes: Attribute[]) => {
  let postBody : {[key: string] : any} = {
    "concept_id": -1,
    "concept_name": conceptName,
    "category_name": categoryName,
    "domain_id": domainId,
    "concept_desc": conceptDesc,
    "sql_statement": sqlStatement,
    "modified_sql": modifiedSql,
    "logic": logic,
    "input_fields": inputFields,
    "output_fields": outputFields,
    "formula": formula,
    "status": status,
    "deprecated": deprecated,
    "attributes": attributes,
  };
  const response = await fetch(`/api/save_concept?email=${email}&library_id=${libraryId}`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(postBody),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to create concept");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to create concept");
  }
  return responseData.id as number;
}

export const fetchUserSession = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/get_user_sessions?email=${email}&library_id=${libraryId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user samples");
  }
  const responseData = await response.json();
  const user_sessions = responseData.user_sessions
  return (
    user_sessions.map((entry: any) => {
      return {id: entry.id, name: entry.name, create_time: strToDate(entry.create_time), status: entry.status} as SessionEntry;
    }) as SessionEntry[]
  )
}

export const createUserSession = async (email: string, libraryId: number, name: string) => {
  const response = await fetch(`/api/create_user_session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      library_id: libraryId,
      user_email: email,
      session_name: name,
    }),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to create session");
  }
  const responseData = await response.json();
  return responseData.session_id as number;
}

export const updateUserSession = async (id: number, name: string, status: string) => {
  const response = await fetch(`/api/update_user_session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      session_id: id,
      session_name: name,
      session_status: status
    }),
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Failed to update session");
  }
}

export const fetchConceptDetails = async (id: number) => {
  const response = await fetch(`/api/get_concept_detail?concept_id=${id}`);
  if (!response.ok) {
    throw new Error("Failed to fetch concept details");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to fetch concept details");
  }
  return responseData.attribute_list as Attribute[];
}

export const fetchAttributeValues = async (id: number) => {
  const response = await fetch(`/api/get_attribute_values?attribute_id=${id}`);
  if (!response.ok) {
    throw new Error("Failed to fetch concept details");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to fetch concept details");
  }
  return responseData.attribute_values as string[];
}

export const fetchUserCohort = async (email: string, libraryId: number) => {
  const response = await fetch(`/api/get_cohort_list?email=${email}&library_id=${libraryId}`)
  if (!response.ok) {
    throw new Error("Failed to fetch user cohorts");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to fetch user cohorts");
  }
  return responseData.data.map((item:any) => { return {
    id: item.id,
    name: item.name,
    library_id: item.library_id,
    attribute_id: item.attribute_id,
    user_email: item.user_email,
    attribute_values: item.attribute_values.split("\t").filter((item:string) => item !== ""),
  }}) as Cohort[];
}

export const saveUserCohort = async(cohortName: string, libraryId: number, email: string, attributeId: number, attributeValues: string[]) => {
  const response = await fetch(`/api/save_cohort`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cohort_name: cohortName,
      library_id: libraryId,
      user_email: email,
      attribute_id: attributeId,
      attribute_values: attributeValues,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to save new cohort");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to save new cohort");
  }
  return responseData.data as number;
}

export const deleteUserCohort = async(cohortId: number) => {
  const response = await fetch(`/api/delete_cohort`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cohort_id: cohortId,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to delete cohort");
  }
}

export const updateUserCohort = async (cohortId: number, cohortName: string, attributeId: number, attributeValues: string[]) => {
  const response = await fetch(`/api/update_cohort`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cohort_id: cohortId,
      cohort_name: cohortName,
      attribute_id: attributeId,
      attribute_values: attributeValues,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to update cohort");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to update cohort");
  }
}

export const fetchUserConceptWithAttributes = async(libraryId: number) => {
  const response = await fetch(`/api/get_concept_list_with_attribute?library_id=${libraryId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user concept with attributes");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to fetch user concept with attributes");
  }
  return responseData.concepts as {concept: Item, attributes: Attribute[]}[];
}

export const fetchUserPrivilege = async(email: string) => {
  const response = await fetch(`/api/get_user_privilege?email=${email}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user privilege");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to fetch user privilege");
  }
  return responseData.privilege as Privilege;
}

export const updateUserPrivilege = async(sourceEmail: string, targetEmail: string, pageList: Page[], page_val_list: number[], library_list: number[], library_val_list: number[]) => {
  const transformPageName = (pageList: Page[]) => {
    // VALID_PAGE = ["qa", "metric_central", "concept_builder", "my_xolved", "report_central"]
    return pageList.filter((page: Page) => {
      return page === 'Q&A' || page === 'ConceptBuilder' || page === 'MetricCentral' || page === 'MyXolved'
    }).map((page: Page) => {
      if (page === 'Q&A') {
        return 'qa';
      } else if (page === 'ConceptBuilder') {
        return 'concept_builder';
      } else if (page === 'MetricCentral') {
        return 'metric_central';
      } else if (page === 'MyXolved') {
        return 'my_xolved';
      }
    }) as string[]
  }
  
  const response = await fetch(`/api/update_user_privilege`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      source_email: sourceEmail,
      target_email: targetEmail,
      page_list: transformPageName(pageList),
      page_val_list: page_val_list,
      library_list: library_list,
      library_val_list: library_val_list,
    }),
  })
  if (!response.ok) {
    throw new Error("Failed to update user privilege");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to update user privilege");
  }
}

export const checkUserAdmin = async(email: string) => {
  const response = await fetch(`/api/get_user_admin?email=${email}`);
  if (!response.ok) {
    throw new Error("Failed to check user admin");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to check user admin");
  }
  return responseData.isAdmin as boolean
}


export const checkUserSuperAdmin = async(email: string) => {
  const response = await fetch(`/api/get_user_super_admin?email=${email}`);
  if (!response.ok) {
    throw new Error("Failed to check user super admin");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to check user super admin");
  }
  return responseData.isSuperAdmin as boolean
}

export const getUserEmails = async(email: string) => {
  const response = await fetch(`/api/get_user_emails?email=${email}`);
  if (!response.ok) {
    throw new Error("Failed to get user emails");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to get user emails");
  }
  return responseData.users as string[]
}

export const makeUserAdmin = async(sourceEmail: string, targetEmail: string) => {
  const response = await fetch(`/api/make_user_admin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      source_email: sourceEmail,
      target_email: targetEmail,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to update user privilege");
  }
  const responseData = await response.json();
  if (responseData.message !== "success") {
    throw new Error("Failed to update user privilege");
  }
}