import {
    $applyNodeReplacement,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    SerializedTextNode,
    Spread,
    TextNode,
} from "lexical";
  
export type SerializedBraceNode = Spread<
    {
        quoted: string;
    },
    SerializedTextNode
>;
  
class BraceNode extends TextNode {
    __quoted: string;
  

    static getType(): string {
        return "brace";
    }
    static clone(node: BraceNode): BraceNode {
        return new BraceNode(node.__quoted, node.__text, node.__key);
    }
  
    constructor(quoted: string, text?: string, key?: string) {
        const assembledText = `{${quoted}}`;
        super(text ?? assembledText, key);
        this.__quoted = quoted;
    }
  
    createDOM(config: EditorConfig): HTMLElement {
        const dom = super.createDOM(config);
        dom.style.font = "Inter";
        dom.style.fontStyle = "normal";
        dom.style.fontWeight = "500";
        dom.style.fontSize = "13px";
        dom.style.lineHeight = "16px";
        dom.style.color = "#6E43F8";
        dom.style.background = "#F3F4F6";
        dom.style.border = "1px solid #E5DDFD";
        dom.style.borderRadius = "10px";
        dom.style.height = "fit";
        dom.style.width = "fit";
        dom.style.padding = "3px 8px"
    
        return dom;
    }
  
    exportDOM(): DOMExportOutput {
        const element = document.createElement("span");
        element.setAttribute("data-lexical-quoted", "true");
        element.textContent = this.__text;
        return { element };
    }
  
    static importDOM(): DOMConversionMap | null {
        return {
            span: (domNode: HTMLElement) => {
            if (!domNode.hasAttribute("data-lexical-quoted")) {
                return null;
            }
            return {
                    conversion: convertQuotedElement,
                    priority: 1,
                };
            },
        };
    }
  
    static importJSON(serializedNode: SerializedBraceNode): BraceNode {
        const node = $createBraceNode(serializedNode.quoted);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
  
    exportJSON(): SerializedBraceNode {
        return {
            ...super.exportJSON(),
            quoted: this.__quoted,
            version: 1,
        };
    }
  
    isTextEntity(): boolean {
        return true;
    }
  
    canInsertTextBefore(): boolean {
        return false;
    }
  
    canInsertTextAfter(): boolean {
        return false;
    }
}
  
function convertQuotedElement(
    domNode: HTMLElement
): DOMConversionOutput | null {
    const textContent = domNode.textContent;
    if (textContent !== null) {
        return {
            node: $createBraceNode(textContent),
        };
    }
    return null;
}
  
  function $createBraceNode(quoted: string): BraceNode {
    const braceNode = new BraceNode(quoted);
    braceNode.setMode("token").toggleDirectionless();
    return $applyNodeReplacement(braceNode);
  }
  
function $isBraceNode(
    node: LexicalNode | null | undefined
): node is BraceNode {
    return node instanceof BraceNode;
}
  
export {
    BraceNode,
    $createBraceNode,
    $isBraceNode,
};