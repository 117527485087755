import { HistoryEntry, useHistory } from "../../hooks/useHistory"
import { strToDate } from "../../helpers/datetimehelper";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as DeleteButton } from "../../svg/session_panel/delete_button.svg";
import { ReactComponent as DeleteButtonRed } from "../../svg/session_panel/delete_button_red.svg";
import { format } from "date-fns";
import * as api from "../../api";
import FavoriteButton from "../FavoriteButton";
import { SimpleToolTipId } from "../ToolTip/SimpleToolTip";
import { useLibrary } from "../../hooks/useLibrary";
import { useAuth } from "../../hooks/useAuth";
import { useToast } from "../../hooks/useToast";


interface HistoryEntryDivProps {
    historyEntry: HistoryEntry;
    historyIndex?: number;
    onClick?: (e: React.MouseEvent) => void;
    onFavorite?: () => void;
}

const HistoryEntryDiv: React.FC<HistoryEntryDivProps> = ({historyEntry, historyIndex, onClick, onFavorite=()=>{}}) => {

    const date = strToDate(historyEntry.timestamp);
    const formattedTimestamp = format(date, "MMMM do, h:mma");
    const { updateHistorySession } = useHistory();
    const { library } = useLibrary();
    const {checkEditPrivilege} = useAuth();
    const toast = useToast();

    return (
        <div className="h-fit w-full flex flex-row group pl-[10px] pr-[2px] py-[2px] overflow-auto items-stretch"
            id={`_history_${historyIndex}`}
        >
            <div className={`w-[100px] flex-1 border-[1px] px-[8px] py-[6px] rounded-[11px] 
                border-[#6E43F8] bg-white hover:bg-[#6E43F8]
                cursor-pointer font-Inter text-[12px] font-[500] text-left text-[#010616] hover:text-white 
                break-words flex items-center`} 
                onClick={onClick}
            >
                <div className="h-fit w-full">
                    {historyEntry.message}
                </div>
            </div>
            <div className="h-fit w-fit justify-center flex">
                <div className={`opacity-0 group-hover:opacity-[1] h-fit w-fit flex flex-col items-center`}>
                    <FavoriteButton className="cursor-pointer" isFav={() => {return historyEntry.favorite === "Y"}} onClick={() => {
                        if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        onFavorite();
                    }} />
                    <DeleteButtonRed className={`cursor-pointer outline-none`} onClick={() => {
                        if (!checkEditPrivilege('Q&A', library?.id || 0)) {
                            toast.warning("You do not have edit access to this page");
                            return;
                        }
                        updateHistorySession({historyid: historyEntry.historyid || "", newSessionId: -1});
                    }}
                    />
                </div>
            </div>
        </div>
    )
}


export default HistoryEntryDiv;